import { Path, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import { Fields } from './types';

interface Props {
  name: Path<Fields>;
  onSubmit?: () => void;
}

const WorkspaceIdentityFormCreate = (props: Props) => {
  const { name, onSubmit } = props;

  const { t } = useTranslation();

  const { setValue } = useFormContext<Fields>();

  return (
    <PageAddon.NewCollapse.Controls>
      <Button
        size="small"
        onClick={() => {
          setValue(name, false);

          if (onSubmit) onSubmit();
        }}
      >
        {t('common.create')}
      </Button>
    </PageAddon.NewCollapse.Controls>
  );
};

export default WorkspaceIdentityFormCreate;
