import { matchSorter } from 'match-sorter';
import { useState } from 'react';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { IdentitySchema } from '../../../api/schemas/identitySchema';
import LinkButton from '../../../components/LinkButton';
import PageAddon from '../../../components/PageAddon';
import useURL from '../../../hooks/useURL';

interface Props {
  data: IdentitySchema;
  getAccountRoute?: (args: { accountId: WorkspaceAccountSchema['id'] }) => string;
}

const WorkspaceIdentityDetailAccountsTab = (props: Props) => {
  const { data, getAccountRoute } = props;

  const { navigate } = useURL();

  const [search, setSearch] = useState('');

  return (
    <PageAddon.List search={search} onSearch={setSearch}>
      {matchSorter(data.accounts, search, { keys: ['login'] }).map((item) => (
        <PageAddon.List.Item key={item.id} title={item.login}>
          {getAccountRoute && (
            <LinkButton
              size="small"
              variant="transparent"
              onClick={() => navigate(getAccountRoute({ accountId: item.id }))}
            />
          )}
        </PageAddon.List.Item>
      ))}
    </PageAddon.List>
  );
};

export default WorkspaceIdentityDetailAccountsTab;
