import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { AgentSchema } from '../../api/schemas/agentSchema';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import Notes from '../../components/Notes';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { can } from '../../utils/permissions';
import DealDetailGeneralTab from '../deal/DealDetailGeneralTab';
import GlobalDealDetailAccountsTab from './GlobalDealDetailAccountsTab';
import GlobalDealDetailDropdown from './GlobalDealDetailDropdown';

interface Props {
  data: GlobalDealSchema;
  onClose: () => void;
  getAgentRoute?: (args: { agentId: AgentSchema['id'] }) => string;
  getClubRoute?: (args: { clubId: GlobalClubSchema['id'] }) => string;
}

const GlobalDealDetail = (props: Props) => {
  const { data, onClose, getAgentRoute, getClubRoute } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const { id, code, notes_count, workspaces, is_deleted } = data;

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        copy
        title={code}
        icons={titleIcons}
        onClose={onClose}
        tabs={[
          {
            label: t('common.general'),
            content: (
              <DealDetailGeneralTab
                data={data}
                disabled={!can.deal.createUpdate}
                workspaces={workspaces}
                getAgentRoute={getAgentRoute}
                getClubRoute={getClubRoute}
              />
            ),
          },
          {
            label: t('common.accounts'),
            content: <GlobalDealDetailAccountsTab data={data} />,
            count: data.accounts.length,
          },
        ]}
      >
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Notes.Button
                count={notes_count}
                onClick={() => navigate(routes.globalDealNotes({ dealId: id }))}
              />
              <GlobalDealDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalDealDetail;
