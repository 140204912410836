import {
  WorkspaceClubTransactionSchema,
  TYPE,
  STATUS,
} from '../../api/schemas/workspaceClubTransactionSchema';
import { t } from '../../i18n';
import icon from '../../static/icons';

export const CLUB_TRANSACTIONS_TYPES_LABELS: Record<
  WorkspaceClubTransactionSchema['type'],
  string
> = {
  [TYPE.UNKNOWN]: '—',
  [TYPE.DEPOSIT]: t('common.deposit'),
  [TYPE.WITHDRAW]: t('common.withdraw'),
};

export const CLUB_TRANSACTIONS_STATUSES_LABELS: Record<
  WorkspaceClubTransactionSchema['status'],
  string
> = {
  [STATUS.UNKNOWN]: '—',
  [STATUS.PENDING]: t('common.pending'),
  [STATUS.FAILED_MEMBER_NOT_FOUND]: t('common.member_not_found'),
  [STATUS.FAILED_MEMBER_NOT_ENOUGH_CHIPS]: t('common.member_has_not_enough_chips'),
  [STATUS.FAILED_CLUB_NOT_ENOUGH_CHIPS]: t('common.club_has_not_enough_chips'),
  [STATUS.CONFIRMED]: t('common.confirmed'),
  [STATUS.FAILED_NOT_AUTHORIZED]: t('common.member_not_authorized'),
  [STATUS.CANCELED]: t('common.canceled'),
  [STATUS.FAILED_MEMBER_BLOCKED]: t('common.member_is_blocked'),
};

export const CLUB_TRANSACTIONS_STATUSES_ICONS: Record<
  WorkspaceClubTransactionSchema['status'],
  ReturnType<typeof icon> | null
> = {
  [STATUS.UNKNOWN]: null,
  [STATUS.PENDING]: icon('clock', 16),
  [STATUS.FAILED_MEMBER_NOT_FOUND]: icon('userX', 16),
  [STATUS.FAILED_MEMBER_NOT_ENOUGH_CHIPS]: icon('userMinus', 16),
  [STATUS.FAILED_CLUB_NOT_ENOUGH_CHIPS]: icon('pokerChipX', 16),
  [STATUS.CONFIRMED]: icon('checkCircle', 16),
  [STATUS.FAILED_NOT_AUTHORIZED]: icon('logOut', 16),
  [STATUS.CANCELED]: icon('crossCircle', 16),
  [STATUS.FAILED_MEMBER_BLOCKED]: icon('userX', 16),
};

export const canCancel = (data: WorkspaceClubTransactionSchema) => {
  const { status } = data;

  if (status === STATUS.UNKNOWN || status === STATUS.PENDING) return true;

  return false;
};
