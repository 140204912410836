import { ChannelSchema, STATUS } from '../../api/schemas/channelSchema';
import { Color } from '../../components/Badge';
import { t } from '../../i18n';

export const STATUS_LABELS: Record<ChannelSchema['status'], string> = {
  [STATUS.UNKNOWN]: t('common.unknown'),
  [STATUS.INACTIVE]: t('common.inactive'),
  [STATUS.ACTIVE]: t('common.active'),
};

export const STATUS_COLORS: Record<ChannelSchema['status'], Color> = {
  [STATUS.UNKNOWN]: 'grey',
  [STATUS.INACTIVE]: 'red',
  [STATUS.ACTIVE]: 'green',
};
