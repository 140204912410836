import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalAgent } from '../../api';
import { AgentSchema } from '../../api/schemas/agentSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';

type Params = RouteParams<typeof routes.globalAgent>;

interface Args {
  data: AgentSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, name, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { agentId } = useParams<Params>();

  return {
    title: name,
    disabled: is_deleted,
    options: {
      notes: {
        icon: icon('notes', 20),
        label: t('common.notes'),
        onClick: () => navigate(routes.globalAgentNotes({ agentId: id })),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteGlobalAgent({ agentId: id });

          if (id === agentId) {
            navigate(routes.globalAgents);
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
        disabled: !can.agent.delete,
        ...(!can.agent.delete && { tooltip: t('common.no_permission') }),
      },
    },
  };
};

export default useDropdown;
