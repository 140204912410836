import { useTranslation } from 'react-i18next';
import { GlobalBonusSchema } from '../../api/schemas/globalBonusSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import useForm from './useForm';

interface Props {
  onClose: () => void;
  onCreate: (args: { bonusId: GlobalBonusSchema['id'] }) => void;
}

const GlobalBonusForm = (props: Props) => {
  const { onClose, onCreate } = props;

  const { t } = useTranslation();

  const { form, fields, onSubmit } = useForm({ onCreate });

  return (
    <PageAddon title={t('common.create_bonus')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.date}
        {fields.deal_id}
        {fields.type}
        {fields.amount}
        {fields.currency}
        {fields.description}
        {fields.reconcile}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalBonusForm;
