import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceStrategy } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceSettingsStrategySchema } from '../../../api/schemas/workspaceSettingsStrategySchema';
import queryKeys from '../../../config/queryKeys';
import WorkspaceSettingsSpinner from '../WorkspaceSettingsSpinner';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  children: (args: { data: WorkspaceSettingsStrategySchema }) => ReactElement;
}

const WorkspaceSettingsStrategyLoader = (props: Props) => {
  const { workspaceId, children } = props;

  const queryKey = [queryKeys.workspaceStrategy({ workspaceId })];
  const queryFn = () => loadWorkspaceStrategy({ workspaceId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isPending) return <WorkspaceSettingsSpinner />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceSettingsStrategyLoader;
