import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAgentDetail from '../../features/global-agent/GlobalAgentDetail';
import GlobalAgentForm from '../../features/global-agent/GlobalAgentForm';
import GlobalAgentLoader from '../../features/global-agent/GlobalAgentLoader';
import GlobalAgentNotes from '../../features/global-agent/GlobalAgentNotes';
import GlobalAgentsPage from '../../pages/GlobalAgentsPage';
import Notes from '../Notes';
import Route from './Route';

const page = <GlobalAgentsPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalAgentForm
        onClose={() => navigate(routes.globalAgents)}
        onCreate={({ agentId }) => navigate(routes.globalAgent({ agentId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['agentId']}>
    {({ agentId, navigate }) => (
      <GlobalAgentLoader agentId={agentId}>
        {({ data }) => (
          <GlobalAgentDetail
            data={data}
            onClose={() => navigate(routes.globalAgents)}
            getNotesRoute={() => navigate(routes.globalAgentNotes({ agentId }))}
            getAgentRoute={({ relatedAgentId }) =>
              routes.globalAgentRelatedAgent({ agentId, relatedAgentId })
            }
          />
        )}
      </GlobalAgentLoader>
    )}
  </Route>
);

const detailNotes = (
  <Route params={['agentId']}>
    {({ agentId, navigate }) => (
      <GlobalAgentNotes agentId={agentId}>
        {(loader) => (
          <Notes onClose={() => navigate(routes.globalAgent({ agentId }))} {...loader} />
        )}
      </GlobalAgentNotes>
    )}
  </Route>
);

const detailRelatedAgent = (
  <Route params={['agentId', 'relatedAgentId']}>
    {({ agentId, relatedAgentId, navigate }) => (
      <GlobalAgentLoader agentId={relatedAgentId}>
        {({ data }) => (
          <GlobalAgentDetail
            data={data}
            onClose={() => navigate(routes.globalAgent({ agentId }))}
            getNotesRoute={() =>
              navigate(routes.globalAgentRelatedAgentNotes({ agentId, relatedAgentId }))
            }
          />
        )}
      </GlobalAgentLoader>
    )}
  </Route>
);

const detailRelatedAgentNotes = (
  <Route params={['agentId', 'relatedAgentId']}>
    {({ agentId, relatedAgentId, navigate }) => (
      <GlobalAgentNotes agentId={relatedAgentId}>
        {(loader) => (
          <Notes
            onClose={() => navigate(routes.globalAgentRelatedAgent({ agentId, relatedAgentId }))}
            {...loader}
          />
        )}
      </GlobalAgentNotes>
    )}
  </Route>
);

const globalAgentsPage: RouteObject = {
  path: routes.globalAgents,
  element: page,
  children: [
    {
      path: routes.globalAgentsCreate,
      element: createForm,
    },
    {
      path: routes.globalAgent({ agentId: ':agentId' }),
      element: detail,
      children: [
        {
          path: routes.globalAgentNotes({ agentId: ':agentId' }),
          element: detailNotes,
        },
        {
          path: routes.globalAgentRelatedAgent({
            agentId: ':agentId',
            relatedAgentId: ':relatedAgentId',
          }),
          element: detailRelatedAgent,
          children: [
            {
              path: routes.globalAgentRelatedAgentNotes({
                agentId: ':agentId',
                relatedAgentId: ':relatedAgentId',
              }),
              element: detailRelatedAgentNotes,
            },
          ],
        },
      ],
    },
  ],
};

export default globalAgentsPage;
