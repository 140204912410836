import { ReactNode } from 'react';
import PageAddonHeading from '../PageAddonHeading';
import styles from './styles.module.scss';

interface Props {
  label: string;
  children: ReactNode;
}

const PageAddonCollapseGroup = (props: Props) => {
  const { label, children } = props;

  return (
    <div className={styles.container}>
      <PageAddonHeading>{label}</PageAddonHeading>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PageAddonCollapseGroup;
