import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceAccounts } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { COLUMNS, WorkspaceStageSchema } from '../../api/schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { Column, Columns, getTableProps } from '../../components/Table';
import Tag from '../../components/Tag';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import {
  COLUMNS_LABELS,
  getSortingKey,
  getSortingLabel,
  sortings,
} from '../../features/workspace-account/helpers';
import useCurrentStage from '../../features/workspace-account/useCurrentStage';
import useStages from '../../features/workspace-account/useStages';
import useTableColumnResize from '../../features/workspace-account/useTableColumnResize';
import WorkspaceAccountCreateButton from '../../features/workspace-account/WorkspaceAccountCreateButton';
import WorkspaceAccountDetailLink from '../../features/workspace-account/WorkspaceAccountDetailLink';
import WorkspaceAccountDropdown from '../../features/workspace-account/WorkspaceAccountDropdown';
import WorkspaceAccountFilter from '../../features/workspace-account/WorkspaceAccountFilter';
import { WorkspaceAccountsProvider } from '../../features/workspace-account/WorkspaceAccountsContext';
import WorkspaceAccountStages from '../../features/workspace-account/WorkspaceAccountStages';
import WorkspaceAccountTableSelectionActions from '../../features/workspace-account/WorkspaceAccountTableSelectionActions';
import WorkspaceAccountTableViewDialog from '../../features/workspace-account/WorkspaceAccountTableViewDialog';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import {
  createInfinitePaginatedQueryData,
  createPaginatedQueryData,
  deleteInfinitePaginatedQueryData,
  deletePaginatedQueryData,
  updatePaginatedQueryData,
} from '../../utils/queryClient';
import Error403Page from '../Error403Page';
import { COLUMNS_WIDTHS, checkMessage, getContent } from './helpers';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceAccount>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountsPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { accountId } = useParams<Params>();
  const { urlParams } = useURL();
  const { currentStage } = useCurrentStage();

  const { search, stage_name_in, status, ...rest } = urlParams;

  const [displayAll, setDisplayAll] = useState(Boolean(stage_name_in));

  delete rest.stage_order_eq;

  const queryKey = [
    queryKeys.workspaceAccounts({ workspaceId }),
    stage_name_in,
    currentStage,
    displayAll,
    search,
    status,
    rest,
  ];

  const stagesQueryKey = [queryKeys.workspaceAccountsStages({ workspaceId })];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) =>
      loadWorkspaceAccounts(
        { workspaceId },
        {
          ...rest,
          ...params,
          ...(search && { search }),
          ...(stage_name_in && { stage_name_in }),
          ...(status === 'online' && { is_online_eq: String(true), is_miner_eq: String(false) }),
          ...(status === 'offline' && { is_online_eq: String(false) }),
          ...(status === 'mining' && { is_miner_eq: String(true) }),
          ...(!search && !displayAll && { stage_order_eq: String(currentStage) }),
        }
      ),
  });

  const { data, total, loading, error, checkIsInView } = query;

  useWebSocketPaginatedActions<WorkspaceAccountSchema>({
    queryKey,
    domain: 'Account',
    createAction: ['WorkspaceChanged'],
    updateAction: [
      'Edited',
      'BalanceChanged',
      'UserRemoved',
      'PilotSwitchOn',
      'PilotSwitchOff',
      'ConfirmPilotStarted',
      'ConfirmPilotFinished',
      'AccountIsOnline',
      'AccountIsOffline',
      'IdentitySet',
      'IdentityRemoved',
      'NoteAdded',
      'NoteRemoved',
      'VerificationSubmitted',
      'VerificationFinished',
    ],
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    select: (item) => item,
  });

  useWebSocketMessage<WorkspaceAccountSchema>({
    domain: 'Account',
    onMessage: (message) => {
      const { payload } = message;

      if (message.meta_info?.maintenance_id === workspaceId) {
        const checkLogin = () => {
          if (!rest.login_in) return true;

          return rest.login_in
            .split(',')
            .some((item) =>
              message.payload.login.toLocaleLowerCase().includes(item.toLocaleLowerCase())
            );
        };

        const check = () => checkMessage(rest, message.payload) && checkLogin();

        if (message.action === 'Created' && check()) {
          if (message.payload.stage.order === currentStage) {
            createInfinitePaginatedQueryData(queryKey, message.payload, { total: true });
          }
        }

        if (message.action === 'StageChanged' && !search && !displayAll && check()) {
          if (message.payload.stage.order === currentStage) {
            createInfinitePaginatedQueryData(queryKey, message.payload, { total: true });
          }

          deleteInfinitePaginatedQueryData<WorkspaceAccountSchema>(
            queryKey,
            (item) => item.stage.order !== currentStage,
            true
          );
        }

        if (message.action === 'RemovedFromWorkspace' && check()) {
          deleteInfinitePaginatedQueryData<WorkspaceAccountSchema>(
            queryKey,
            (item) => item.id === payload.id,
            true
          );
        }
      }
    },
  });

  const { stage } = useStages({ workspaceId });

  useWebSocketMessage<WorkspaceStageSchema>({
    domain: 'Stage',
    onMessage: (message) => {
      if (message.meta_info?.maintenance_id === workspaceId) {
        if (message.action === 'Created') {
          createPaginatedQueryData(stagesQueryKey, message.payload);
        }

        if (message.action === 'Edited') {
          updatePaginatedQueryData(
            stagesQueryKey,
            message.payload,
            (item) => item.id === message.payload.id
          );
        }

        if (message.action === 'Deleted') {
          deletePaginatedQueryData<WorkspaceStageSchema>(
            stagesQueryKey,
            (item) => item.id === message.payload.id
          );
        }
      }
    },
  });

  const currentColumns = stage?.columns;

  const columnResize = useTableColumnResize({ workspaceId, stage });

  const getColumns = (): Columns<WorkspaceAccountSchema> => {
    if (currentColumns) {
      const columns: Columns<WorkspaceAccountSchema> = currentColumns.map((item) => {
        const sorting = getSortingKey(item);

        return {
          key: sorting || String(item),
          title: COLUMNS_LABELS[item],
          sorting: Boolean(sorting),
          resize: true,
          style: { width: COLUMNS_WIDTHS[item] },
          render: ({ values }) => {
            const content = getContent(values)[item];

            if (item === COLUMNS.PILOT_STATUS) {
              return <div className={styles.buttons}>{content}</div>;
            }

            return content;
          },
        };
      });

      return [
        {
          key: 'login',
          title: t('common.login'),
          sorting: true,
          render: ({ values }) => (
            <WorkspaceAccountDetailLink workspaceId={workspaceId} data={values} />
          ),
          pin: 'left',
          select: true,
          resize: true,
          style: { width: 182 },
        },
        ...addWhen<Column<WorkspaceAccountSchema>>(
          {
            title: t('common.stage'),
            render: ({ values }) => (
              <Tag>
                <Tag.FadingText text={values.stage.name} />
              </Tag>
            ),
            resize: true,
            style: { width: 100 },
          },
          Boolean(search || displayAll)
        ),
        ...columns,
        {
          ...(!displayAll && {
            title: (
              <WorkspaceAccountTableViewDialog workspaceId={workspaceId} queryKey={queryKey} />
            ),
          }),
          render: ({ values }) => (
            <WorkspaceAccountDropdown data={values} workspaceId={workspaceId} />
          ),
          align: 'right',
          pin: 'right',
          controls: true,
          style: { width: 32, marginLeft: 'auto' },
        },
      ];
    }

    return [];
  };

  const getCardFields = (values: WorkspaceAccountSchema) => {
    if (currentColumns) {
      return currentColumns
        .filter((item) => item !== COLUMNS.PILOT_STATUS)
        .map((item) => (
          <Card.Field label={COLUMNS_LABELS[item]}>{getContent(values)[item]}</Card.Field>
        ));
    }

    return [];
  };

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <WorkspaceAccountsProvider
      key={String(displayAll)}
      displayAll={displayAll}
      setDisplayAll={setDisplayAll}
    >
      <Table.Provider ids={data.map((item) => item.id)} disabledSelectAll={Boolean(search)}>
        <Page
          heading={t('common.accounts')}
          tabs={[
            {
              to: routes.workspaceAccounts({ workspaceId }),
              label: t('common.accounts'),
              active: true,
            },
            {
              to: routes.workspaceSessions({ workspaceId }),
              label: t('common.sessions'),
            },
          ]}
          extra={
            <PageControls ignoredParams={['stage_order_eq']}>
              <WorkspaceAccountCreateButton workspaceId={workspaceId} />
            </PageControls>
          }
          filter={<WorkspaceAccountFilter workspaceId={workspaceId} total={total} />}
          addon={<Outlet />}
        >
          {!search && <WorkspaceAccountStages workspaceId={workspaceId} />}
          <PageTop
            total={total}
            loading={loading}
            {...(isPhone && {
              extra: (
                <WorkspaceAccountTableViewDialog workspaceId={workspaceId} queryKey={queryKey} />
              ),
              sorting: sortings.map((item) => {
                const default_sorting = stage?.default_sorting;

                const isDefault = default_sorting?.name === item;

                return {
                  key: item,
                  title: getSortingLabel(item),
                  default: isDefault,
                  ...(default_sorting && isDefault && { defaultOrder: default_sorting.direction }),
                };
              }),
            })}
          />
          {(isDesktop || isTablet) && stage && (
            <Table
              data={data}
              search={search}
              columns={getColumns()}
              {...getTableProps(query)}
              renderRow={(row, index) => (
                <Table.Row active={row.data.id === accountId} {...checkIsInView(index)} {...row} />
              )}
              selectionActions={<WorkspaceAccountTableSelectionActions workspaceId={workspaceId} />}
              {...columnResize}
            />
          )}
          {isPhone && (
            <Card.Group>
              {data.map((item, index) => (
                <Card key={item.id} {...checkIsInView(index)}>
                  <Card.Top>
                    <WorkspaceAccountDetailLink workspaceId={workspaceId} data={item} />
                    <Card.Top.Controls>
                      {getContent(item)[COLUMNS.PILOT_STATUS]}
                      <WorkspaceAccountDropdown data={item} workspaceId={workspaceId} />
                    </Card.Top.Controls>
                  </Card.Top>
                  <Card.Field.Group>
                    {search && <Card.Field label={t('common.stage')}>{item.stage.name}</Card.Field>}
                    {...getCardFields(item)}
                  </Card.Field.Group>
                </Card>
              ))}
            </Card.Group>
          )}
        </Page>
      </Table.Provider>
    </WorkspaceAccountsProvider>
  );
};

export default WorkspaceAccountsPage;
