import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import { formatAbsoluteDate } from '../../utils/date';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';
import useForm from './useForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: IdentitySchema;
  disabled: boolean;
}

const WorkspaceIdentityDetailGeneralTab = (props: Props) => {
  const { workspaceId, data, disabled } = props;

  const { t } = useTranslation();

  const { id, modified_on, created_on } = data;

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  const { fields, form } = useForm({ workspaceId, data, disabled });

  return (
    <FormProvider {...form}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        {fields.first_name}
        {fields.last_name}
        {fields.date_of_birth}
        {fields.gender}
        {fields.country}
        {fields.city}
        {!isPokerFarm && fields.channel}
        {!isPokerFarm && fields.rooms}
        {fields.description}
        {fields.phones}
        {fields.online_wallets}
        {fields.bank_accounts}
        {fields.documents}
        {fields.photos}
        {fields.social_networks}
        <PageAddon.Field label={t('common.modified')}>
          <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
        </PageAddon.Field>
      </PageAddon.Fields>
    </FormProvider>
  );
};

export default WorkspaceIdentityDetailGeneralTab;
