import EXTENSIONS from '../constants/EXTENSIONS';
import { t } from '../i18n';

type Formatter = (value: number) => number;

export const getShortName = (value: string) => value.slice(0, 2);

export const capitalize = (value: string) => value[0].toUpperCase() + value.slice(1);

export const removeCommas = (value: string) => value.replaceAll(',', '');

export const convertDollarsToCents = (value: number) => Math.round(value * 100);
export const convertCentsToDollars = (value: number) => value / 100;

export const convertUSDTToCents = (value: number) => value * 10 ** 6;
export const convertCentsToUSDT = (value: number) => value / 10 ** 6;

export const convertStringToNumber = (value: string, formatter?: Formatter) => {
  if (value === '0') return 0;

  if (value) return formatter ? formatter(Number(value)) : Number(value);

  return null;
};

export const convertNumberToString = (value: number | null, formatter?: Formatter) => {
  if (value === 0) return '0';

  if (value) return formatter ? String(formatter(value)) : String(value);

  return '';
};

export const renderNumber = (value?: number | null, formatter?: Formatter) => {
  if (value === 0) return 0;

  if (value) return formatter ? formatter(value) : value;

  return '—';
};

export const getFileExtension = (file: string) => {
  const parts = file.split('.');
  const lastPart = parts[parts.length - 1].toLowerCase();

  return `.${lastPart}`;
};

export const checkIsMediaFile = (file: string) => {
  const mediaFilesExtensions = [EXTENSIONS.JPG, EXTENSIONS.JPEG, EXTENSIONS.PNG];

  return mediaFilesExtensions.includes(getFileExtension(file));
};

export const cutUUID = (id: string) => {
  const first = id.slice(0, 4);
  const last = id.slice(-6);

  return `${first}...${last}`;
};

export const getExtraField = (key: string, value: string | number | boolean) => {
  const getLabel = () => {
    const abbrs = ['vpip', 'ip', 'gps', 'pc'];

    return capitalize(
      key
        .split('_')
        .map((item) => (abbrs.includes(item) ? item.toUpperCase() : item))
        .join(' ')
    );
  };

  const getValue = () => {
    if (typeof value === 'string' && !value) return '—';
    if (typeof value === 'boolean') return t(`common.${value}`);

    return value;
  };

  return { label: getLabel(), value: getValue(), key };
};

export const snakeToSentenceCase = (string: string) =>
  `${string[0].toLocaleUpperCase()}${string.split('_').join(' ').toLocaleLowerCase().slice(1)}`;

export const secondsToHours = (value: number) => {
  const rawMinutes = value / 60;

  const hours = Math.floor(rawMinutes / 60);
  const minutes = rawMinutes % 60;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}${formattedMinutes}`;
};

export const hoursToSeconds = (value: string) => {
  const hours = Number(value.slice(0, 2));
  const minutes = Number(value.slice(3, 5));

  return hours * 60 * 60 + minutes * 60;
};

export const displayMinuteInHours = (value: number | null) => {
  if (!value || value < 0) return '—';

  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  if (minutes < 10) return `${hours}:0${minutes} h`;

  return `${hours}:${minutes} h`;
};

export const getSelectOptions = <T extends Record<string, T[keyof T]>>(
  enums: T,
  labels: Record<number, string>
) =>
  Object.values(enums)
    .filter((item) => item !== Object.values(enums)[0])
    .map((item) => ({
      value: item,
      label: typeof item === 'number' ? labels[item] : String(item),
    }));
