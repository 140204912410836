import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import { getRentalState } from './helpers';
import useForm from './useForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: IdentitySchema;
  disabled: boolean;
}

const WorkspaceIdentityDetailRentalTab = (props: Props) => {
  const { workspaceId, data, disabled } = props;

  const { t } = useTranslation();

  const { form, fields } = useForm({ workspaceId, data, disabled });

  return (
    <FormProvider {...form}>
      <PageAddon.Fields>
        {fields.monthly_cost}
        {fields.minimal_rental_period}
        <PageAddon.Field label={t('common.rental_state')}>{getRentalState(data)}</PageAddon.Field>
      </PageAddon.Fields>
    </FormProvider>
  );
};

export default WorkspaceIdentityDetailRentalTab;
