import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceAccountSchema, {
  WorkspaceAccountPasswordSchema,
  WorkspaceAccountSchema,
} from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceAccountSchema;

interface Payload {
  login: Schema['login'];
  password: WorkspaceAccountPasswordSchema;
  room: Schema['room'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceAccount = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account`, payload);

  return workspaceAccountSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceAccount;
