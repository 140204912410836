import { useState } from 'react';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import GlobalAccountCredentialsDialog from './GlobalAccountCredentialsDialog';
import GlobalAccountVerificationCodeDialog from './GlobalAccountVerificationCodeDialog';
import useDropdown from './useDropdown';

interface Props {
  data: GlobalAccountSchema;
}

const GlobalAccountDetailDropdown = (props: Props) => {
  const { data } = props;

  const [type, setType] = useState<'credentials' | 'verification' | null>(null);

  const { title, view, options, setDefaultView } = useDropdown({
    data,
    credentials: () => setType('credentials'),
    verificationCode: () => setType('verification'),
  });

  const getOptions = () => {
    if (view === 'verification') {
      return compact([
        options.back,
        options.divider,
        options.sumbitVerification,
        options.finishVerification,
        options.showVerificationCode,
      ]);
    }

    return compact([
      options.resetCache,
      options.credentials,
      options.verification,
      options.divider,
      options.closePokerRoom,
      options.terminate,
    ]);
  };

  const onClose = () => setType(null);

  return (
    <>
      {type === 'credentials' && (
        <GlobalAccountCredentialsDialog accountId={data.id} onClose={onClose} />
      )}
      {type === 'verification' && (
        <GlobalAccountVerificationCodeDialog accountId={data.id} onClose={onClose} />
      )}
      <Dropdown title={title} buttonSize="medium" options={getOptions()} onClose={setDefaultView} />
    </>
  );
};

export default GlobalAccountDetailDropdown;
