import { useTranslation } from 'react-i18next';
import { pick, keys } from 'remeda';
import { bulkUpdateWorkspaceAccounts, bulkUpdateFilteredWorkspaceAccounts } from '../../../../api';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Form from '../../../../components/Form';
import PageAddon from '../../../../components/PageAddon';
import SubmitButton from '../../../../components/SubmitButton';
import routes from '../../../../config/routes';
import useURL from '../../../../hooks/useURL';
import useBulkActions from '../../useBulkActions';
import useForm from '../../useForm';
import useSelectedFields from '../../useSelectedFields';
import WorkspaceAccountFormAdd from '../WorkspaceAccountFormAdd';
import WorkspaceAccountFormField from '../WorkspaceAccountFormField';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountBulkForm = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const { navigate } = useURL();

  const { form, fields: formFields, getPayload } = useForm({ workspaceId, bulk: true });

  const { ids, params, selectAllMode, handle } = useBulkActions();

  const fields = {
    club_ids: formFields.club_ids,
    game_types: formFields.game_types,
    comparative_limits: formFields.comparative_limits,
    table_sizes: formFields.table_sizes,
    max_tables: formFields.max_tables,
    tag_ids: formFields.tag_ids,
    affiliate_reference_number: formFields.affiliate_reference_number,
    user_id: formFields.user_id,
    identity_id: formFields.identity_id,
    bot_settings_ids: formFields.bot_settings_ids,
    deal_ids: formFields.deal_ids,
  } as const;

  const labels: Record<keyof typeof fields, string> = {
    club_ids: t('common.clubs'),
    game_types: t('common.game_types'),
    comparative_limits: t('common.limits'),
    table_sizes: t('common.table_sizes'),
    max_tables: t('common.max_tables'),
    tag_ids: t('common.tags'),
    affiliate_reference_number: t('common.affiliate'),
    user_id: t('common.assign_to'),
    identity_id: t('common.identity'),
    bot_settings_ids: t('common.bot_settings'),
    deal_ids: t('common.deals'),
  };

  const { selectedFields, disabled, addField, removeField, fieldDisabled } = useSelectedFields({
    fields,
  });

  const onClose = () => navigate(routes.workspaceAccounts({ workspaceId }));

  return (
    <PageAddon title={t('sentences.bulk_edit_accounts')} onClose={onClose}>
      <Form
        form={form}
        onSubmit={(values) =>
          handle(async () => {
            const fn = selectAllMode
              ? bulkUpdateFilteredWorkspaceAccounts
              : bulkUpdateWorkspaceAccounts;

            await fn({
              workspaceId,
              payload: {
                account_ids: ids,
                to_edit: pick(getPayload(values), selectedFields),
              },
              params,
            });

            onClose();
          })
        }
      >
        {selectedFields.map((item) => {
          const field = fields[item];

          return (
            <WorkspaceAccountFormField key={item} onRemove={() => removeField(item)}>
              {typeof field === 'function' ? field() : field}
            </WorkspaceAccountFormField>
          );
        })}
        <WorkspaceAccountFormAdd
          options={keys(fields).map((item) => ({
            label: labels[item],
            disabled: fieldDisabled(item),
            onClick: () => addField(item),
          }))}
        />
        <PageAddon.Controls>
          <SubmitButton disabled={disabled}>{t('common.save')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceAccountBulkForm;
