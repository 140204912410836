import { COLUMNS } from '../../../api/schemas/stage/workspaceStageSchema';
import { t } from '../../../i18n';
import { COLUMNS_LABELS } from '../helpers';

export const getFieldGroups = (variant: 'view' | 'csv') => {
  const commonCoreSettingsFields = [
    { value: 3, label: t('common.poker_room') },
    { value: 4, label: t('common.club_name') },
    { value: 24, label: t('common.club_code') },
    { value: 5, label: t('common.game_types') },
    { value: 6, label: t('common.limits') },
    { value: 8, label: t('common.max_tables') },
    { value: 7, label: t('common.table_sizes') },
    { value: 11, label: t('common.geolocation') },
    { value: 12, label: t('common.channels') },
    { value: 17, label: t('common.bot_settings') },
  ];

  const commonAdditionalInfoFields = [
    { value: 16, label: t('common.tags') },
    { value: 19, label: t('common.affiliate') },
    { value: 23, label: t('common.assign_to') },
    { value: 37, label: t('common.notes') },
    { value: 10, label: t('common.pid') },
  ];

  const commonMetricsFields = [
    { value: 13, label: t('common.hands') },
    { value: 14, label: t('common.net_win') },
    { value: 15, label: t('common.bb/100') },
  ];

  const activityStatus = {
    label: t('common.activity_status'),
    fields: [
      { value: 9, label: t('common.status') },
      { value: 21, label: t('common.pilot') },
      { value: 25, label: t('common.next_session') },
    ],
  };

  const balance = {
    label: t('common.balance'),
    fields: [
      { value: 38, label: t('common.club_balance') },
      { value: 39, label: t('common.lobby_balance') },
    ],
  };

  const dates = {
    label: t('common.dates'),
    fields: [
      { value: 1, label: t('common.created') },
      { value: 2, label: t('common.modified') },
      { value: 20, label: t('common.last_online') },
      { value: 22, label: t('common.last_played') },
    ],
  };

  const identity = {
    label: t('common.identity'),
    fields: [
      { value: 18, label: t('common.identity') },
      { value: 26, label: t('common.bank_accounts') },
      { value: 27, label: t('common.date_of_birth') },
      { value: 28, label: t('common.documents') },
      { value: 29, label: t('common.full_name') },
      { value: 30, label: t('common.gender') },
      { value: 32, label: t('common.online_wallets') },
      { value: 33, label: t('common.phones') },
      { value: 34, label: t('common.photos') },
      { value: 35, label: t('common.social_networks') },
      { value: 31, label: t('common.location') },
    ],
  };

  const techInfo = {
    label: t('common.tech_info'),
    fields: [{ value: 36, label: t('common.trainer_version') }],
  };

  const strategy = [
    COLUMNS.STRATEGY_PROFILE_BUYIN_BB,
    COLUMNS.STRATEGY_PROFILE_REBUY_BELOW_BB,
    COLUMNS.STRATEGY_PROFILE_STOP_LOSE_BB,
    COLUMNS.STRATEGY_PROFILE_STOP_WIN_BB,
    COLUMNS.STRATEGY_PROFILE_MAX_SWING_BB,
    COLUMNS.STRATEGY_PROFILE_STOP_LOSE_TABLE_BB,
    COLUMNS.STRATEGY_PROFILE_STOP_WIN_TABLE_BB,
    COLUMNS.STRATEGY_PROFILE_MAX_STACK_SIZE_BB,
    COLUMNS.STRATEGY_PROFILE_EFFECTIVE_STACK_LIMIT_BB,
    COLUMNS.STRATEGY_PROFILE_TABLE_MIN_HANDS,
    COLUMNS.STRATEGY_PROFILE_BUYIN_BB_MIN,
    COLUMNS.STRATEGY_PROFILE_BUYIN_BB_MAX,
    COLUMNS.STRATEGY_PROFILE_BUYIN_BB_STRADDLE_TABLE_MIN,
    COLUMNS.STRATEGY_PROFILE_BUYIN_BB_STRADDLE_TABLE_MAX,
    COLUMNS.STRATEGY_PROFILE_REBUY_BELOW_BB_STRADDLE_TABLE,
    COLUMNS.STRATEGY_PROFILE_PLAYER_TYPE,
    COLUMNS.STRATEGY_PROFILE_BUYIN_STEP_BB,
    COLUMNS.STRATEGY_PROFILE_BUYIN_STEP_BB_STRADDLE_TABLE,
    COLUMNS.STRATEGY_PROFILE_WAIT_FOR_BIG_BLIND,
    COLUMNS.STRATEGY_PROFILE_SEATING_PREFERENCE,
  ];

  let fieldGroups;

  if (variant === 'view') {
    fieldGroups = [
      {
        label: t('common.core_settings'),
        fields: commonCoreSettingsFields,
      },
      {
        label: t('common.additional_info'),
        fields: [
          ...commonAdditionalInfoFields,
          { value: 40, label: t('common.nickname') },
          { value: 41, label: t('common.email') },
          { value: 43, label: t('common.deals') },
        ],
      },
      activityStatus,
      balance,
      dates,
      {
        label: t('common.metrics'),
        fields: [...commonMetricsFields, { value: 42, label: t('common.vpip') }],
      },
      identity,
      {
        label: t('common.strategy'),
        fields: strategy
          .filter((item) => item !== COLUMNS.STRATEGY_PROFILE_BUYIN_BB)
          .map((item) => ({ value: item, label: COLUMNS_LABELS[item] })),
      },
      techInfo,
    ];
  } else {
    fieldGroups = [
      {
        label: t('common.core_settings'),
        fields: [
          ...commonCoreSettingsFields,
          { value: 40, label: t('common.config') },
          { value: 64, label: t('common.id') },
        ],
      },
      {
        label: t('common.additional_info'),
        fields: [
          ...commonAdditionalInfoFields,
          { value: 61, label: t('common.nickname') },
          { value: 62, label: t('common.email') },
          { value: 65, label: t('common.email_password') },
          { value: 66, label: t('common.deals') },
        ],
      },
      activityStatus,
      balance,
      dates,
      {
        label: t('common.metrics'),
        fields: [...commonMetricsFields, { value: 63, label: t('common.vpip') }],
      },
      identity,
      {
        label: t('common.strategy'),
        // КОСТЫЛЬ!
        // тут вычитается магическое число 3 - это сдвиг енамов между экспортом csv и колонками стейджа
        // вероятно придётся переписать когда появится новый енам ст профайла и перед ним будет другой енам
        fields: strategy
          .map((item) => ({ value: item - 3, label: COLUMNS_LABELS[item] }))
          .filter((item) => item.value !== COLUMNS.STRATEGY_PROFILE_BUYIN_BB - 3),
      },
      techInfo,
    ];
  }

  return fieldGroups.map((item) => ({
    ...item,
    fields: item.fields.sort((a, b) => a.label.localeCompare(b.label)),
  }));
};
