import { useQuery } from '@tanstack/react-query';
import { loadWorkspaceAccountVerificationCode } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import queryKeys from '../../config/queryKeys';
import AccountVerificationCodeDialog from '../account/AccountVerificationCodeDialog';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  onClose?: () => void;
}

const WorkspaceAccountVerificationCodeDialog = (props: Props) => {
  const { workspaceId, accountId, onClose } = props;

  const { data, isPending } = useQuery({
    queryKey: [queryKeys.workspaceAccountVerificationCode({ workspaceId, accountId })],
    queryFn: () => loadWorkspaceAccountVerificationCode({ workspaceId, accountId }),
  });

  return <AccountVerificationCodeDialog data={data} loading={isPending} onClose={onClose} />;
};

export default WorkspaceAccountVerificationCodeDialog;
