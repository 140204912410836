import clsx from 'clsx';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import { SelectProvider, useSelectContext } from '../SelectContext';
import SelectField from '../SelectField';
import SelectMultiQuick from '../SelectMultiQuick';
import SelectOptions from '../SelectOptions';
import { Props as SelectProps, Option, Options, OptionValue } from '../types';
import styles from './styles.module.scss';

interface Props<T extends OptionValue = string> extends SelectProps<T> {
  value: Options<T>;
  onChange: (options: Options<T>) => void;
  tagExtra?: (value: Option<T>) => ReactNode;
  renderOptionIcon?: (value: Option<T>) => ReactNode;
}

const Value = <T extends OptionValue = string>(props: Props<T>) => {
  const { value, onChange, tagExtra } = props;

  const { renderValueAsTag, disabled, quick, open } = useSelectContext();

  if (value.length > 0) {
    if (renderValueAsTag) {
      return value.map((item) => {
        const handleClick = () => onChange(value.filter((option) => option.value !== item.value));

        return (
          <div key={item.value} className={clsx(styles.tag, quick && styles.quick)}>
            {tagExtra && tagExtra(item)}
            {item.label}
            {(quick ? open : true) && (
              <button data-select-clear type="button" onClick={handleClick} disabled={disabled}>
                {icon('cross', 12)}
              </button>
            )}
          </div>
        );
      });
    }

    return `${value[0].label}${value.length > 1 ? ` (+${value.length - 1})` : ''}`;
  }

  return null;
};

const SelectMulti = <T extends OptionValue = string>(props: Props<T>) => {
  const {
    value,
    options = [],
    addon,
    renderValueAsTag,
    className,
    onChange,
    disabled,
    quick,
    selectAll,
    renderOptionIcon,
    ...rest
  } = props;

  const { t } = useTranslation();

  const selected = value.map((item) => item.value);

  const handleChange = (option: NonNullable<Option<T>>) => {
    const isValueSelected = selected.includes(option.value);

    const newValue = isValueSelected
      ? value.filter((item) => item.value !== option.value)
      : [...value, option];

    onChange(newValue);
  };

  const onClear = () => onChange([]);

  return (
    <SelectProvider
      multi
      quick={quick}
      options={options}
      selected={selected}
      renderValueAsTag={renderValueAsTag}
      disabled={disabled}
      {...rest}
    >
      <div {...(quick && { 'data-quick': true })} className={clsx(styles.container, className)}>
        <SelectField addon={addon} onClear={onClear}>
          {value.length ? <Value {...props} /> : null}
        </SelectField>
      </div>
      <SelectOptions onChange={handleChange} renderOptionIcon={renderOptionIcon}>
        {selectAll && (
          <div className={styles.selectAll}>
            <span>
              {value.length}
              &nbsp;
              {t('sentences.of_selected_1')}
              &nbsp;
              {options.length}
              &nbsp;
              {t('sentences.of_selected_2')}
            </span>
            <Button
              variant="light"
              size="extra-small"
              onClick={() => {
                if (value.length === options.length) {
                  onChange([]);
                } else {
                  onChange(options);
                }
              }}
            >
              {options.length === value.length ? t('common.deselect_all') : t('common.select_all')}
            </Button>
          </div>
        )}
      </SelectOptions>
    </SelectProvider>
  );
};

SelectMulti.Quick = SelectMultiQuick;

export default SelectMulti;
