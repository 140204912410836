import { ReactNode } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectMulti from './SelectMulti';
import styles from './styles.module.scss';
import { Option, Props as SelectProps } from './types';

type Props<T extends FieldValues, Name extends string> = UseControllerProps<T> &
  SelectProps<FieldValues[Name]> & {
    onSubmit?: () => void;
    tagExtra?: (value: Option<FieldValues[Name], FieldValues[Name]>) => ReactNode;
    renderOptionIcon?: (value: Option<FieldValues[Name], FieldValues[Name]>) => ReactNode;
  };

const SelectMultiQuick = <T extends FieldValues, Name extends string>(props: Props<T, Name>) => {
  const { name, control, rules, onSubmit, label, options, disabled, ...rest } = props;

  const { t } = useTranslation();

  const {
    field,
    formState: { errors },
  } = useController({ name, control, rules });

  if (disabled && !field.value.length) {
    return (
      <div data-quick className={styles.empty}>
        —
      </div>
    );
  }

  const error = errors[name];

  return (
    <SelectMulti
      quick
      renderValueAsTag
      disabled={disabled}
      placeholder={t('common.select')}
      label={label}
      options={options}
      value={field.value}
      onChange={(value) => {
        field.onChange(value);

        if (onSubmit) onSubmit();
      }}
      errorVariant="popover"
      {...(error && { error: String(error.message) })}
      {...rest}
    />
  );
};

export default SelectMultiQuick;
