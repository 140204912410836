import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceDashboardChannelsCount } from '../../../api';
import { STATUS } from '../../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import queryKeys from '../../../config/queryKeys';
import COLORS from '../../../constants/COLORS';
import { STATUS_LABELS } from '../../workspace-channel/helpers';
import WorkspaceDashboardWidget from './WorkspaceDashboardWidget';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceDashboardWidgetChannels = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const { data = [], isPending } = useQuery({
    queryKey: [queryKeys.workspaceDashboardChannelsCount({ workspaceId })],
    queryFn: () => loadWorkspaceDashboardChannelsCount({ workspaceId }),
    meta: { ignoredStatuses: [403] },
  });

  return (
    <WorkspaceDashboardWidget
      title={t('common.channels')}
      loading={isPending}
      data={data.map((item) => {
        const getColor = () => {
          if (item.status === STATUS.ACTIVE) return COLORS.GREEN_300;
          if (item.status === STATUS.INACTIVE) return COLORS.RED_300;

          return null;
        };

        return {
          label: STATUS_LABELS[item.status],
          count: item.count,
          color: getColor(),
        };
      })}
    />
  );
};

export default WorkspaceDashboardWidgetChannels;
