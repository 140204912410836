import { Suspense, lazy } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createGlobalLeague, loadGlobalSuperLeagues, updateGlobalLeague } from '../../../api';
import { GlobalLeagueSchema } from '../../../api/schemas/globalLeagueSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select, { Option } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import { maxLength, minLength, required } from '../../../utils/form';
import notify from '../../../utils/notify';
import { TRAINER_POKER_ROOM_LABELS, trainerPokerRoomOptions } from '../../../utils/trainer';
import styles from './styles.module.scss';

const CodeEditor = lazy(() => import('../../../components/CodeEditor'));

interface Fields {
  name: string;
  code: string;
  poker_room: Option<GlobalLeagueSchema['poker_room']>;
  super_league_id: Option;
  extra: string;
}

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { leagueId: GlobalLeagueSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: GlobalLeagueSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalLeagueForm = (props: Props) => {
  const { data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      name: data?.name || '',
      code: data?.code || '',
      poker_room: data
        ? { value: data.poker_room, label: TRAINER_POKER_ROOM_LABELS[data.poker_room] }
        : null,
      super_league_id: data?.super_league
        ? { value: data.super_league.id, label: data.super_league.code }
        : null,
      extra: data?.extra ? JSON.stringify(data.extra, null, 1) : '',
    },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.poker_room) throw new Error();

    const payload = {
      name: values.name,
      code: values.code,
      poker_room: values.poker_room.value,
      super_league_id: values.super_league_id?.value || null,
      extra: values.extra ? JSON.parse(values.extra) : null,
    };

    if (data) {
      await updateGlobalLeague({
        leagueId: data.id,
        payload: {
          name: payload.name,
          super_league_id: payload.super_league_id,
          extra: payload.extra,
        },
      });

      onEdit();
    } else {
      const response = await createGlobalLeague({ payload });

      onCreate({ leagueId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      title={data ? `${t('common.edit')} ${data.code}` : t('common.create_league')}
      onClose={onClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        {!data && (
          <Input
            label={`${t('common.code')}*`}
            error={errors.code?.message}
            {...register('code', {
              validate: {
                required,
                minLength: minLength(),
                maxLength: maxLength(),
              },
            })}
          />
        )}
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', {
            validate: {
              required,
              maxLength: maxLength(),
            },
          })}
        />
        {!data && (
          <Controller
            name="poker_room"
            control={control}
            rules={{ validate: { required } }}
            render={({ field }) => (
              <Select
                clearable={false}
                label={`${t('common.poker_room')}*`}
                options={trainerPokerRoomOptions}
                value={field.value}
                onChange={field.onChange}
                error={errors.poker_room?.message}
              />
            )}
          />
        )}
        <Controller
          name="super_league_id"
          control={control}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalSuperLeagues(args);

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.code,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  label={t('common.super_league')}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.super_league_id?.message}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <PageAddon.Heading className={styles.heading}>{t('common.extra_fields')}</PageAddon.Heading>
        <Suspense fallback={null}>
          <Controller
            name="extra"
            control={control}
            render={({ field }) => (
              <CodeEditor
                value={field.value}
                onChange={field.onChange}
                error={errors.extra?.message}
              />
            )}
          />
        </Suspense>
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalLeagueForm;
