import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalBonuses } from '../../api';
import { GlobalBonusSchema } from '../../api/schemas/globalBonusSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import DealClub from '../../features/deal/DealClub';
import DealCode from '../../features/deal/DealCode';
import GlobalBonusCreateButton from '../../features/global-bonus/GlobalBonusCreateButton';
import GlobalBonusDetailLink from '../../features/global-bonus/GlobalBonusDetailLink';
import GlobalBonusDropdown from '../../features/global-bonus/GlobalBonusDropdown';
import GlobalBonusFilter from '../../features/global-bonus/GlobalBonusFilter';
import { TYPE_LABELS } from '../../features/global-bonus/helpers';
import { getAmount } from '../../features/global-deal/helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { can } from '../../utils/permissions';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.globalBonus>;

const GlobalBonusesPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { bonusId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.globalBonuses, urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadGlobalBonuses({ ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<GlobalBonusSchema>({
    queryKey,
    domain: 'Bonus',
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.bonuses')}
      tabs={[
        { to: routes.globalDeals, label: t('common.deals') },
        ...addWhen({ to: routes.globalAgents, label: t('common.agents') }, can.agent.read),
        { to: routes.globalBonuses, label: t('common.bonuses'), active: true },
        { to: routes.globalExpenses, label: t('common.expenses') },
      ]}
      filter={<GlobalBonusFilter />}
      extra={
        <PageControls>
          <GlobalBonusCreateButton />
        </PageControls>
      }
      addon={<Outlet />}
    >
      {isPhone && (
        <PageTop
          sorting={[
            { key: 'date', title: t('common.date'), default: true },
            { key: 'amount', title: t('common.amount') },
            { key: 'created_on', title: t('common.created') },
          ]}
        />
      )}
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'date',
              sorting: true,
              title: t('common.date'),
              render: ({ values }) => <GlobalBonusDetailLink data={values} />,
            },
            {
              title: t('common.deal'),
              render: ({ values }) => <DealCode data={values.deal} />,
            },
            {
              title: t('common.poker_room'),
              render: ({ values }) => TRAINER_POKER_ROOM_LABELS[values.deal.poker_room],
            },
            {
              title: t('common.club'),
              render: ({ values }) => <DealClub data={values.deal} />,
            },
            {
              title: t('common.type'),
              render: ({ values }) => TYPE_LABELS[values.type],
            },
            {
              key: 'amount',
              sorting: true,
              title: t('common.amount'),
              render: ({ values }) => getAmount(values),
            },
            {
              render: ({ values }) => <GlobalBonusDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === bonusId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalBonusDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalBonusDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.deal')}>
                  <DealCode data={item.deal} />
                </Card.Field>
                <Card.Field label={t('common.type')}>{TYPE_LABELS[item.type]}</Card.Field>
                <Card.Field label={t('common.amount')}>{getAmount(item)}</Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalBonusesPage;
