import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { createGlobalAgent, loadGlobalAgents, loadGlobalUsers, updateGlobalAgent } from '../../api';
import { AgentSchema, CONTACT_TYPE } from '../../api/schemas/agentSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import Dropdown, { presets } from '../../components/Dropdown';
import Input from '../../components/Input';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select, { Option, Options } from '../../components/Select';
import Textarea from '../../components/Textarea';
import useQuickForm from '../../hooks/useQuickForm';
import useURL from '../../hooks/useURL';
import { required } from '../../utils/form';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';
import {
  TYPE_LABELS,
  CONTACT_TYPE_LABELS,
  PAYMENT_SYSTEM_LABELS,
  typesOptions,
  paymentSystemOptions,
  contactTypesOptions,
} from './helpers';

interface Fields {
  name: string;
  type: Option<AgentSchema['type']>;
  holder: Option<GlobalUserSchema['id']>;
  payment_system: Options<AgentSchema['payment_system'][number]>;
  related_agent: Option<AgentSchema['id']>;
  contacts: {
    type: Option<AgentSchema['contacts'][number]['type']>;
    address: AgentSchema['contacts'][number]['address'];
    description: Exclude<AgentSchema['contacts'][number]['description'], null>;
    append: boolean;
    open: boolean;
  }[];
}

const getDefaultValues = (data?: AgentSchema): Fields => ({
  name: data?.name || '',
  type: data?.type ? { value: data.type, label: TYPE_LABELS[data.type] } : null,
  holder: data?.holder ? { value: data.holder.id, label: data.holder.username } : null,
  payment_system: data
    ? data.payment_system.map((item) => ({
        value: item,
        label: PAYMENT_SYSTEM_LABELS[item],
      }))
    : [],
  related_agent: data?.related_agent
    ? { value: data.related_agent.id, label: data.related_agent.name }
    : null,
  contacts: data
    ? data.contacts.map((item) => ({
        type: { value: item.type, label: CONTACT_TYPE_LABELS[item.type] },
        address: item.address,
        description: item.description || '',
        append: false,
        open: false,
      }))
    : [],
});

interface Args {
  data?: AgentSchema;
  onCreate?: (args: { agentId: AgentSchema['id'] }) => void;
  getAgentRoute?: (args: { relatedAgentId: AgentSchema['id'] }) => string;
}

const useForm = (args: Args) => {
  const { data, onCreate, getAgentRoute } = args;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const form = useQuickForm<Fields>({ data, defaultValues: getDefaultValues(data) });

  const { control, formState, handleSubmit, watch, setValue } = form;

  const { fields, prepend, remove, update } = useFieldArray({ control, name: 'contacts' });

  const onSubmit = async (values: Fields) => {
    if (!values.type) throw new Error();

    const commonPayload = {
      name: values.name,
      type: values.type.value,
    };

    if (data) {
      await updateGlobalAgent({
        agentId: data.id,
        payload: {
          ...commonPayload,
          holder_id: values.holder?.value || null,
          payment_system: values.payment_system.map((item) => item.value),
          related_agent_id: values.related_agent?.value || null,
          contacts: values.contacts.map((item) => ({
            type: z.object({ value: z.nativeEnum(CONTACT_TYPE) }).parse(item.type).value,
            address: item.address,
            description: item.description,
          })),
        },
      });
    } else {
      const response = await createGlobalAgent({ payload: commonPayload });

      if (onCreate) {
        onCreate({ agentId: response.id });
      }

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const detail = data && !onCreate;

  const disabled = !can.agent.createUpdate;

  const commonProps = { control, disabled, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  return {
    form,
    onSubmit,
    fields: {
      name: (
        <PageAddon.Field label={t('common.name')}>
          <Input.Quick name="name" rules={{ validate: { required } }} {...commonProps} />
        </PageAddon.Field>
      ),
      type: (
        <PageAddon.Field label={t('common.type')}>
          <Select.Quick
            name="type"
            options={typesOptions}
            rules={{ validate: { required } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      holder: (
        <PageAddon.Field label={t('common.holder')}>
          <Select.Async
            onLoad={async (params) => {
              const response = await loadGlobalUsers(params);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.username,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick clearable name="holder" {...commonProps} {...selectAsyncProps} />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      payment_system: (
        <PageAddon.Field label={t('common.payment_system')}>
          <Select.Multi.Quick
            name="payment_system"
            options={paymentSystemOptions}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      related_agent: (
        <PageAddon.Field label={t('common.related_agent')}>
          <Select.AsyncV2
            onLoad={loadGlobalAgents}
            select={(item) => ({ value: item.id, label: item.name })}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                clearable
                name="related_agent"
                {...(data?.related_agent &&
                  getAgentRoute && {
                    extra: (
                      <LinkButton
                        size="extra-small"
                        variant="light"
                        onClick={() => {
                          if (data.related_agent) {
                            navigate(getAgentRoute({ relatedAgentId: data.related_agent.id }));
                          }
                        }}
                      />
                    ),
                  })}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
      ),
      contacts: (
        <PageAddon.NewCollapse.Group
          label={t('common.contacts')}
          onAdd={() =>
            prepend(
              { type: null, address: '', description: '', append: true, open: true },
              { shouldFocus: false }
            )
          }
        >
          {fields.map((item, index) => {
            const value = watch(`contacts.${index}`);

            return (
              <PageAddon.NewCollapse
                key={item.id}
                open={value.open}
                onToggle={(open) => setValue(`contacts.${index}.open`, open)}
                label={value.type ? CONTACT_TYPE_LABELS[value.type.value] : t('common.new_contact')}
                {...(item.append && {
                  onCreate: () => {
                    handleSubmit(onSubmit)();

                    if (formState.isValid) {
                      update(index, { ...value, append: false });
                    }
                  },
                })}
                controls={
                  <Dropdown
                    options={[
                      {
                        ...presets.delete,
                        onClick: () => {
                          remove(index);

                          if (!item.append) {
                            handleSubmit(onSubmit)();
                          }
                        },
                      },
                    ]}
                  />
                }
              >
                <PageAddon.NewCollapse.Field label={t('common.type')}>
                  <Select.Quick
                    control={control}
                    name={`contacts.${index}.type`}
                    options={contactTypesOptions}
                    rules={{ validate: { required } }}
                    {...(!item.append && { onSubmit: handleSubmit(onSubmit) })}
                  />
                </PageAddon.NewCollapse.Field>
                <PageAddon.NewCollapse.Field label={t('common.address')}>
                  <Input.Quick
                    control={control}
                    name={`contacts.${index}.address`}
                    rules={{ validate: { required } }}
                    {...(!item.append && { onSubmit: handleSubmit(onSubmit) })}
                  />
                </PageAddon.NewCollapse.Field>
                <PageAddon.NewCollapse.Field label={t('common.description')}>
                  <Textarea.Quick
                    control={control}
                    name={`contacts.${index}.description`}
                    {...(!item.append && { onSubmit: handleSubmit(onSubmit) })}
                  />
                </PageAddon.NewCollapse.Field>
              </PageAddon.NewCollapse>
            );
          })}
        </PageAddon.NewCollapse.Group>
      ),
    },
  };
};

export default useForm;
