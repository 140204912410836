import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const finishGlobalAccountVerification = async (args: Args) => {
  const { accountId } = args;

  const response = await api.patch(`/api/account/${accountId}/verification-finish`);

  return emptyResponseSchema.parse(response.data);
};

export default finishGlobalAccountVerification;
