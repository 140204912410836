import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import { AccountStrategySchema } from '../schemas/accountStrategySchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = AccountStrategySchema;

interface Payload {
  player_type?: Schema['player_type'];
  buyin_bb_min?: Schema['buyin_bb_min'] | null;
  buyin_bb_max?: Schema['buyin_bb_max'] | null;
  buyin_bb_straddle_table_min?: Schema['buyin_bb_straddle_table_min'] | null;
  buyin_bb_straddle_table_max?: Schema['buyin_bb_straddle_table_max'] | null;
  rebuy_below_bb?: Schema['rebuy_below_bb'] | null;
  rebuy_below_bb_straddle_table?: Schema['rebuy_below_bb_straddle_table'] | null;
  stop_lose_bb?: Schema['stop_lose_bb'] | null;
  stop_win_bb?: Schema['stop_win_bb'] | null;
  stop_lose_table_bb?: Schema['stop_lose_table_bb'] | null;
  stop_win_table_bb?: Schema['stop_win_table_bb'] | null;
  max_swing_bb?: Schema['max_swing_bb'] | null;
  effective_stack_limit_bb?: Schema['effective_stack_limit_bb'] | null;
  max_stack_size_bb?: Schema['max_stack_size_bb'] | null;
  table_min_hands?: Schema['table_min_hands'] | null;
  wait_for_big_blind?: Schema['wait_for_big_blind'] | null;
  seating_preference?: Schema['seating_preference'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  payload: Payload;
}

const updateWorkspaceAccountStrategy = async (args: Args) => {
  const { workspaceId, accountId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/strategy-profile`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceAccountStrategy;
