import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalBonusesFilterValues } from '../../api';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';

interface Fields {
  deal_id_in: string[];
  date_ge: string;
  date_le: string;
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
  show_deleted: boolean;
}

const GlobalBonusFilter = () => {
  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.globalBonusesFilterValues, urlParams.show_deleted];
  const queryFn = () => loadGlobalBonusesFilterValues({ show_deleted: urlParams.show_deleted });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    deal_id_in: 'multi',
    date_ge: 'single',
    date_le: 'single',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    deal_id_in,
    date_ge,
    date_le,
    updated_ge,
    updated_le,
    created_ge,
    created_le,
    show_deleted,
  } = values;

  const filterProps = { filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { deal } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {deal.length !== 0 && (
          <Filter.Collapse
            label={t('common.deal')}
            count={deal_id_in.length}
            onClear={clearValue('deal_id_in')}
          >
            <Filter.MultiCheckbox
              config={deal.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('deal_id_in'),
                checked: deal_id_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.date')}
          {...((date_ge || date_le) && {
            onClear: clearValue(['date_ge', 'date_le']),
          })}
        >
          <Filter.Date
            from={{ value: date_ge, onChange: setValue('date_ge') }}
            to={{ value: date_le, onChange: setValue('date_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default GlobalBonusFilter;
