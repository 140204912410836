import { useTranslation } from 'react-i18next';
import { AccountVerificationCodeSchema } from '../../../api/schemas/account/accountVerificationCodeSchema';

import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Placeholder from '../../../components/Placeholder';
import styles from './styles.module.scss';

interface Props {
  loading: boolean;
  data?: AccountVerificationCodeSchema;
  onClose?: () => void;
}

const AccountVerificationCodeDialog = (props: Props) => {
  const { data, loading, onClose } = props;

  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose}>
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title>{t('common.verification_code')}</Dialog.Title>
          </Dialog.Top>
          <div className={styles.container}>
            {loading &&
              Array.from({ length: 6 }).map((_, index) => (
                <Placeholder key={index} className={styles.placeholder} />
              ))}
            {data &&
              Array.from(String(data.verification_code)).map((item, index) => (
                <div key={index} className={styles.cell}>
                  {item}
                </div>
              ))}
          </div>
          <Dialog.Footer>
            <Button onClick={() => close()}>{t('common.ok')}</Button>
          </Dialog.Footer>
        </>
      )}
    </Dialog>
  );
};

export default AccountVerificationCodeDialog;
