import { useTranslation } from 'react-i18next';
import {
  createGlobalDeal,
  loadGlobalAgents,
  loadGlobalClubs,
  loadGlobalUsers,
  loadGlobalWorkspaces,
  updateGlobalDeal,
} from '../../api';
import { AgentSchema } from '../../api/schemas/agentSchema';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select, { Option, Options } from '../../components/Select';
import Textarea from '../../components/Textarea';
import useQuickForm from '../../hooks/useQuickForm';
import useURL from '../../hooks/useURL';
import { convertNumberToString, convertStringToNumber, removeCommas } from '../../utils/data';
import { bigNumber, max, required } from '../../utils/form';
import notify from '../../utils/notify';
import { TRAINER_POKER_ROOM_LABELS, trainerPokerRoomOptions } from '../../utils/trainer';
import DealBadge from './DealBadge';
import DealCredit from './DealCredit';
import DealProtection from './DealProtection';
import DealRole from './DealRole';
import DealStatus from './DealStatus';
import {
  CREDIT_LABELS,
  PROTECTION_LABELS,
  STATUS_LABELS,
  ROLE_LABELS,
  creditOptions,
  protectionOptions,
  statusOptions,
  roleOptions,
} from './helpers';
import { CommonDealSchema } from './types';

interface Fields {
  agent_id: Option<CommonDealSchema['agent']['id']>;
  poker_room: Option<CommonDealSchema['poker_room']>;
  club_id: Option<CommonDealSchema['club']['id']>;
  area_id: string;
  area2_id: string;
  holder_user_id: Option<Exclude<CommonDealSchema['holder'], null>['id']>;
  owner: string;
  status: Option<CommonDealSchema['status']>;
  role: Option<CommonDealSchema['role']>;
  credit: Option<CommonDealSchema['credit']>;
  protection: Option<CommonDealSchema['protection']>;
  currency: string;
  chip_rate: string;
  rake_back: string;
  action: string;
  rev_share: string;
  win_share: string;
  rebate: string;
  agent_fee: string;
  withdraw_fee: string;
  hand_cost: string;
  fixed_payment: string;
  income_cap: string;
  workspace_ids: Options<GlobalDealSchema['workspaces'][number]['id']>;
  financial_conditions: string;
  operating_conditions: string;
  policy: string;
  description: string;
  split_accounting: boolean;
}

interface Args {
  data?: CommonDealSchema;
  workspaces?: GlobalDealSchema['workspaces'];
  disabled?: boolean;
  onCreate?: (args: { dealId: CommonDealSchema['id'] }) => void;
  getClubRoute?: (args: { clubId: GlobalClubSchema['id'] }) => string;
  getAgentRoute?: (args: { agentId: AgentSchema['id'] }) => string;
}

const useForm = (args: Args) => {
  const { data, workspaces, disabled, onCreate, getClubRoute, getAgentRoute } = args;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      agent_id: data ? { label: data.agent.name, value: data.agent.id } : null,
      poker_room: data
        ? { label: TRAINER_POKER_ROOM_LABELS[data.poker_room], value: data.poker_room }
        : null,
      club_id: data
        ? { label: data.club.name, value: data.club.id, caption: data.club.code }
        : null,
      area_id: data?.area_id || '',
      area2_id: data?.area2_id || '',
      holder_user_id: data?.holder ? { label: data.holder.username, value: data.holder.id } : null,
      owner: data?.owner || '',
      status: data
        ? { value: data.status, label: STATUS_LABELS[data.status] }
        : { value: statusOptions[0].value, label: statusOptions[0].label },
      role: data
        ? { value: data.role, label: ROLE_LABELS[data.role] }
        : { value: roleOptions[0].value, label: roleOptions[0].label },
      credit: data
        ? { value: data.credit, label: CREDIT_LABELS[data.credit] }
        : { value: creditOptions[0].value, label: creditOptions[0].label },
      protection: data
        ? { value: data.protection, label: PROTECTION_LABELS[data.protection] }
        : { value: protectionOptions[0].value, label: protectionOptions[0].label },
      currency: data?.currency || '',
      chip_rate: data ? convertNumberToString(data.chip_rate) : '',
      rake_back: data ? convertNumberToString(data.rake_back) : '',
      action: data ? convertNumberToString(data.action) : '',
      rev_share: data ? convertNumberToString(data.rev_share) : '',
      win_share: data ? convertNumberToString(data.win_share) : '',
      rebate: data ? convertNumberToString(data.rebate) : '',
      agent_fee: data ? convertNumberToString(data.agent_fee) : '',
      withdraw_fee: data ? convertNumberToString(data.withdraw_fee) : '',
      hand_cost: data ? convertNumberToString(data.hand_cost) : '',
      fixed_payment: data ? convertNumberToString(data.fixed_payment) : '',
      income_cap: data ? convertNumberToString(data.income_cap) : '',
      workspace_ids: workspaces
        ? workspaces.map((item) => ({ value: item.id, label: item.name }))
        : [],
      financial_conditions: data?.financial_conditions || '',
      operating_conditions: data?.operating_conditions || '',
      policy: data?.policy || '',
      description: data?.description || '',
      split_accounting: data?.split_accounting || false,
    },
  });

  const { control, watch, setValue, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.poker_room) throw new Error();
    if (!values.club_id) throw new Error();
    if (!values.agent_id) throw new Error();

    const commonPayload = {
      agent_id: values.agent_id.value,
      poker_room: values.poker_room.value,
      club_id: values.club_id.value,
    };

    if (data) {
      await updateGlobalDeal({
        dealId: data.id,
        payload: {
          ...commonPayload,
          area_id: values.area_id || null,
          area2_id: values.area2_id || null,
          holder_user_id: values.holder_user_id?.value || null,
          owner: values.owner || null,
          status: values.status?.value || null,
          role: values.role?.value || null,
          credit: values.credit?.value || null,
          protection: values.protection?.value || null,
          currency: values.currency || null,
          chip_rate: convertStringToNumber(removeCommas(values.chip_rate)),
          rake_back: convertStringToNumber(removeCommas(values.rake_back)),
          action: convertStringToNumber(removeCommas(values.action)),
          rev_share: convertStringToNumber(removeCommas(values.rev_share)),
          win_share: convertStringToNumber(removeCommas(values.win_share)),
          rebate: convertStringToNumber(removeCommas(values.rebate)),
          agent_fee: convertStringToNumber(removeCommas(values.agent_fee)),
          withdraw_fee: convertStringToNumber(removeCommas(values.withdraw_fee)),
          hand_cost: convertStringToNumber(removeCommas(values.hand_cost)),
          fixed_payment: convertStringToNumber(removeCommas(values.fixed_payment)),
          income_cap: convertStringToNumber(removeCommas(values.income_cap)),
          workspace_ids: values.workspace_ids.map((item) => item.value),
          financial_conditions: values.financial_conditions || null,
          operating_conditions: values.operating_conditions || null,
          policy: values.policy || null,
          description: values.description || null,
          split_accounting: values.split_accounting,
        },
      });
    } else {
      const response = await createGlobalDeal({ payload: commonPayload });

      if (onCreate) onCreate({ dealId: response.id });

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const pokerRoom = watch('poker_room')?.value;

  const detail = data && !onCreate;

  const commonProps = { control, disabled, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  const numberInputProps = {
    format: { decimalScale: 6 },
    rules: { validate: { max: bigNumber() } },
  };

  const getPercentInputProps = (label: string) => ({
    format: { decimalScale: 6 },
    rules: { validate: { max: max(label, 100) } },
  });

  return {
    form,
    onSubmit,
    fields: {
      agent_id: (
        <PageAddon.Field label={t('common.agent')}>
          <Select.AsyncV2
            onLoad={(params) => loadGlobalAgents(params)}
            select={(item) => ({
              value: item.id,
              label: item.name,
            })}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="agent_id"
                rules={{ validate: { required } }}
                {...(data?.agent &&
                  getAgentRoute && {
                    extra: (
                      <LinkButton
                        size="extra-small"
                        variant="light"
                        onClick={() => navigate(getAgentRoute({ agentId: data.agent.id }))}
                      />
                    ),
                  })}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
      ),
      poker_room: (
        <PageAddon.Field label={t('common.poker_room')}>
          <Select.Quick
            name="poker_room"
            options={trainerPokerRoomOptions}
            rules={{ validate: { required } }}
            onChange={() => {
              setValue('club_id', null);
            }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      club_id: (
        <PageAddon.Field label={t('common.club')}>
          <Select.Async
            onLoad={async (params) => {
              const response = await loadGlobalClubs({
                ...params,
                poker_room_in: String(pokerRoom),
              });

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
                caption: item.code,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="club_id"
                rules={{ validate: { required } }}
                onChange={() => {
                  if (commonProps.onSubmit) commonProps.onSubmit();
                }}
                renderCaption={(option) => option?.caption}
                {...(data?.club &&
                  getClubRoute && {
                    extra: (
                      <LinkButton
                        size="extra-small"
                        variant="light"
                        onClick={() => navigate(getClubRoute({ clubId: data.club.id }))}
                      />
                    ),
                  })}
                {...commonProps}
                {...selectAsyncProps}
                {...{ ...commonProps, disabled: commonProps.disabled || !pokerRoom }}
              />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      area_id: (
        <PageAddon.Field label={t('common.area')}>
          <Input.Quick name="area_id" {...commonProps} />
        </PageAddon.Field>
      ),
      area2_id: (
        <PageAddon.Field label={t('common.area_2')}>
          <Input.Quick name="area2_id" {...commonProps} />
        </PageAddon.Field>
      ),
      holder_user_id: (
        <PageAddon.Field label={t('common.holder')}>
          <Select.Async
            onLoad={async (params) => {
              const response = await loadGlobalUsers(params);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.username,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick name="holder_user_id" {...commonProps} {...selectAsyncProps} />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      owner: (
        <PageAddon.Field label={t('common.owner')}>
          <Input.Quick name="owner" {...commonProps} />
        </PageAddon.Field>
      ),
      status: (
        <PageAddon.Field label={t('common.status')}>
          {data ? (
            <Select.Quick name="status" options={statusOptions} popupSize="auto" {...commonProps}>
              <DealBadge>
                <DealStatus data={data} />
              </DealBadge>
            </Select.Quick>
          ) : null}
        </PageAddon.Field>
      ),
      role: (
        <PageAddon.Field label={t('common.role')}>
          {data ? (
            <Select.Quick name="role" options={roleOptions} popupSize="auto" {...commonProps}>
              <DealBadge>
                <DealRole data={data} />
              </DealBadge>
            </Select.Quick>
          ) : null}
        </PageAddon.Field>
      ),
      credit: (
        <PageAddon.Field label={t('common.credit')}>
          {data ? (
            <Select.Quick name="credit" options={creditOptions} popupSize="auto" {...commonProps}>
              <DealBadge>
                <DealCredit data={data} />
              </DealBadge>
            </Select.Quick>
          ) : null}
        </PageAddon.Field>
      ),
      protection: (
        <PageAddon.Field label={t('common.protection')}>
          {data ? (
            <Select.Quick
              name="protection"
              options={protectionOptions}
              popupSize="auto"
              {...commonProps}
            >
              <DealBadge>
                <DealProtection data={data} />
              </DealBadge>
            </Select.Quick>
          ) : null}
        </PageAddon.Field>
      ),
      currency: (
        <PageAddon.Field label={t('common.currency')}>
          <Input.Quick name="currency" {...commonProps} />
        </PageAddon.Field>
      ),
      chip_rate: (
        <PageAddon.Field label={t('common.chip_rate')}>
          <Input.Quick name="chip_rate" {...numberInputProps} {...commonProps} />
        </PageAddon.Field>
      ),
      rake_back: (
        <PageAddon.Field label={t('common.rakeback')}>
          <Input.Quick
            name="rake_back"
            {...getPercentInputProps(t('common.rakeback'))}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      action: (
        <PageAddon.Field label={t('common.action')}>
          <Input.Quick
            name="action"
            {...getPercentInputProps(t('common.action'))}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      rev_share: (
        <PageAddon.Field label={t('common.revshare')}>
          <Input.Quick
            name="rev_share"
            {...getPercentInputProps(t('common.revshare'))}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      win_share: (
        <PageAddon.Field label={t('common.winshare')}>
          <Input.Quick
            name="win_share"
            {...getPercentInputProps(t('common.winshare'))}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      rebate: (
        <PageAddon.Field label={t('common.rebate')}>
          <Input.Quick
            name="rebate"
            {...getPercentInputProps(t('common.rebate'))}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      agent_fee: (
        <PageAddon.Field label={t('common.agent_fee')}>
          <Input.Quick
            name="agent_fee"
            {...getPercentInputProps(t('common.agent_fee'))}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      withdraw_fee: (
        <PageAddon.Field label={t('common.withdraw_fee')}>
          <Input.Quick
            name="withdraw_fee"
            {...getPercentInputProps(t('common.withdraw_fee'))}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      hand_cost: (
        <PageAddon.Field label={t('common.hand_cost')}>
          <Input.Quick name="hand_cost" {...numberInputProps} {...commonProps} />
        </PageAddon.Field>
      ),
      fixed_payment: (
        <PageAddon.Field label={t('common.fixed_payment')}>
          <Input.Quick name="fixed_payment" {...numberInputProps} {...commonProps} />
        </PageAddon.Field>
      ),
      income_cap: (
        <PageAddon.Field label={t('common.income_cap')}>
          <Input.Quick name="income_cap" {...numberInputProps} {...commonProps} />
        </PageAddon.Field>
      ),
      workspace_ids: (
        <PageAddon.Field label={t('common.workspaces')}>
          <Select.Async
            onLoad={async (params) => {
              const response = await loadGlobalWorkspaces(params);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Multi.Quick name="workspace_ids" {...commonProps} {...selectAsyncProps} />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      financial_conditions: (
        <PageAddon.Field label={t('common.financial_conditions')}>
          <Textarea.Quick name="financial_conditions" {...commonProps} />
        </PageAddon.Field>
      ),
      operating_conditions: (
        <PageAddon.Field label={t('common.operating_conditions')}>
          <Textarea.Quick name="operating_conditions" {...commonProps} />
        </PageAddon.Field>
      ),
      policy: (
        <PageAddon.Field label={t('common.policy')}>
          <Textarea.Quick name="policy" {...commonProps} />
        </PageAddon.Field>
      ),
      description: (
        <PageAddon.Field label={t('common.description')}>
          <Textarea.Quick name="description" {...commonProps} />
        </PageAddon.Field>
      ),
      split_accounting: (
        <PageAddon.Field label={t('common.split_accounting')}>
          <Checkbox.Quick name="split_accounting" {...commonProps} />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
