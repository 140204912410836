import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import workspaceSchema from '../schemas/workspaceSchema';

const loadWorkspaces = async (params?: URLParams) => {
  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}`, { params });

  return z.array(workspaceSchema).parse(response.data);
};

export default loadWorkspaces;
