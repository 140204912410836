import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const submitGlobalAccountForVerification = async (args: Args) => {
  const { accountId } = args;

  const response = await api.patch(`/api/account/${accountId}/verification-submit`);

  return emptyResponseSchema.parse(response.data);
};

export default submitGlobalAccountForVerification;
