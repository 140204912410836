import { ReactNode } from 'react';
import icon from '../../../../static/icons';
import Button from '../../../Button';
import styles from './styles.module.scss';

interface Props {
  label: string;
  children: ReactNode;
  onAdd?: () => void;
}

const PageAddonNewCollapseGroup = (props: Props) => {
  const { label, children, onAdd } = props;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.label}>{label}</div>
        {onAdd && <Button size="small" variant="light" icon={icon('add', 16)} onClick={onAdd} />}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PageAddonNewCollapseGroup;
