import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceDashboardIdentitiesCount } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import queryKeys from '../../../config/queryKeys';
import WorkspaceDashboardWidget from './WorkspaceDashboardWidget';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceDashboardWidgetIdentities = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const { data = [], isPending } = useQuery({
    queryKey: [queryKeys.workspaceDashboardIdentitiesCount({ workspaceId })],
    queryFn: () => loadWorkspaceDashboardIdentitiesCount({ workspaceId }),
    meta: { ignoredStatuses: [403] },
  });

  return (
    <WorkspaceDashboardWidget
      title={t('common.identities')}
      loading={isPending}
      data={data.map((item) => ({
        label: item.country,
        count: item.count,
        color: null,
      }))}
    />
  );
};

export default WorkspaceDashboardWidgetIdentities;
