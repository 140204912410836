import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import accountVerificationCodeSchema from '../schemas/account/accountVerificationCodeSchema';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const loadWorkspaceAccountVerificationCode = async (args: Args) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/verification-code`
  );

  return accountVerificationCodeSchema.parse(response.data);
};

export default loadWorkspaceAccountVerificationCode;
