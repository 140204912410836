import { useTranslation } from 'react-i18next';
import { bulkTransferFilteredWorkspaceAccounts, bulkTransferWorkspaceAccounts } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import useBulkActions from '../useBulkActions';
import styles from './styles.module.scss';
import WorkspaceAccountTransferDialogForm from './WorkspaceAccountTransferDialogForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountTransferDialog = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { ids, params, selectAllMode, handle } = useBulkActions();

  return (
    <Dialog
      className={styles.dialog}
      label={({ referenceProps }) => (
        <Tooltip label={t('common.transfer_accounts')}>
          <Button
            danger
            variant="transparent"
            icon={icon('switch', 16)}
            data-testid="transfer"
            {...referenceProps}
          />
        </Tooltip>
      )}
    >
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title>{t('common.transfer_accounts')}</Dialog.Title>
          </Dialog.Top>
          <WorkspaceAccountTransferDialogForm
            onSubmit={async (values) => {
              if (!values.maintenance_id) throw new Error();
              if (!values.stage_id) throw new Error();

              const payload = {
                maintenance_id: values.maintenance_id.value,
                stage_id: values.stage_id.value,
                account_ids: ids,
              };

              await handle(() => {
                const fn = selectAllMode
                  ? bulkTransferFilteredWorkspaceAccounts
                  : bulkTransferWorkspaceAccounts;

                return fn({ workspaceId, payload, params });
              });

              close();
            }}
          />
        </>
      )}
    </Dialog>
  );
};

export default WorkspaceAccountTransferDialog;
