import { useTranslation } from 'react-i18next';
import { AgentSchema } from '../../api/schemas/agentSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import useForm from './useForm';

interface Props {
  onClose: () => void;
  onCreate: (args: { agentId: AgentSchema['id'] }) => void;
}

const GlobalAgentForm = (props: Props) => {
  const { onClose, onCreate } = props;

  const { t } = useTranslation();

  const { fields, form, onSubmit } = useForm({ onCreate });

  return (
    <PageAddon onClose={onClose} title={t('common.create_agent')}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.name}
        {fields.type}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalAgentForm;
