import { FocusEvent, KeyboardEvent, MouseEvent, useRef, useState } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

type Elements = HTMLInputElement | HTMLTextAreaElement;

type Args<T extends FieldValues> = UseControllerProps<T> & {
  onSubmit?: () => void;
};

const useQuickField = <T extends FieldValues>(args: Args<T>) => {
  const { onSubmit, ...rest } = args;

  const controller = useController(rest);

  const {
    field,
    fieldState: { error },
  } = controller;

  const [focused, setFocused] = useState(false);

  const esc = useRef(false);
  const isDirty = useRef(false);
  const initialValue = useRef<string>(field.value);

  return {
    controller,
    focused,
    error: error ? error.message : undefined,
    listeners: {
      onFocus: () => setFocused(true),
      onClick: (event: MouseEvent<Elements>) => {
        if (!focused) {
          const { length } = event.currentTarget.value;

          event.currentTarget.setSelectionRange(length, length);
        }
      },
      onKeyDown: (event: KeyboardEvent<Elements>) => {
        if (event.key === 'Escape') {
          field.onChange(initialValue.current);

          esc.current = true;
          event.currentTarget.blur();
        }

        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          event.currentTarget.blur();
        }
      },
      onBlur: (event: FocusEvent<Elements>) => {
        const blur = () => {
          field.onBlur();
          setFocused(false);
        };

        if (esc.current) {
          blur();

          esc.current = false;
        } else {
          blur();

          if (event.currentTarget.value !== initialValue.current) {
            isDirty.current = true;
          }

          initialValue.current = event.currentTarget.value;
          field.onChange(initialValue.current);

          if (isDirty.current && onSubmit) onSubmit();
        }
      },
    },
  };
};

export default useQuickField;
