import { z } from 'zod';
import currencySchema from './currencySchema';
import dateTimeSchema from './dateTimeSchema';
import globalDealSchema from './deal/globalDealSchema';

export const TYPE = {
  UNKNOWN: 0,
  JACKPOT: 1,
  HAND_COMBO: 2,
  CLUB_CONDITIONS: 3,
  OTHER: 4,
} as const;

const globalBonusSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(TYPE),
  deal: globalDealSchema,
  amount: z.number(),
  currency: currencySchema,
  reconcile: z.boolean(),
  description: z.string().or(z.null()),
  date: dateTimeSchema,
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const globalBonusFilterValuesSchema = z.object({
  deal: z.array(
    z.object({
      id: globalBonusSchema.shape.deal.shape.id,
      name: globalBonusSchema.shape.deal.shape.code,
    })
  ),
});

export type GlobalBonusSchema = z.infer<typeof globalBonusSchema>;

export default globalBonusSchema;
