import { ChannelSchema } from '../../api/schemas/channelSchema';
import Badge from '../../components/Badge';
import { STATUS_COLORS, STATUS_LABELS } from './helpers';

interface Props {
  data: ChannelSchema;
}

const WorkspaceChannelStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  return <Badge color={STATUS_COLORS[status]}>{STATUS_LABELS[status]}</Badge>;
};

export default WorkspaceChannelStatus;
