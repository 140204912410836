import api from '../../services/api';
import agentSchema, { AgentSchema } from '../schemas/agentSchema';

type Schema = AgentSchema;

interface Payload {
  name: Schema['name'];
  type: Schema['type'];
  contact?: Schema['contact'] | null;
}

interface Args {
  payload: Payload;
}

const createGlobalAgent = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/agent', payload);

  return agentSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalAgent;
