import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SOCIAL_NETWORK_TYPE } from '../../api/schemas/identity';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { required } from '../../utils/form';
import { SOCIAL_NETWORKS_LABELS } from './helpers';
import { Fields, FieldProps as Props } from './types';
import WorkspaceIdentityFormControls from './WorkspaceIdentityFormControls';
import WorkspaceIdentityFormCreate from './WorkspaceIdentityFormCreate';

const options = [
  {
    value: SOCIAL_NETWORK_TYPE.FACEBOOK,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.FACEBOOK],
  },
  {
    value: SOCIAL_NETWORK_TYPE.TWITTER,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.TWITTER],
  },
  {
    value: SOCIAL_NETWORK_TYPE.TELEGRAM,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.TELEGRAM],
  },
  {
    value: SOCIAL_NETWORK_TYPE.INSTAGRAM,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.INSTAGRAM],
  },
  {
    value: SOCIAL_NETWORK_TYPE.WECHAT,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.WECHAT],
  },
];

const WorkspaceIdentityFormSocialNetworks = (props: Props) => {
  const { disabled, onSubmit } = props;

  const { t } = useTranslation();

  const { watch, control, setValue } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'social_networks' });

  return (
    <PageAddon.NewCollapse.Group
      label={t('common.social_networks')}
      {...(!disabled && {
        onAdd: () =>
          append(
            { type: null, link: '', login: '', password: '', open: true, append: true },
            { shouldFocus: false }
          ),
      })}
    >
      {fields.map((dynamicField, index) => {
        const value = watch(`social_networks.${index}`);

        const commonProps = { control, disabled, ...(!value.append && { onSubmit }) };

        return (
          <PageAddon.NewCollapse
            key={dynamicField.id}
            open={value.open}
            onToggle={(open) => setValue(`social_networks.${index}.open`, open)}
            label={
              (value.type && SOCIAL_NETWORKS_LABELS[value.type.value]) ||
              `${t('common.social_network')} ${index + 1}`
            }
            controls={<WorkspaceIdentityFormControls onRemove={() => remove(index)} {...props} />}
          >
            <PageAddon.NewCollapse.Field label={t('common.type')}>
              <Select.Quick
                name={`social_networks.${index}.type`}
                options={options}
                rules={{ validate: { required } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.url')}>
              <Input.Quick
                name={`social_networks.${index}.link`}
                rules={{ validate: { required } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.login')}>
              <Input.Quick name={`social_networks.${index}.login`} {...commonProps} />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.password')}>
              <Input.Quick
                name={`social_networks.${index}.password`}
                type="password"
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            {value.append && (
              <WorkspaceIdentityFormCreate
                name={`social_networks.${index}.append`}
                onSubmit={onSubmit}
              />
            )}
          </PageAddon.NewCollapse>
        );
      })}
    </PageAddon.NewCollapse.Group>
  );
};

export default WorkspaceIdentityFormSocialNetworks;
