import clsx from 'clsx';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import icon from '../../static/icons';
import Files from '../../types/Files';
import { handleDrop } from '../../utils/files';
import Error from '../Error';
import styles from './styles.module.scss';

interface Props extends Omit<DropzoneProps, 'children'> {
  onAttach: (files: Files) => void;
  error?: string;
}

const Attach = (props: Props) => {
  const { onAttach, error, ...rest } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => onAttach(handleDrop(acceptedFiles)),
    ...rest,
  });

  return (
    <div className={styles.container}>
      <div {...getRootProps()} className={clsx(styles.button, error && styles.error)}>
        <input {...getInputProps()} />
        {icon('upload', 20)}
      </div>
      <Error error={error} />
    </div>
  );
};

export default Attach;
