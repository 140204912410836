import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceAccountsStages, loadWorkspaces } from '../../../api';
import { WorkspaceStageSchema } from '../../../api/schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import Select, { Option } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import styles from './styles.module.scss';

interface Fields {
  maintenance_id: Option<WorkspaceSchema['id']>;
  stage_id: Option<WorkspaceStageSchema['id']>;
  account_ids: string[];
}

interface Props {
  onSubmit: (values: Fields) => void;
}

const WorkspaceAccountTransferDialogForm = (props: Props) => {
  const { onSubmit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: { maintenance_id: null, stage_id: null, account_ids: [] },
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const workspaceId = watch('maintenance_id')?.value;

  return (
    <Form form={form} onSubmit={onSubmit} className={styles.form}>
      <div>
        <Controller
          name="maintenance_id"
          control={control}
          render={({ field }) => (
            <Select.Async
              paginated={false}
              onLoad={async (args) => {
                const response = await loadWorkspaces({ ...args, is_owner: 'true' });

                const options = response.map((item) => ({ value: item.id, label: item.name }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  label={t('common.workspace')}
                  options={options}
                  value={field.value}
                  onChange={(value) => {
                    setValue('stage_id', null);

                    field.onChange(value);
                  }}
                  error={errors.maintenance_id?.message}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <Controller
          name="stage_id"
          control={control}
          render={({ field }) => (
            <Select.AsyncV2
              onLoad={(params) => {
                if (!workspaceId) throw new Error();

                return loadWorkspaceAccountsStages({ workspaceId }, params);
              }}
              select={(item) => ({
                value: item.id,
                label: item.name,
              })}
            >
              {({ options, ...rest }) => (
                <Select
                  label={t('common.stage')}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={!workspaceId}
                  error={errors.stage_id?.message}
                  {...rest}
                />
              )}
            </Select.AsyncV2>
          )}
        />
      </div>
      <Dialog.Footer>
        <SubmitButton>{t('common.transfer')}</SubmitButton>
      </Dialog.Footer>
    </Form>
  );
};

export default WorkspaceAccountTransferDialogForm;
