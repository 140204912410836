import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalBonusDetail from '../../features/global-bonus/GlobalBonusDetail';
import GlobalBonusForm from '../../features/global-bonus/GlobalBonusForm';
import GlobalBonusLoader from '../../features/global-bonus/GlobalBonusLoader';
import GlobalDealDetail from '../../features/global-deal/GlobalDealDetail';
import GlobalDealLoader from '../../features/global-deal/GlobalDealLoader';
import GlobalBonusesPage from '../../pages/GlobalBonusesPage';
import Route from './Route';

const page = <GlobalBonusesPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalBonusForm
        onClose={() => navigate(routes.globalBonuses)}
        onCreate={({ bonusId }) => navigate(routes.globalBonus({ bonusId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['bonusId']}>
    {({ bonusId, navigate }) => (
      <GlobalBonusLoader bonusId={bonusId}>
        {({ data }) => (
          <GlobalBonusDetail
            data={data}
            onClose={() => navigate(routes.globalBonuses)}
            getDealRoute={({ dealId }) => routes.globalBonusDeal({ bonusId, dealId })}
          />
        )}
      </GlobalBonusLoader>
    )}
  </Route>
);

const detailDeal = (
  <Route params={['bonusId', 'dealId']}>
    {({ bonusId, dealId, navigate }) => (
      <GlobalDealLoader dealId={dealId}>
        {({ data }) => (
          <GlobalDealDetail data={data} onClose={() => navigate(routes.globalBonus({ bonusId }))} />
        )}
      </GlobalDealLoader>
    )}
  </Route>
);

const globalBonusesPage: RouteObject = {
  path: routes.globalBonuses,
  element: page,
  children: [
    {
      path: routes.globalBonusesCreate,
      element: createForm,
    },
    {
      path: routes.globalBonus({ bonusId: ':bonusId' }),
      element: detail,
      children: [
        {
          path: routes.globalBonusDeal({
            bonusId: ':bonusId',
            dealId: ':dealId',
          }),
          element: detailDeal,
        },
      ],
    },
  ],
};

export default globalBonusesPage;
