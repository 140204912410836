import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import { GlobalBonusSchema } from '../../api/schemas/globalBonusSchema';
import { GlobalExpenseSchema } from '../../api/schemas/globalExpenseSchema';
import Tag from '../../components/Tag';
import { CURRENCY_LABELS } from '../../utils/currency';
import { convertCentsToDollars } from '../../utils/data';

export const renderWorkspaces = (data: GlobalDealSchema) => {
  const { workspaces } = data;

  if (workspaces.length) {
    return (
      <Tag.Group
        tooltip={(index) =>
          workspaces
            .slice(index)
            .map((item) => item.name)
            .join(', ')
        }
      >
        {workspaces.map((item) => (
          <Tag key={item.id}>{item.name}</Tag>
        ))}
      </Tag.Group>
    );
  }

  return '—';
};

export const getAmount = (values: GlobalBonusSchema | GlobalExpenseSchema) =>
  `${convertCentsToDollars(values.amount)} ${CURRENCY_LABELS[values.currency]}`;
