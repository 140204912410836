import DoubleRowText from '../../components/DoubleRowText';
import FadingText from '../../components/FadingText';
import { CommonDealSchema } from './types';

interface Props {
  data: CommonDealSchema;
}

const DealCode = (props: Props) => {
  const { data } = props;

  const { code, holder } = data;

  return <DoubleRowText title={code} subTitle={<FadingText text={holder?.username || '—'} />} />;
};

export default DealCode;
