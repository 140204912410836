import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPE } from '../../api/schemas/identity';
import DatePicker from '../../components/DatePicker';
import MediaFilesField from '../../components/MediaFilesField';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { MB } from '../../constants';
import { DAY } from '../../constants/DATE_FORMATS';
import { required, validDateFormat } from '../../utils/form';
import { DOCUMENTS_LABELS } from './helpers';
import { Fields, FieldProps as Props } from './types';
import WorkspaceIdentityFormControls from './WorkspaceIdentityFormControls';
import WorkspaceIdentityFormCreate from './WorkspaceIdentityFormCreate';

const options = [
  {
    value: DOCUMENT_TYPE.PASSPORT,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.PASSPORT],
  },
  {
    value: DOCUMENT_TYPE.NATIONAL_ID_CARD,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.NATIONAL_ID_CARD],
  },
  {
    value: DOCUMENT_TYPE.DRIVING_LICENSE,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.DRIVING_LICENSE],
  },
  {
    value: DOCUMENT_TYPE.UTILITY_BILL,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.UTILITY_BILL],
  },
  {
    value: DOCUMENT_TYPE.BANK_STATEMENT,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.BANK_STATEMENT],
  },
];

const WorkspaceIdentityFormDocuments = (props: Props) => {
  const { disabled, onSubmit } = props;

  const { t } = useTranslation();

  const {
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'documents' });

  return (
    <PageAddon.NewCollapse.Group
      label={t('common.documents')}
      {...(!disabled && {
        onAdd: () =>
          append(
            { issued: '', expire: '', type: null, files: [], open: true, append: true },
            { shouldFocus: false }
          ),
      })}
    >
      {fields.map((dynamicField, index) => {
        const value = watch(`documents.${index}`);

        const commonProps = { control, disabled, ...(!value.append && { onSubmit }) };

        return (
          <PageAddon.NewCollapse
            key={dynamicField.id}
            open={value.open}
            onToggle={(open) => setValue(`documents.${index}.open`, open)}
            label={
              (value.type && DOCUMENTS_LABELS[value.type.value]) ||
              `${t('common.document')} ${index + 1}`
            }
            controls={<WorkspaceIdentityFormControls onRemove={() => remove(index)} {...props} />}
          >
            <PageAddon.NewCollapse.Field label={t('common.type')}>
              <Select.Quick
                name={`documents.${index}.type`}
                options={options}
                rules={{ validate: { required } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.date_of_issue')}>
              <Controller
                control={control}
                name={`documents.${index}.issued`}
                rules={{ validate: { required, validDate: validDateFormat(DAY) } }}
                render={({ field }) => (
                  <DatePicker
                    quick
                    errorVariant="popover"
                    value={field.value}
                    disabled={disabled}
                    onChange={(val) => {
                      field.onChange(val);

                      if (commonProps.onSubmit) commonProps.onSubmit();
                    }}
                    error={errors.documents && errors.documents[index]?.issued?.message}
                  />
                )}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.date_of_expiry')}>
              <Controller
                control={control}
                name={`documents.${index}.expire`}
                rules={{ validate: { validDate: validDateFormat(DAY) } }}
                render={({ field }) => (
                  <DatePicker
                    quick
                    errorVariant="popover"
                    value={field.value}
                    disabled={disabled}
                    onChange={(val) => {
                      field.onChange(val);

                      if (commonProps.onSubmit) commonProps.onSubmit();
                    }}
                    error={errors.documents && errors.documents[index]?.expire?.message}
                  />
                )}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field>
              <Controller
                control={control}
                name={`documents.${index}.files`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <MediaFilesField
                    inline
                    value={field.value}
                    maxSize={3 * MB}
                    onChange={(val) => {
                      field.onChange(val);

                      if (commonProps.onSubmit) commonProps.onSubmit();
                    }}
                    disabled={disabled || isSubmitting}
                    {...(errors.documents && {
                      error: errors.documents[index]?.files?.message,
                    })}
                  >
                    {t('sentences.workspace_identities_files_restrictions')}
                  </MediaFilesField>
                )}
              />
            </PageAddon.NewCollapse.Field>
            {value.append && (
              <WorkspaceIdentityFormCreate name={`documents.${index}.append`} onSubmit={onSubmit} />
            )}
          </PageAddon.NewCollapse>
        );
      })}
    </PageAddon.NewCollapse.Group>
  );
};

export default WorkspaceIdentityFormDocuments;
