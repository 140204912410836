import { persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';
import { TimeZoneSchema } from '../api/schemas/timeZoneSchema';
import LOCALSTORAGE from '../constants/LOCALSTORAGE';

interface State {
  timeZone: TimeZoneSchema;
  setTimeZone: (payload: TimeZoneSchema) => void;
}

const useTimeZoneStore = create(
  persist<State>(
    (set) => ({
      timeZone: 'local',
      setTimeZone: (payload) => set({ timeZone: payload }),
    }),
    { name: LOCALSTORAGE.TIME_ZONE }
  )
);

export { useTimeZoneStore };
