import { useTranslation } from 'react-i18next';
import icon from '../../../../static/icons';
import Button from '../../../Button';
import Select from '../../../Select';
import Tooltip from '../../../Tooltip';

interface Props {
  disabled: boolean;
}

const PageAddonListAdd = (props: Props) => {
  const { disabled } = props;

  const { t } = useTranslation();

  return (
    <Select.Trigger>
      {({ ref, referenceProps }) => (
        <Tooltip ref={ref} label={t('common.add')}>
          <Button
            size="small"
            variant="transparent"
            disabled={disabled}
            icon={icon('add', 16)}
            {...referenceProps}
          />
        </Tooltip>
      )}
    </Select.Trigger>
  );
};

export default PageAddonListAdd;
