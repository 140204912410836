import { useQuery } from '@tanstack/react-query';
import { loadGlobalAccountVerificationCode } from '../../api/global-account-verification';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import queryKeys from '../../config/queryKeys';
import AccountVerificationCodeDialog from '../account/AccountVerificationCodeDialog';

interface Props {
  accountId: GlobalAccountSchema['id'];
  onClose?: () => void;
}

const GlobalAccountVerificationCodeDialog = (props: Props) => {
  const { accountId, onClose } = props;

  const { data, isPending } = useQuery({
    queryKey: [queryKeys.globalAccountVerificationCode({ accountId })],
    queryFn: () => loadGlobalAccountVerificationCode({ accountId }),
  });

  return <AccountVerificationCodeDialog data={data} loading={isPending} onClose={onClose} />;
};

export default GlobalAccountVerificationCodeDialog;
