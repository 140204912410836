import { AgentSchema } from '../../api/schemas/agentSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: AgentSchema;
}

const GlobalAgentDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown
      title={title}
      buttonSize="small"
      disabled={disabled}
      options={[options.notes, options.copy, options.divider, options.delete]}
    />
  );
};

export default GlobalAgentDropdown;
