import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { AgentSchema } from '../../api/schemas/agentSchema';
import Notes from '../../components/Notes';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import GlobalAgentDetailDropdown from './GlobalAgentDetailDropdown';
import useForm from './useForm';

interface Props {
  data: AgentSchema;
  onClose: () => void;
  getNotesRoute: () => void;
  getAgentRoute?: (args: { relatedAgentId: AgentSchema['id'] }) => string;
}

const GlobalAgentDetail = (props: Props) => {
  const { data, onClose, getNotesRoute, getAgentRoute } = props;

  const { t } = useTranslation();

  const { id, name, notes_count, contact, is_deleted, created_on, modified_on } = data;

  const { fields } = useForm({ data, getAgentRoute });

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={name} icons={titleIcons} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          {fields.type}
          {fields.holder}
          {fields.payment_system}
          {fields.related_agent}
          <PageAddon.Field label={t('common.modified')}>
            <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
          </PageAddon.Field>
          <PageAddon.Field label={t('common.created')}>
            <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
          </PageAddon.Field>
          <PageAddon.Collapse label={t('common.contacts')}>
            <PageAddon.Collapse.Field label={false}>{contact || '—'}</PageAddon.Collapse.Field>
          </PageAddon.Collapse>
          {fields.contacts}
        </PageAddon.Fields>
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Notes.Button count={notes_count} onClick={getNotesRoute} />
              <GlobalAgentDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalAgentDetail;
