import { useTranslation } from 'react-i18next';
import { AgentSchema } from '../../api/schemas/agentSchema';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import PageAddon from '../../components/PageAddon';
import { formatAbsoluteDate } from '../../utils/date';
import { CommonDealSchema } from './types';
import useForm from './useForm';

interface Props {
  data: CommonDealSchema;
  disabled: boolean;
  workspaces?: GlobalDealSchema['workspaces'];
  getClubRoute?: (args: { clubId: GlobalClubSchema['id'] }) => string;
  getAgentRoute?: (args: { agentId: AgentSchema['id'] }) => string;
}

const DealDetailGeneralTab = (props: Props) => {
  const { data, disabled, workspaces, getAgentRoute, getClubRoute } = props;

  const { id, modified_on, created_on } = data;

  const { t } = useTranslation();

  const { fields } = useForm({ data, disabled, workspaces, getAgentRoute, getClubRoute });

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      {fields.agent_id}
      {fields.poker_room}
      {fields.club_id}
      {fields.area_id}
      {fields.area2_id}
      {fields.holder_user_id}
      {fields.owner}
      {fields.status}
      {fields.role}
      {fields.credit}
      {fields.protection}
      {fields.currency}
      {fields.chip_rate}
      {fields.rake_back}
      {fields.action}
      {fields.rev_share}
      {fields.win_share}
      {fields.rebate}
      {fields.agent_fee}
      {fields.withdraw_fee}
      {fields.hand_cost}
      {fields.fixed_payment}
      {fields.income_cap}
      {workspaces && fields.workspace_ids}
      {fields.financial_conditions}
      {fields.operating_conditions}
      {fields.policy}
      {fields.description}
      {fields.split_accounting}
      <PageAddon.Field label={t('common.modified')}>
        <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default DealDetailGeneralTab;
