import { useTranslation } from 'react-i18next';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import useForm from '../deal/useForm';

interface Props {
  onClose: () => void;
  onCreate: (args: { dealId: GlobalDealSchema['id'] }) => void;
}

const GlobalDealForm = (props: Props) => {
  const { onClose, onCreate } = props;

  const { t } = useTranslation();

  const { form, fields, onSubmit } = useForm({ onCreate });

  return (
    <PageAddon title={t('common.create_deal')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.agent_id}
        {fields.poker_room}
        {fields.club_id}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalDealForm;
