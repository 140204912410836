import DoubleRowText from '../../components/DoubleRowText';
import FadingText from '../../components/FadingText';
import { CommonDealSchema } from './types';

interface Props {
  data: CommonDealSchema;
}

const DealHolder = (props: Props) => {
  const { data } = props;

  const { holder, owner } = data;

  if (!holder && !owner) return '—';

  return (
    <DoubleRowText
      title={<FadingText text={holder?.username || '—'} />}
      subTitle={<FadingText text={owner || '—'} />}
    />
  );
};

export default DealHolder;
