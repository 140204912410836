import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import usePrevious from '../../../hooks/usePrevious';
import Input, { Props as InputProps } from '../../Input';
import Range, { Props as RangeProps } from '../../Range';
import FilterCollapse from '../FilterCollapse';
import { useFilterContext } from '../FilterContext';
import styles from './styles.module.scss';

type Formatter = (value: number) => number;

interface Props<Fields> {
  label: string;
  min: RangeProps['min'];
  max: RangeProps['max'];
  step: RangeProps['step'];
  valueMin: string;
  valueMax: string;
  fieldMin: keyof Fields;
  fieldMax: keyof Fields;
  range?: boolean;
  decimalScale?: Exclude<InputProps['format'], undefined>['decimalScale'];
  formatter?: Formatter;
  valueFormatter?: Formatter;
}

const FilterRangeInputComponent = <Fields,>(props: Props<Fields>) => {
  const {
    step,
    label,
    valueMin,
    valueMax,
    fieldMin,
    fieldMax,
    range = true,
    decimalScale = 0,
    formatter,
    valueFormatter,
  } = props;

  let { min, max } = props;

  min = formatter ? formatter(min) : min;
  max = formatter ? formatter(max) : max;

  const { isResetting, setValue, clearValue } = useFilterContext<Fields>();

  const getValue = (value: string, constraint: number) => {
    if (!value) return constraint;

    if (formatter) return formatter(Number(value));

    return Number(value);
  };

  const values = [getValue(valueMin, min), getValue(valueMax, max)];

  const handleChange = useCallback(
    (value: number, field: keyof Fields, constraint: number) => {
      if (value !== constraint) {
        setValue(field)(valueFormatter ? valueFormatter(value) : value);
      }

      if (value === constraint) {
        clearValue(field)();
      }
    },
    [clearValue, setValue, valueFormatter]
  );

  const getFrom = () => {
    if (values[0] < min) return min;
    if (values[0] > max) return max;

    return values[0];
  };

  const getTo = () => (values[1] > max ? max : values[1]);

  const [from, setFrom] = useState(String(getFrom()));
  const [to, setTo] = useState(String(getTo()));

  const prevFrom = usePrevious(from);
  const prevTo = usePrevious(to);

  const onFromChange = useCallback(
    (value: number) => handleChange(value, fieldMin, min),
    [fieldMin, handleChange, min]
  );

  const onToChange = useCallback(
    (value: number) => handleChange(value, fieldMax, max),
    [fieldMax, handleChange, max]
  );

  const onClear = useCallback(() => {
    setFrom(String(min));
    setTo(String(max));

    clearValue([fieldMin, fieldMax])();
  }, [min, max, fieldMin, fieldMax, clearValue]);

  useEffect(() => {
    if (
      from !== prevFrom &&
      !from.endsWith('.') &&
      Number(from) >= min &&
      Number(from) <= max &&
      Number(from) < Number(to) &&
      !Number.isNaN(Number(from))
    ) {
      onFromChange(Number(from));
    }
  }, [from, to, prevFrom, min, max, onFromChange]);

  useEffect(() => {
    if (
      onToChange &&
      to !== prevTo &&
      !to.endsWith('.') &&
      Number(to) >= min &&
      Number(to) <= max &&
      Number(from) < Number(to) &&
      !Number.isNaN(Number(to))
    ) {
      onToChange(Number(to));
    }
  }, [from, to, prevTo, min, max, onToChange, valueFormatter]);

  useEffect(() => {
    if (isResetting) onClear();
  }, [isResetting, onClear]);

  const renderRange = () => {
    if (range) {
      return (
        <Range
          min={min}
          max={max}
          step={step}
          values={[Number(from), Number(to)]}
          onFromChange={(value) => setFrom(String(value))}
          onToChange={(value) => setTo(String(value))}
        />
      );
    }

    return null;
  };

  if (min === max) return null;

  return (
    <FilterCollapse label={label} {...((valueMin || valueMax) && { onClear })}>
      <div className={styles.container}>
        <div className={clsx(styles.inputs, range && styles.range)}>
          <Input
            size={range ? 'small' : 'medium'}
            value={from}
            format={{
              decimalScale,
              allowNegative: true,
              onValueChange: setFrom,
            }}
          />
          {!range && <span>—</span>}
          <Input
            size={range ? 'small' : 'medium'}
            value={to}
            format={{
              decimalScale,
              allowNegative: true,
              onValueChange: (value) => {
                if (Number(value) >= max) {
                  setTo(String(max));
                } else {
                  setTo(value);
                }
              },
            }}
          />
        </div>
        {renderRange()}
      </div>
    </FilterCollapse>
  );
};

const FilterRangeInput = <Fields,>(props: Props<Fields>) => {
  const { min, max } = props;

  return <FilterRangeInputComponent key={`${min}${max}`} {...props} />;
};

export default FilterRangeInput;
