import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PHOTO_TYPE } from '../../api/schemas/identity';
import MediaFilesField from '../../components/MediaFilesField';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { MB } from '../../constants';
import CONTENT_TYPES from '../../constants/CONTENT_TYPES';
import EXTENSIONS from '../../constants/EXTENSIONS';
import { required } from '../../utils/form';
import { PHOTOS_LABELS } from './helpers';
import { Fields, FieldProps as Props } from './types';
import WorkspaceIdentityFormControls from './WorkspaceIdentityFormControls';
import WorkspaceIdentityFormCreate from './WorkspaceIdentityFormCreate';

const options = [
  {
    value: PHOTO_TYPE.SELFIE,
    label: PHOTOS_LABELS[PHOTO_TYPE.SELFIE],
  },
  {
    value: PHOTO_TYPE.SELFIE_WITH_IDENTITY_DOCUMENT,
    label: PHOTOS_LABELS[PHOTO_TYPE.SELFIE_WITH_IDENTITY_DOCUMENT],
  },
  {
    value: PHOTO_TYPE.SELFIE_WITH_BLANK_PAPER_SHEET,
    label: PHOTOS_LABELS[PHOTO_TYPE.SELFIE_WITH_BLANK_PAPER_SHEET],
  },
];

const WorkspaceIdentityFormPhotos = (props: Props) => {
  const { disabled, onSubmit } = props;

  const { t } = useTranslation();

  const {
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'photos' });

  return (
    <PageAddon.NewCollapse.Group
      label={t('common.photos')}
      {...(!disabled && {
        onAdd: () =>
          append({ files: [], type: null, open: true, append: true }, { shouldFocus: false }),
      })}
    >
      {fields.map((dynamicField, index) => {
        const value = watch(`photos.${index}`);

        const commonProps = { control, disabled, ...(!value.append && { onSubmit }) };

        return (
          <PageAddon.NewCollapse
            key={dynamicField.id}
            open={value.open}
            onToggle={(open) => setValue(`photos.${index}.open`, open)}
            label={
              (value.type && PHOTOS_LABELS[value.type.value]) || `${t('common.photo')} ${index + 1}`
            }
            controls={<WorkspaceIdentityFormControls onRemove={() => remove(index)} {...props} />}
          >
            <PageAddon.NewCollapse.Field label={t('common.type')}>
              <Select.Quick
                name={`photos.${index}.type`}
                options={options}
                rules={{ validate: { required } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field>
              <Controller
                control={control}
                name={`photos.${index}.files`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <MediaFilesField
                    inline
                    value={field.value}
                    maxSize={3 * MB}
                    onChange={(val) => {
                      field.onChange(val);

                      if (commonProps.onSubmit) commonProps.onSubmit();
                    }}
                    accept={{
                      [CONTENT_TYPES['image/*']]: [EXTENSIONS.JPG, EXTENSIONS.JPEG, EXTENSIONS.PNG],
                    }}
                    disabled={disabled || isSubmitting}
                    {...(errors.photos && {
                      error: errors.photos[index]?.files?.message,
                    })}
                  >
                    {t('sentences.workspace_identities_files_restrictions')}
                  </MediaFilesField>
                )}
              />
            </PageAddon.NewCollapse.Field>
            {value.append && (
              <WorkspaceIdentityFormCreate name={`photos.${index}.append`} onSubmit={onSubmit} />
            )}
          </PageAddon.NewCollapse>
        );
      })}
    </PageAddon.NewCollapse.Group>
  );
};

export default WorkspaceIdentityFormPhotos;
