import clsx from 'clsx';
import { ReactNode, Ref } from 'react';
import styles from './styles.module.scss';

export type Color = 'green' | 'orange' | 'red' | 'blue' | 'purple' | 'grey';

const COLORS: Record<Color, string> = {
  green: styles.green,
  orange: styles.orange,
  red: styles.red,
  blue: styles.blue,
  purple: styles.purple,
  grey: styles.grey,
};

interface Props {
  color: Color;
  children: ReactNode;
  ref?: Ref<HTMLDivElement>;
}

const Badge = (props: Props) => {
  const { color, children, ref, ...rest } = props;

  return (
    <div ref={ref} data-badge className={clsx(styles.container, COLORS[color])} {...rest}>
      {children}
    </div>
  );
};

export default Badge;
