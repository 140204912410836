import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import { accountStrategySchema } from '../schemas/accountStrategySchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountStrategy = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}/strategy-profile`);

  return accountStrategySchema.parse(response.data);
};

export default loadGlobalAccountStrategy;
