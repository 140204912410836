import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalDealDetail from '../../features/global-deal/GlobalDealDetail';
import GlobalDealLoader from '../../features/global-deal/GlobalDealLoader';
import GlobalExpenseDetail from '../../features/global-expense/GlobalExpenseDetail';
import GlobalExpenseForm from '../../features/global-expense/GlobalExpenseForm';
import GlobalExpenseLoader from '../../features/global-expense/GlobalExpenseLoader';
import GlobalExpensesPage from '../../pages/GlobalExpensesPage';
import Route from './Route';

const page = <GlobalExpensesPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalExpenseForm
        onClose={() => navigate(routes.globalExpenses)}
        onCreate={({ expenseId }) => navigate(routes.globalExpense({ expenseId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['expenseId']}>
    {({ expenseId, navigate }) => (
      <GlobalExpenseLoader expenseId={expenseId}>
        {({ data }) => (
          <GlobalExpenseDetail
            data={data}
            onClose={() => navigate(routes.globalExpenses)}
            getDealRoute={({ dealId }) => routes.globalExpenseDeal({ expenseId, dealId })}
          />
        )}
      </GlobalExpenseLoader>
    )}
  </Route>
);

const detailDeal = (
  <Route params={['expenseId', 'dealId']}>
    {({ expenseId, dealId, navigate }) => (
      <GlobalDealLoader dealId={dealId}>
        {({ data }) => (
          <GlobalDealDetail
            data={data}
            onClose={() => navigate(routes.globalExpense({ expenseId }))}
          />
        )}
      </GlobalDealLoader>
    )}
  </Route>
);

const globalExpensesPage: RouteObject = {
  path: routes.globalExpenses,
  element: page,
  children: [
    {
      path: routes.globalExpensesCreate,
      element: createForm,
    },
    {
      path: routes.globalExpense({ expenseId: ':expenseId' }),
      element: detail,
      children: [
        {
          path: routes.globalExpenseDeal({
            expenseId: ':expenseId',
            dealId: ':dealId',
          }),
          element: detailDeal,
        },
      ],
    },
  ],
};

export default globalExpensesPage;
