import { Suspense, lazy } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createGlobalSuperLeague, updateGlobalSuperLeague } from '../../../api';
import { GlobalSuperLeagueSchema } from '../../../api/schemas/globalSuperLeagueSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select, { Option } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import { maxLength, minLength, required } from '../../../utils/form';
import notify from '../../../utils/notify';
import { TRAINER_POKER_ROOM_LABELS, trainerPokerRoomOptions } from '../../../utils/trainer';
import styles from './styles.module.scss';

const CodeEditor = lazy(() => import('../../../components/CodeEditor'));

interface Fields {
  name: string;
  code: string;
  poker_room: Option<GlobalSuperLeagueSchema['poker_room']>;
  extra: string;
}

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { superLeagueId: GlobalSuperLeagueSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: GlobalSuperLeagueSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalSuperLeagueForm = (props: Props) => {
  const { data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      name: data?.name || '',
      code: data?.code || '',
      poker_room: data
        ? { value: data.poker_room, label: TRAINER_POKER_ROOM_LABELS[data.poker_room] }
        : null,
      extra: data?.extra ? JSON.stringify(data.extra, null, 1) : '',
    },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.poker_room) throw new Error();

    const payload = {
      name: values.name,
      code: values.code,
      poker_room: values.poker_room.value,
      extra: values.extra ? JSON.parse(values.extra) : null,
    };

    if (data) {
      await updateGlobalSuperLeague({
        superLeagueId: data.id,
        payload: { name: payload.name, extra: payload.extra },
      });

      onEdit();
    } else {
      const response = await createGlobalSuperLeague({ payload });

      onCreate({ superLeagueId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      title={data ? `${t('common.edit')} ${data.code}` : t('common.create_super_league')}
      onClose={onClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        {!data && (
          <Input
            label={`${t('common.code')}*`}
            error={errors.code?.message}
            {...register('code', {
              validate: {
                required,
                minLength: minLength(),
                maxLength: maxLength(),
              },
            })}
          />
        )}
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', {
            validate: {
              required,
              maxLength: maxLength(),
            },
          })}
        />
        {!data && (
          <Controller
            name="poker_room"
            control={control}
            rules={{ validate: { required } }}
            render={({ field }) => (
              <Select
                clearable={false}
                label={`${t('common.poker_room')}*`}
                options={trainerPokerRoomOptions}
                value={field.value}
                onChange={field.onChange}
                error={errors.poker_room?.message}
              />
            )}
          />
        )}
        <PageAddon.Heading className={styles.heading}>{t('common.extra_fields')}</PageAddon.Heading>
        <Suspense fallback={null}>
          <Controller
            name="extra"
            control={control}
            render={({ field }) => (
              <CodeEditor
                value={field.value}
                onChange={field.onChange}
                error={errors.extra?.message}
              />
            )}
          />
        </Suspense>
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalSuperLeagueForm;
