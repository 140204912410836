import clsx from 'clsx';
import { ReactNode } from 'react';
import Select from '../../../components/Select';
import { useSelectContext } from '../../../components/Select/SelectContext';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const DealBadge = (props: Props) => {
  const { children } = props;

  const { open } = useSelectContext();

  return (
    <Select.Trigger>
      <div className={clsx(styles.container, open && styles.open)}>{children}</div>
    </Select.Trigger>
  );
};

export default DealBadge;
