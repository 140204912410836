import { AgentSchema, TYPE, CONTACT_TYPE, PAYMENT_SYSTEM } from '../../api/schemas/agentSchema';
import Tag from '../../components/Tag';
import { t } from '../../i18n';
import { getSelectOptions } from '../../utils/data';

export const TYPE_LABELS: Record<AgentSchema['type'], string> = {
  [TYPE.UNKNOWN]: '—',
  [TYPE.CLUB_HOST]: t('common.club_host'),
  [TYPE.UNION_HEAD]: t('common.union_head'),
  [TYPE.AFFILIATE]: t('common.affiliate'),
  [TYPE.AGENT]: t('common.agent'),
};

export const PAYMENT_SYSTEM_LABELS: Record<AgentSchema['payment_system'][number], string> = {
  [PAYMENT_SYSTEM.UNKNOWN]: t('common.unknown'),
  [PAYMENT_SYSTEM.USDT]: t('common.usdt'),
  [PAYMENT_SYSTEM.BTC]: t('common.btc'),
  [PAYMENT_SYSTEM.BANK_CARD_RU]: t('common.bank_card_ru'),
  [PAYMENT_SYSTEM.VENMO]: t('common.venmo'),
  [PAYMENT_SYSTEM.ZELLE]: t('common.zelle'),
  [PAYMENT_SYSTEM.CASH_APP]: t('common.cash_app'),
  [PAYMENT_SYSTEM.PAYPAL]: t('common.paypal'),
  [PAYMENT_SYSTEM.APPLE_PAY]: t('common.apple_pay'),
};

export const CONTACT_TYPE_LABELS: Record<AgentSchema['contacts'][number]['type'], string> = {
  [CONTACT_TYPE.UNKNOWN]: '—',
  [CONTACT_TYPE.TELEGRAM]: t('common.telegram'),
  [CONTACT_TYPE.WHATSAPP]: t('common.whatsapp'),
  [CONTACT_TYPE.EMAIL]: t('common.email'),
  [CONTACT_TYPE.PHONE]: t('common.phone'),
  [CONTACT_TYPE.CONTACT_PERSON]: t('common.contact_person'),
};

export const typesOptions = getSelectOptions(TYPE, TYPE_LABELS);
export const paymentSystemOptions = getSelectOptions(PAYMENT_SYSTEM, PAYMENT_SYSTEM_LABELS);
export const contactTypesOptions = getSelectOptions(CONTACT_TYPE, CONTACT_TYPE_LABELS);

export const renderPaymentSystems = (data: AgentSchema) => {
  const { payment_system } = data;

  if (payment_system.length) {
    return (
      <Tag.Group
        tooltip={(index) =>
          payment_system
            .slice(index)
            .map((item) => PAYMENT_SYSTEM_LABELS[item])
            .join(', ')
        }
      >
        {payment_system.map((item) => (
          <Tag key={item}>{PAYMENT_SYSTEM_LABELS[item]}</Tag>
        ))}
      </Tag.Group>
    );
  }

  return '—';
};
