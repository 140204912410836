import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  INITIATOR,
  OBJECT,
  STATUS,
  TYPE,
} from '../../../api/schemas/account/accountTransactionSchema';
import Attach from '../../../components/Attach';
import Checkbox from '../../../components/Checkbox';
import Collapse from '../../../components/Collapse';
import Empty from '../../../components/Empty';
import MediaViewer from '../../../components/MediaViewer';
import PageAddon from '../../../components/PageAddon';
import PrettyNumber from '../../../components/PrettyNumber';
import SearchInput from '../../../components/SearchInput';
import Spinner from '../../../components/Spinner';
import Tooltip from '../../../components/Tooltip';
import { MB } from '../../../constants';
import CONTENT_TYPES from '../../../constants/CONTENT_TYPES';
import EXTENSIONS from '../../../constants/EXTENSIONS';
import usePaginatedData from '../../../hooks/usePaginatedData';
import icon from '../../../static/icons';
import { convertCentsToDollars } from '../../../utils/data';
import { formatAbsoluteDate } from '../../../utils/date';
import { getFileURL } from '../../../utils/files';
import { CommonAccountTransactionSchema } from '../types';
import AccountTransactionsAuthor from './AccountTransactionsAuthor';
import AccountTransactionsForm from './AccountTransactionsForm';
import { OBJECT_LABELS, TYPE_LABELS, STATUS_LABELS, STATUS_ICONS } from './helpers';
import styles from './styles.module.scss';
import { OnAttach, OnFinalize, OnRemove } from './types';
import useAccountTransactionsFilter from './useAccountTransactionsFilter';

type Value = string | null;

interface Props {
  data: CommonAccountTransactionSchema[];
  filter: ReturnType<typeof useAccountTransactionsFilter>;
  loading: boolean;
  loadingMore: boolean;
  checkIsInView: ReturnType<typeof usePaginatedData>['checkIsInView'];
  onMediaAttach?: OnAttach;
  onMediaRemove?: OnRemove;
  onFinalize?: OnFinalize;
  showMedia?: boolean;
}

const AccountTransactions = (props: Props) => {
  const {
    data,
    filter,
    loading,
    loadingMore,
    checkIsInView,
    onMediaAttach,
    onMediaRemove,
    onFinalize,
    showMedia = true,
  } = props;

  const { t } = useTranslation();

  const { form } = filter;

  const getValue = (oldValue: Value, newValue: Value) => {
    if (oldValue !== newValue) {
      return `${oldValue || '—'} → ${newValue || '—'}`;
    }

    if (!newValue) return '—';

    return newValue;
  };

  const getContent = () => {
    if (loading) {
      return (
        <div className={styles.state}>
          <Spinner />
        </div>
      );
    }

    if (!data.length) {
      return (
        <div className={styles.state}>
          <Empty />
        </div>
      );
    }

    return data.map((item, index) => {
      const isDeposit = item.delta > 0;

      return (
        <Collapse
          key={item.id}
          label={formatAbsoluteDate(item.executed_on)}
          {...(item.trx_status !== STATUS.UNKNOWN && {
            labelAfter: (
              <Collapse.SecondaryLabel>
                <Tooltip label={STATUS_LABELS[item.trx_status]}>
                  <div>{STATUS_ICONS[item.trx_status]}</div>
                </Tooltip>
              </Collapse.SecondaryLabel>
            ),
          })}
          labelBefore={<Collapse.Badge color={isDeposit ? 'green' : 'red'} />}
          caption={
            <Collapse.Caption>
              {`${OBJECT_LABELS[item.trx_object]} • ${TYPE_LABELS[item.trx_type]}`}
              {item.files.length > 0 && (
                <>
                  &nbsp;•&nbsp;<div className={styles.icon}>{icon('paperclip', 12)}</div>
                </>
              )}
            </Collapse.Caption>
          }
          extra={
            <div className={styles.extra}>
              <span className={styles.delta}>
                <PrettyNumber prefix={isDeposit ? '+' : ''}>
                  {convertCentsToDollars(item.delta)}
                </PrettyNumber>
              </span>
              <span className={styles.currency}>{item.new_balance.currency}</span>
            </div>
          }
          className={styles.collapse}
          {...checkIsInView(index)}
        >
          <Collapse.InfoField label={t('common.author')}>
            <AccountTransactionsAuthor data={item} />
          </Collapse.InfoField>
          {item.initiator === INITIATOR.TRAINER && (
            <Collapse.InfoField label={t('common.seq')}>{item.trainer_seq}</Collapse.InfoField>
          )}
          <Collapse.InfoField label={t('common.amount')}>{`${convertCentsToDollars(
            item.old_balance.amount
          )} → ${convertCentsToDollars(item.new_balance.amount)}`}</Collapse.InfoField>
          <Collapse.InfoField label={t('common.currency')}>
            {getValue(item.old_balance.currency, item.new_balance.currency)}
          </Collapse.InfoField>
          {(item.trx_object === OBJECT.LEAGUE ||
            item.trx_object === OBJECT.CLUB ||
            item.trx_object === OBJECT.TABLE) && (
            <Collapse.InfoField
              label={t('common.league')}
              {...(item.new_balance.league_id && { caption: item.new_balance.league_id })}
            >
              {getValue(item.old_balance.league_name, item.new_balance.league_name)}
            </Collapse.InfoField>
          )}
          {(item.trx_object === OBJECT.CLUB || item.trx_object === OBJECT.TABLE) && (
            <Collapse.InfoField
              label={t('common.club')}
              {...(item.new_balance.club_id && { caption: item.new_balance.club_id })}
            >
              {getValue(item.old_balance.club_name, item.new_balance.club_name)}
            </Collapse.InfoField>
          )}
          {item.trx_object === OBJECT.TABLE && (
            <Collapse.InfoField label={t('common.table')}>
              {getValue(item.old_balance.table_id, item.new_balance.table_id)}
            </Collapse.InfoField>
          )}
          {item.authorizer && (
            <Collapse.InfoField label={t('common.authorizer')}>
              {item.authorizer.login}
            </Collapse.InfoField>
          )}
          {item.finalized_on && (
            <Collapse.InfoField label={t('common.finalized')}>
              {formatAbsoluteDate(item.finalized_on)}
            </Collapse.InfoField>
          )}
          {item.corrections && (
            <Collapse.InfoField label={t('common.corrections')}>
              {`${convertCentsToDollars(
                item.corrections.old_delta
              )} → ${convertCentsToDollars(item.corrections.new_delta)}`}
            </Collapse.InfoField>
          )}
          {item.user_message && (
            <Collapse.InfoField label={t('common.description')}>
              {item.user_message}
            </Collapse.InfoField>
          )}
          {item.corrections?.comment && (
            <Collapse.InfoField label={t('common.comment')}>
              {item.corrections.comment}
            </Collapse.InfoField>
          )}
          {showMedia && (
            <MediaViewer
              files={item.files.map((el) => ({
                name: el.original_name,
                src: getFileURL(el, 'transactions'),
                ...(onMediaRemove && {
                  onRemove: () =>
                    onMediaRemove({
                      transactionId: item.id,
                      file_ids: item.files
                        .filter((file) => file.id !== el.id)
                        .map((file) => file.id),
                    }),
                }),
              }))}
            >
              {onMediaAttach && (
                <Attach
                  maxSize={3 * MB}
                  accept={{
                    [CONTENT_TYPES['image/*']]: [EXTENSIONS.JPG, EXTENSIONS.JPEG, EXTENSIONS.PNG],
                  }}
                  onAttach={(files) =>
                    onMediaAttach({
                      transactionId: item.id,
                      files,
                      file_ids: item.files.map((el) => el.id),
                    })
                  }
                />
              )}
            </MediaViewer>
          )}
          {onFinalize && item.trx_status === STATUS.PENDING && (
            <AccountTransactionsForm data={item} onFinalize={onFinalize} />
          )}
        </Collapse>
      );
    });
  };

  const { watch, setValue } = form;

  const values = watch();

  const { trx_type_in } = values;

  const search = (
    <SearchInput
      size="small"
      value={values.search}
      onChange={(value) => setValue('search', value, { shouldDirty: true })}
    />
  );

  return (
    <FormProvider {...form}>
      <div className={styles.container}>
        <div className={styles.filter}>
          {search}
          <PageAddon.Filter>
            {search}
            <div className={styles.type}>
              {Object.values(TYPE)
                .filter((item) => item !== TYPE.UNKNOWN)
                .map((item) => {
                  const selected = trx_type_in.includes(item);

                  return (
                    <Checkbox
                      key={item}
                      checked={selected}
                      onChange={() => {
                        setValue(
                          'trx_type_in',
                          selected
                            ? trx_type_in.filter((el) => el !== item)
                            : [...trx_type_in, item],
                          { shouldDirty: true }
                        );
                      }}
                    >
                      {TYPE_LABELS[item]}
                    </Checkbox>
                  );
                })}
            </div>
          </PageAddon.Filter>
        </div>
        <div className={styles.list}>
          {getContent()}
          {loadingMore && (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default AccountTransactions;
