import { useTranslation } from 'react-i18next';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import useForm from './useForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  onCreate: (args: { identityId: IdentitySchema['id'] }) => void;
}

const WorkspaceIdentityForm = (props: Props) => {
  const { workspaceId, onClose, onCreate } = props;

  const { t } = useTranslation();

  const { form, fields, onSubmit } = useForm({ workspaceId, onCreate });

  return (
    <PageAddon title={t('common.create_identity')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.first_name}
        {fields.last_name}
        {fields.date_of_birth}
        {fields.gender}
        {fields.country}
        {fields.city}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceIdentityForm;
