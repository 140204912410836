import { z } from 'zod';
import agentSchema from '../agentSchema';
import globalClubSchema from '../globalClubSchema';
import trainerPokerRoomSchema from '../trainerPokerRoomSchema';
import workspaceSchema from '../workspaceSchema';

export const ROLE = {
  UNKNOWN: 0,
  PLAYER: 1,
  AGENT: 2,
  CLUBOWNER: 3,
  OPEN_DEAL: 4,
} as const;

export const STATUS = {
  UNKNOWN: 0,
  REVIEW: 1,
  AVAILABLE: 2,
  ACTIVE: 3,
  PAUSED: 4,
  DISCUSSION: 5,
  STOP: 6,
  NOT_RELEVANT: 7,
} as const;

export const CREDIT = {
  UNKNOWN: 0,
  FULL: 1,
  LIMITED: 2,
  FIFTY_FIFTY: 3,
  NO_CREDIT: 4,
} as const;

export const PROTECTION = {
  UNKNOWN: 0,
  PROTECTION: 1,
  PENALTY: 2,
  NO_PENALTY: 3,
  FIFTY_FIFTY: 4,
} as const;

export const holderSchema = z.object({
  id: workspaceSchema.shape.id,
  username: workspaceSchema.shape.name,
});

const ownerSchema = z.string();

const areaIdSchema = z.string();

const currencySchema = z.string();

const policySchema = z.string();

export const dealCommonSchema = z.object({
  id: z.string(),
  code: z.string(),
  agent: z.object({
    id: agentSchema.shape.id,
    name: agentSchema.shape.name,
    contact: agentSchema.shape.contact,
  }),
  poker_room: trainerPokerRoomSchema,
  club: globalClubSchema,
  area_id: areaIdSchema.or(z.null()),
  area2_id: areaIdSchema.or(z.null()),
  holder: holderSchema.or(z.null()),
  owner: ownerSchema.or(z.null()),
  status: z.nativeEnum(STATUS),
  role: z.nativeEnum(ROLE),
  credit: z.nativeEnum(CREDIT),
  protection: z.nativeEnum(PROTECTION),
  currency: currencySchema.or(z.null()),
  chip_rate: z.number().or(z.null()),
  rake_back: z.number().or(z.null()),
  action: z.number().or(z.null()),
  rev_share: z.number().or(z.null()),
  win_share: z.number().or(z.null()),
  rebate: z.number().or(z.null()),
  agent_fee: z.number().or(z.null()),
  withdraw_fee: z.number().or(z.null()),
  hand_cost: z.number().or(z.null()),
  fixed_payment: z.number().or(z.null()),
  income_cap: z.number().or(z.null()),
  financial_conditions: z.string().or(z.null()),
  operating_conditions: z.string().or(z.null()),
  policy: policySchema.or(z.null()),
  description: z.string().or(z.null()),
  notes_count: z.number(),
  split_accounting: z.boolean(),
  is_deleted: z.boolean(),
  created_on: z.string(),
  modified_on: z.string(),
});

export const dealCommonFilterValuesSchema = z.object({
  agent: z.array(
    z.object({
      id: dealCommonSchema.shape.agent.shape.id,
      name: dealCommonSchema.shape.agent.shape.name,
    })
  ),
  poker_room: z.array(dealCommonSchema.shape.poker_room),
  club: z.array(
    z.object({
      id: dealCommonSchema.shape.club.shape.id,
      name: dealCommonSchema.shape.club.shape.name,
      code: dealCommonSchema.shape.club.shape.code,
    })
  ),
  area_id: z.array(areaIdSchema),
  area2_id: z.array(areaIdSchema),
  holder: z.array(
    z.object({
      id: holderSchema.shape.id,
      name: holderSchema.shape.username,
    })
  ),
  owner: z.array(ownerSchema),
  status: z.array(dealCommonSchema.shape.status),
  role: z.array(dealCommonSchema.shape.role),
  credit: z.array(dealCommonSchema.shape.credit),
  protection: z.array(dealCommonSchema.shape.protection),
  currency: z.array(currencySchema),
  policy: z.array(policySchema),
  chip_rate: z.object({ min: z.number(), max: z.number() }),
  rake_back: z.object({ min: z.number(), max: z.number() }),
  action: z.object({ min: z.number(), max: z.number() }),
  rev_share: z.object({ min: z.number(), max: z.number() }),
  win_share: z.object({ min: z.number(), max: z.number() }),
  rebate: z.object({ min: z.number(), max: z.number() }),
  agent_fee: z.object({ min: z.number(), max: z.number() }),
  withdraw_fee: z.object({ min: z.number(), max: z.number() }),
});
