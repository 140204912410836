import clsx from 'clsx';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import styles from './styles.module.scss';
import WorkspaceDashboardWidgetAccounts from './WorkspaceDashboardWidgetAccounts';
import WorkspaceDashboardWidgetChannels from './WorkspaceDashboardWidgetChannels';
import WorkspaceDashboardWidgetIdentities from './WorkspaceDashboardWidgetIdentities';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  className?: string;
}

const WorkspaceDashboardWidgets = (props: Props) => {
  const { workspaceId, className } = props;

  return (
    <div className={clsx(styles.container, className)}>
      <WorkspaceDashboardWidgetAccounts workspaceId={workspaceId} />
      <WorkspaceDashboardWidgetChannels workspaceId={workspaceId} />
      <WorkspaceDashboardWidgetIdentities workspaceId={workspaceId} />
    </div>
  );
};

export default WorkspaceDashboardWidgets;
