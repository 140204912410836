import api from '../../services/api';
import globalDealSchema, { GlobalDealSchema } from '../schemas/deal/globalDealSchema';

type Schema = GlobalDealSchema;

interface Payload {
  agent_id: Schema['agent']['id'];
  poker_room: Schema['poker_room'];
  club_id: Schema['club']['id'];
}

interface Args {
  payload: Payload;
}

const createGlobalDeal = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/deal', payload);

  return globalDealSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalDeal;
