import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import { maxLength, required } from '../../utils/form';
import { FieldProps as Props, Fields } from './types';
import WorkspaceIdentityFormControls from './WorkspaceIdentityFormControls';
import WorkspaceIdentityFormCreate from './WorkspaceIdentityFormCreate';

const WorkspaceIdentityFormPhones = (props: Props) => {
  const { disabled, onSubmit } = props;

  const { t } = useTranslation();

  const { watch, control, setValue } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'phones' });

  return (
    <PageAddon.NewCollapse.Group
      label={t('common.phones')}
      {...(!disabled && {
        onAdd: () => append({ phone: '', open: true, append: true }, { shouldFocus: false }),
      })}
    >
      {fields.map((dynamicField, index) => {
        const value = watch(`phones.${index}`);

        const commonProps = { control, disabled, ...(!value.append && { onSubmit }) };

        return (
          <PageAddon.NewCollapse
            key={dynamicField.id}
            open={value.open}
            label={value.phone || `${t('common.phone')} ${index + 1}`}
            onToggle={(open) => setValue(`phones.${index}.open`, open)}
            controls={<WorkspaceIdentityFormControls onRemove={() => remove(index)} {...props} />}
          >
            <PageAddon.NewCollapse.Field label={t('common.phone_number')}>
              <Input.Quick
                name={`phones.${index}.phone`}
                rules={{ validate: { required, maxLength: maxLength() } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            {value.append && (
              <WorkspaceIdentityFormCreate name={`phones.${index}.append`} onSubmit={onSubmit} />
            )}
          </PageAddon.NewCollapse>
        );
      })}
    </PageAddon.NewCollapse.Group>
  );
};

export default WorkspaceIdentityFormPhones;
