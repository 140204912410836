import { z } from 'zod';
import globalUserSchema from './globalUserSchema';

export const TYPE = {
  UNKNOWN: 0,
  CLUB_HOST: 1,
  UNION_HEAD: 2,
  AFFILIATE: 3,
  AGENT: 4,
};

export const CONTACT_TYPE = {
  UNKNOWN: 0,
  TELEGRAM: 1,
  WHATSAPP: 2,
  EMAIL: 3,
  PHONE: 4,
  CONTACT_PERSON: 5,
};

export const PAYMENT_SYSTEM = {
  UNKNOWN: 0,
  USDT: 1,
  BTC: 2,
  BANK_CARD_RU: 3,
  VENMO: 4,
  ZELLE: 5,
  CASH_APP: 6,
  PAYPAL: 7,
  APPLE_PAY: 8,
} as const;

const schema = z.object({
  id: z.string(),
  name: z.string(),
  contact: z.string().or(z.null()),
  type: z.nativeEnum(TYPE),
});

const agentSchema = z.object({
  id: schema.shape.id,
  name: z.string(),
  contact: z.string().or(z.null()),
  notes_count: z.number(),
  type: z.nativeEnum(TYPE),
  related_agent: schema.or(z.null()),
  payment_system: z.array(z.nativeEnum(PAYMENT_SYSTEM)),
  holder: z
    .object({
      id: globalUserSchema.shape.id,
      username: globalUserSchema.shape.name,
    })
    .or(z.null()),
  contacts: z.array(
    z.object({
      address: z.string(),
      type: z.nativeEnum(CONTACT_TYPE),
      description: z.string().or(z.null()),
    })
  ),
  is_deleted: z.boolean(),
  created_on: z.string(),
  modified_on: z.string(),
});

export type AgentSchema = z.infer<typeof agentSchema>;

export default agentSchema;
