import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import { GlobalExpenseSchema } from '../../api/schemas/globalExpenseSchema';
import PageAddon from '../../components/PageAddon';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import GlobalExpenseDetailDropdown from './GlobalExpenseDetailDropdown';
import useForm from './useForm';

interface Props {
  data: GlobalExpenseSchema;
  onClose: () => void;
  getDealRoute: (args: { dealId: GlobalDealSchema['id'] }) => string;
}

const GlobalExpenseDetail = (props: Props) => {
  const { data, onClose, getDealRoute } = props;

  const { id, date, is_deleted, created_on, modified_on } = data;

  const { t } = useTranslation();

  const { fields } = useForm({ data, getDealRoute });

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon copy title={formatAbsoluteDate(date)} icons={titleIcons} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          {fields.deal_id}
          {fields.type}
          {fields.amount}
          {fields.currency}
          {fields.description}
          {fields.reconcile}
          <PageAddon.Field label={t('common.modified')}>
            <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
          </PageAddon.Field>
          <PageAddon.Field label={t('common.created')}>
            <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
          </PageAddon.Field>
        </PageAddon.Fields>
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <GlobalExpenseDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalExpenseDetail;
