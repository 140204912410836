import DoubleRowText from '../../components/DoubleRowText';
import FadingText from '../../components/FadingText';
import { CommonDealSchema } from './types';

interface Props {
  data: CommonDealSchema;
}

const DealClub = (props: Props) => {
  const { data } = props;

  const { club } = data;

  return <DoubleRowText title={<FadingText text={club.name} />} subTitle={club.code} />;
};

export default DealClub;
