import { cloneElement, isValidElement, ReactNode } from 'react';
import { useSelectContext } from './SelectContext';

type Children = (args: {
  ref: ReturnType<typeof useSelectContext>['floating']['refs']['setReference'];
  referenceProps: ReturnType<ReturnType<typeof useSelectContext>['getReferenceProps']>;
}) => ReactNode;

interface Props {
  children: ReactNode | Children;
}

const SelectTrigger = (props: Props) => {
  const { children } = props;

  const {
    floating: { refs },
    getReferenceProps,
  } = useSelectContext();

  if (typeof children === 'function') {
    return children({ ref: refs.setReference, referenceProps: getReferenceProps() });
  }

  if (!isValidElement(children)) return null;

  return cloneElement(children, getReferenceProps({ ref: refs.setReference }));
};

export default SelectTrigger;
