import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PageDetailLink from '../../../components/PageDetailLink';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { STATUS_COLORS, STATUS_LABELS } from '../helpers';
import { CommonDealSchema } from '../types';
import styles from './styles.module.scss';

interface Props {
  data: CommonDealSchema;
  to: string;
}

const DealDetailLink = (props: Props) => {
  const { data, to } = props;

  const { code, status, notes_count } = data;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <PageDetailLink
        label={code}
        to={to}
        extra={
          <>
            {Boolean(notes_count) && (
              <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>
            )}
          </>
        }
      />
      <span className={clsx(styles.subTitle, styles[STATUS_COLORS[status]])}>
        {STATUS_LABELS[status]}
      </span>
    </div>
  );
};

export default DealDetailLink;
