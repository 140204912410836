import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  createWorkspaceIdentity,
  geonamesSearchCity,
  geonamesSearchCountry,
  updateWorkspaceIdentity,
} from '../../api';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Checkbox from '../../components/Checkbox';
import DatePicker from '../../components/DatePicker';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import Textarea from '../../components/Textarea';
import { DAY } from '../../constants/DATE_FORMATS';
import useQuickForm from '../../hooks/useQuickForm';
import {
  convertCentsToUSDT,
  convertNumberToString,
  convertStringToNumber,
  convertUSDTToCents,
} from '../../utils/data';
import { getFileURL, uploadFiles } from '../../utils/files';
import { age21, max, maxLength, minLength, required, validDateFormat } from '../../utils/form';
import notify from '../../utils/notify';
import { TRAINER_POKER_ROOM_LABELS, trainerPokerRoomOptions } from '../../utils/trainer';
import { numbers } from '../../utils/validations';
import {
  GENDER_LABELS,
  WALLETS_LABELS,
  BANK_ACCOUNTS_LABELS,
  DOCUMENTS_LABELS,
  PHOTOS_LABELS,
  SOCIAL_NETWORKS_LABELS,
  genderOptions,
} from './helpers';
import { Fields } from './types';
import WorkspaceIdentityFormBankAccounts from './WorkspaceIdentityFormBankAccounts';
import WorkspaceIdentityFormDocuments from './WorkspaceIdentityFormDocuments';
import WorkspaceIdentityFormOnlineWallets from './WorkspaceIdentityFormOnlineWallets';
import WorkspaceIdentityFormPhones from './WorkspaceIdentityFormPhones';
import WorkspaceIdentityFormPhotos from './WorkspaceIdentityFormPhotos';
import WorkspaceIdentityFormSocialNetworks from './WorkspaceIdentityFormSocialNetworks';

const MAX_MONTHLY_COST = 9999;
const MAX_RENTAL_PERIOD = 12;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  data?: IdentitySchema;
  disabled?: boolean;
  onCreate?: (args: { identityId: IdentitySchema['id'] }) => void;
}

const useForm = (args: Args) => {
  const { workspaceId, data, disabled, onCreate } = args;

  const { t } = useTranslation();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      first_name: data?.first_name || '',
      last_name: data?.last_name || '',
      date_of_birth: data?.date_of_birth || '',
      gender: data?.gender ? { value: data.gender, label: GENDER_LABELS[data.gender] } : null,
      country: data?.location
        ? { value: data.location.country_iso, label: data.location.country_name }
        : null,
      city: data?.location.city ? { value: data.location.city, label: data.location.city } : null,
      channel: data?.channel || false,
      rooms: data
        ? data.rooms.map((item) => ({ value: item, label: TRAINER_POKER_ROOM_LABELS[item] }))
        : [],
      description: data?.description || '',
      phones: data?.phones.map((item) => ({ phone: item, open: false, append: false })) || [],
      online_wallets: data
        ? data.online_wallets.map((item) => ({
            ...item,
            password: item.password || '',
            type: { value: item.type, label: WALLETS_LABELS[item.type] },
            open: false,
            append: false,
          }))
        : [],
      bank_accounts: data
        ? data.bank_accounts.map((item) => ({
            ...item,
            type: { value: item.type, label: BANK_ACCOUNTS_LABELS[item.type] },
            open: false,
            append: false,
          }))
        : [],
      documents: data
        ? data.documents.map((item) => ({
            issued: item.issued,
            expire: item.expire || '',
            type: { value: item.type, label: DOCUMENTS_LABELS[item.type] },
            files: item.files.map((file) => ({
              id: file.id,
              url: getFileURL(file, 'identity'),
              name: file.original_name,
              content_type: file.content_type,
            })),
            open: false,
            append: false,
          }))
        : [],
      photos: data
        ? data.photos.map((item) => ({
            type: { value: item.type, label: PHOTOS_LABELS[item.type] },
            files: item.files.map((file) => ({
              id: file.id,
              url: getFileURL(file, 'identity'),
              name: file.original_name,
              content_type: file.content_type,
            })),
            open: false,
            append: false,
          }))
        : [],
      social_networks: data
        ? data.social_networks.map((item) => ({
            ...item,
            type: { value: item.type, label: SOCIAL_NETWORKS_LABELS[item.type] },
            open: false,
            append: false,
          }))
        : [],
      monthly_cost: data ? convertNumberToString(data.monthly_cost, convertCentsToUSDT) : '',
      minimal_rental_period: data ? convertNumberToString(data.minimal_rental_period) : '',
    },
  });

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.gender) throw new Error();

    const commonPayload = {
      first_name: values.first_name,
      last_name: values.last_name,
      date_of_birth: values.date_of_birth,
      gender: values.gender.value,
      location: {
        country_iso: values.country?.value || '',
        country_name: values.country?.label || '',
        city: values.city?.value || '',
      },
    };

    if (data) {
      const documents = await Promise.all(
        values.documents
          .filter((item) => !item.append)
          .map(async (item) => {
            if (!item.type) throw new Error();

            return {
              type: item.type.value,
              issued: item.issued,
              expire: item.expire || null,
              file_ids: await uploadFiles(item.files),
            };
          })
      );

      const photos = await Promise.all(
        values.photos
          .filter((item) => !item.append)
          .map(async (item) => {
            if (!item.type) throw new Error();

            return {
              type: item.type.value,
              file_ids: await uploadFiles(item.files),
            };
          })
      );

      await updateWorkspaceIdentity({
        workspaceId,
        identityId: data.id,
        payload: {
          ...commonPayload,
          channel: values.channel,
          rooms: values.rooms.map((item) => item.value),
          description: values.description,
          phones: values.phones.filter((item) => !item.append).map((item) => item.phone),
          online_wallets: values.online_wallets
            .filter((item) => !item.append)
            .map((item) => {
              if (!item.type) throw new Error();

              return {
                ...item,
                password: item.password || null,
                type: item.type.value,
              };
            }),
          bank_accounts: values.bank_accounts
            .filter((item) => !item.append)
            .map((item) => {
              if (!item.type) throw new Error();

              return {
                ...item,
                type: item.type.value,
              };
            }),
          documents,
          photos,
          social_networks: values.social_networks
            .filter((item) => !item.append)
            .map((item) => {
              if (!item.type) throw new Error();

              return {
                ...item,
                type: item.type.value,
              };
            }),
          monthly_cost: convertStringToNumber(values.monthly_cost, convertUSDTToCents),
          minimal_rental_period: convertStringToNumber(values.minimal_rental_period),
        },
      });
    } else {
      const response = await createWorkspaceIdentity({ workspaceId, payload: commonPayload });

      if (onCreate) onCreate({ identityId: response.id });

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const country = watch('country');

  const detail = data && !onCreate;

  const commonProps = {
    control,
    disabled,
    ...(detail && { onSubmit: handleSubmit(onSubmit) }),
  };

  const { control: _, ...collapseProps } = commonProps;

  return {
    form,
    onSubmit,
    fields: {
      first_name: (
        <PageAddon.Field label={t('common.first_name')}>
          <Input.Quick
            name="first_name"
            rules={{ validate: { required, minLength: minLength(), maxLength: maxLength() } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      last_name: (
        <PageAddon.Field label={t('common.last_name')}>
          <Input.Quick
            name="last_name"
            rules={{ validate: { required, minLength: minLength(), maxLength: maxLength() } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      date_of_birth: (
        <PageAddon.Field label={t('common.date_of_birth')}>
          <Controller
            control={control}
            name="date_of_birth"
            rules={{ validate: { required, age21, validDate: validDateFormat(DAY) } }}
            render={({ field }) => (
              <DatePicker
                quick
                errorVariant="popover"
                value={field.value}
                disabled={disabled}
                onChange={(value) => {
                  field.onChange(value);

                  if (commonProps.onSubmit) commonProps.onSubmit();
                }}
                error={errors.date_of_birth?.message}
              />
            )}
          />
        </PageAddon.Field>
      ),
      gender: (
        <PageAddon.Field label={t('common.gender')}>
          <Select.Quick
            name="gender"
            options={genderOptions}
            rules={{ validate: { required } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      country: (
        <PageAddon.Field label={t('common.country')}>
          <Select.Async
            paginated={false}
            onLoad={async ({ search }) => {
              const response = await geonamesSearchCountry({ ...(search && { search }) });

              const options = response.geonames.map((item) => ({
                value: item.country_code,
                label: item.country_name,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="country"
                rules={{ validate: { required } }}
                onChange={() => {
                  setValue('city', null);

                  if (commonProps.onSubmit) commonProps.onSubmit();
                }}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      city: (
        <PageAddon.Field label={t('common.city')}>
          <Select.Async
            paginated={false}
            onLoad={async ({ page, search }) => {
              const response = await geonamesSearchCity({
                featureClass: 'P',
                country: country?.value || '',
                max_rows: String(20),
                orderBy: 'population',
                ...(page && { start_row: String(20 * (Number(page) - 1)) }),
                ...(search && { name: search }),
              });

              const options = response.geonames.map((item) => ({
                value: item.name,
                label: item.name,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="city"
                rules={{ validate: { required } }}
                onChange={() => {
                  if (commonProps.onSubmit) commonProps.onSubmit();
                }}
                {...commonProps}
                {...selectAsyncProps}
                {...{ ...commonProps, disabled: commonProps.disabled || !country }}
              />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      channel: (
        <PageAddon.Field label={t('common.channel')}>
          <Checkbox.Quick name="channel" {...commonProps} />
        </PageAddon.Field>
      ),
      rooms: (
        <PageAddon.Field label={t('common.visited_poker_rooms')}>
          <Select.Multi.Quick name="rooms" options={trainerPokerRoomOptions} {...commonProps} />
        </PageAddon.Field>
      ),
      description: (
        <PageAddon.Field label={t('common.description')}>
          <Textarea.Quick name="description" {...commonProps} />
        </PageAddon.Field>
      ),
      phones: <WorkspaceIdentityFormPhones {...collapseProps} />,
      online_wallets: <WorkspaceIdentityFormOnlineWallets {...collapseProps} />,
      bank_accounts: <WorkspaceIdentityFormBankAccounts {...collapseProps} />,
      documents: <WorkspaceIdentityFormDocuments {...collapseProps} />,
      photos: <WorkspaceIdentityFormPhotos {...collapseProps} />,
      social_networks: <WorkspaceIdentityFormSocialNetworks {...collapseProps} />,
      monthly_cost: (
        <PageAddon.Field label={t('common.cost_month_USDT')}>
          <Input.Quick
            name="monthly_cost"
            allow={numbers}
            rules={{ validate: { max: max(t('common.monthly_cost'), MAX_MONTHLY_COST, 'USDT') } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      minimal_rental_period: (
        <PageAddon.Field label={t('common.min_rental_month')}>
          <Input.Quick
            name="minimal_rental_period"
            allow={numbers}
            rules={{ validate: { max: max(t('common.min_rent'), MAX_RENTAL_PERIOD) } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
