import { z } from 'zod';
import { PLAYER_TYPE, SEATING_PREFERENCE } from './accountStrategySchema';

const workspaceStrategySchema = z.object({
  player_type: z.nativeEnum(PLAYER_TYPE),
  buyin_bb: z.number(),
  buyin_bb_min: z.number(),
  buyin_bb_max: z.number(),
  buyin_step_bb: z.number(),
  buyin_bb_straddle_table_min: z.number(),
  buyin_bb_straddle_table_max: z.number(),
  buyin_step_bb_straddle_table: z.number(),
  rebuy_below_bb: z.number(),
  rebuy_below_bb_min: z.number(),
  rebuy_below_bb_max: z.number(),
  rebuy_below_bb_straddle_table: z.number(),
  rebuy_below_bb_straddle_table_min: z.number(),
  rebuy_below_bb_straddle_table_max: z.number(),
  stop_lose_bb: z.number(),
  stop_lose_bb_min: z.number(),
  stop_lose_bb_max: z.number(),
  stop_win_bb: z.number(),
  stop_win_bb_min: z.number(),
  stop_win_bb_max: z.number(),
  stop_lose_table_bb: z.number(),
  stop_lose_table_bb_min: z.number(),
  stop_lose_table_bb_max: z.number(),
  stop_win_table_bb: z.number(),
  stop_win_table_bb_min: z.number(),
  stop_win_table_bb_max: z.number(),
  max_swing_bb: z.number(),
  max_swing_bb_min: z.number(),
  max_swing_bb_max: z.number(),
  effective_stack_limit_bb: z.number(),
  effective_stack_limit_bb_min: z.number(),
  effective_stack_limit_bb_max: z.number(),
  max_stack_size_bb: z.number(),
  max_stack_size_bb_min: z.number(),
  max_stack_size_bb_max: z.number(),
  mtt_max_rebuy: z.number(),
  mtt_max_rebuy_min: z.number(),
  mtt_max_rebuy_max: z.number(),
  table_min_hands: z.number(),
  table_time: z.number().or(z.null()),
  wait_for_big_blind: z.boolean(),
  mtt_addon: z.boolean(),
  auto_rebuy: z.boolean(),
  seating_preference: z.nativeEnum(SEATING_PREFERENCE),
});

export type WorkspaceStrategySchema = z.infer<typeof workspaceStrategySchema>;

export { workspaceStrategySchema };
