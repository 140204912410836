import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import identitySchema, { IdentitySchema } from '../schemas/identitySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = IdentitySchema;

interface Payload {
  first_name: Schema['first_name'];
  last_name: Schema['last_name'];
  date_of_birth: Schema['date_of_birth'];
  gender: Schema['gender'];
  location: Schema['location'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceIdentity = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity`, payload);

  return identitySchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceIdentity;
