import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import { PaginationSchema } from '../../api/schemas/paginationSchema';
import PageAddon from '../../components/PageAddon';
import Select, { OnLoad } from '../../components/Select';
import { required } from '../../utils/form';
import { TRAINER_GAME_TYPE_LABELS } from '../../utils/trainer';
import { getBotSettingCaption } from '../global-bot-setting-rule/helpers';
import { CommonAccountSchema, CommonAccountFields as Fields } from './types';

interface BotSetting {
  id: CommonAccountSchema['bot_settings'][number]['id'];
  name: CommonAccountSchema['bot_settings'][number]['name'];
  game_type?: CommonAccountSchema['bot_settings'][number]['game_type'];
}

interface Data {
  bot_settings: BotSetting[];
}

interface Props {
  data: Data;
  onSubmit: () => Promise<void> | void;
  onLoad: OnLoad<PaginationSchema<BotSettingSchema>>;
  disabled?: boolean;
  required?: boolean;
  controls?: (args: { botSettingId: BotSettingSchema['id'] }) => ReactNode;
}

const AccountDetailBotSettings = (props: Props) => {
  const { data, disabled, required: req, controls, onSubmit, onLoad } = props;

  const { bot_settings } = data;

  const { watch, setValue } = useFormContext<Fields>();

  const botSettingsIds = watch('bot_settings_ids');

  if (bot_settings.length === 0 && disabled) return '—';

  return (
    <PageAddon.QuickField.Group>
      {bot_settings.map((item) => (
        <PageAddon.QuickField
          key={item.id}
          disabled={disabled}
          title={item.name}
          {...(item.game_type && { subTitle: TRAINER_GAME_TYPE_LABELS[item.game_type] })}
          onDelete={() => {
            setValue(
              'bot_settings_ids',
              botSettingsIds.filter((el) => el.value !== item.id)
            );

            onSubmit();
          }}
        >
          {controls && controls({ botSettingId: item.id })}
        </PageAddon.QuickField>
      ))}
      {!disabled && (
        <Select.Async
          onLoad={async (args) => {
            const response = await onLoad(args);

            const options = response.items.map((item) => ({
              value: item.id,
              label: item.name,
              caption: getBotSettingCaption(item),
            }));

            return options;
          }}
        >
          {({ options, ...selectAsyncProps }) => (
            <Select.Quick
              name="bot_settings_ids"
              disabled={disabled}
              options={options.filter(
                (item) => !botSettingsIds.map((el) => el.value).includes(item.value)
              )}
              onChange={(value) => {
                setValue('bot_settings_ids', value ? [...botSettingsIds, value] : [], {
                  shouldValidate: true,
                });

                onSubmit();
              }}
              {...(req && !bot_settings.length && { rules: { validate: required } })}
              {...selectAsyncProps}
            />
          )}
        </Select.Async>
      )}
    </PageAddon.QuickField.Group>
  );
};

export default AccountDetailBotSettings;
