import { invert } from 'remeda';
import { CURRENCY } from '../api/schemas/currencySchema';

export const CURRENCY_LABELS = invert(CURRENCY);

export const currenciesOptions = [
  { value: CURRENCY.USD, label: CURRENCY_LABELS[CURRENCY.USD] },
  ...Object.values(CURRENCY)
    .filter((item) => item !== CURRENCY.UNKNOWN && item !== CURRENCY.USD)
    .map((item) => ({
      value: item,
      label: CURRENCY_LABELS[item],
    })),
];
