import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import PageAddon from '../../components/PageAddon';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import { checkWorkspaceHasChannelsAccess } from '../../utils/workspace';
import { getTitle } from '../session/helpers';
import useForm from './useForm';
import WorkspaceAccountMetrics from './WorkspaceAccountMetrics';

interface Props {
  data: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
  getChannelRoute?: (args: { channelId: ChannelSchema['id'] }) => string;
  getBotSettingRoute?: (args: { botSettingId: BotSettingSchema['id'] }) => string;
  getDealRoute?: (args: { dealId: WorkspaceDealSchema['id'] }) => string;
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
  getIdentityRoute?: (args: { identityId: IdentitySchema['id'] }) => string;
}

const WorkspaceAccountDetailGeneralTab = (props: Props) => {
  const {
    data,
    workspaceId,
    getBotSettingRoute,
    getChannelRoute,
    getDealRoute,
    getUserRoute,
    getIdentityRoute,
  } = props;

  const {
    id,
    room,
    maintenance,
    trainer_version,
    next_session,
    last_online,
    last_played,
    created_on,
    modified_on,
  } = data;

  const { t } = useTranslation();

  const { form, fields } = useForm({ workspaceId, data });

  const workspaceHasChannelsAccess = checkWorkspaceHasChannelsAccess(workspaceId);

  return (
    <FormProvider {...form}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.poker_room')}>
          <PageAddon.Copy>{TRAINER_POKER_ROOM_LABELS[room]}</PageAddon.Copy>
        </PageAddon.Field>
        {fields.club_ids}
        {fields.game_types}
        {fields.comparative_limits}
        {fields.table_sizes}
        {fields.max_tables}
        {fields.geo}
        {workspaceHasChannelsAccess && fields.channel_ids({ getChannelRoute })}
        {fields.bot_settings_ids({ ...(!maintenance.external_partner && { getBotSettingRoute }) })}
        {!maintenance.external_partner && fields.deal_ids({ getDealRoute })}
        {fields.affiliate_reference_number}
        {fields.user_id({ getUserRoute })}
        {fields.identity_id({ getIdentityRoute })}
        {fields.tag_ids}
        <PageAddon.Field label={t('common.trainer_version')}>
          {trainer_version ? <PageAddon.Copy>{trainer_version}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.next_session')}>
          {next_session ? (
            <PageAddon.Copy>
              {getTitle({ start: next_session.start, end: next_session.end })}
            </PageAddon.Copy>
          ) : (
            '—'
          )}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.last_online')}>
          {last_online ? <PageAddon.Copy>{formatAbsoluteDate(last_online)}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.last_played')}>
          {last_online ? <PageAddon.Copy>{formatAbsoluteDate(last_played)}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.modified')}>
          <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
        </PageAddon.Field>
        {fields.email}
        <PageAddon.Collapse label={t('common.metrics')}>
          <WorkspaceAccountMetrics data={data} />
        </PageAddon.Collapse>
      </PageAddon.Fields>
    </FormProvider>
  );
};

export default WorkspaceAccountDetailGeneralTab;
