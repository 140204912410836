import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { maxLength, required, minLength } from '../../utils/form';
import { WALLETS_LABELS, walletsOptions } from './helpers';
import { Fields, FieldProps as Props } from './types';
import WorkspaceIdentityFormControls from './WorkspaceIdentityFormControls';
import WorkspaceIdentityFormCreate from './WorkspaceIdentityFormCreate';

const WorkspaceIdentityFormOnlineWallets = (props: Props) => {
  const { disabled, onSubmit } = props;

  const { t } = useTranslation();

  const { watch, control, setValue } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'online_wallets' });

  return (
    <PageAddon.NewCollapse.Group
      label={t('common.online_wallets')}
      {...(!disabled && {
        onAdd: () =>
          append(
            { login: '', password: '', type: null, open: true, append: true },
            { shouldFocus: false }
          ),
      })}
    >
      {fields.map((dynamicField, index) => {
        const value = watch(`online_wallets.${index}`);

        const commonProps = { control, disabled, ...(!value.append && { onSubmit }) };

        return (
          <PageAddon.NewCollapse
            key={dynamicField.id}
            open={value.open}
            onToggle={(open) => setValue(`online_wallets.${index}.open`, open)}
            label={
              (value.type && WALLETS_LABELS[value.type.value]) ||
              `${t('common.wallet')} ${index + 1}`
            }
            controls={<WorkspaceIdentityFormControls onRemove={() => remove(index)} {...props} />}
          >
            <PageAddon.NewCollapse.Field label={t('common.type')}>
              <Select.Quick
                name={`online_wallets.${index}.type`}
                options={walletsOptions}
                rules={{ validate: required }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.login')}>
              <Input.Quick
                name={`online_wallets.${index}.login`}
                rules={{ validate: { required, maxLength: maxLength() } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.password')}>
              <Input.Quick
                name={`online_wallets.${index}.password`}
                type="password"
                rules={{ validate: { minLength: minLength(6), maxLength: maxLength() } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            {value.append && (
              <WorkspaceIdentityFormCreate
                name={`online_wallets.${index}.append`}
                onSubmit={onSubmit}
              />
            )}
          </PageAddon.NewCollapse>
        );
      })}
    </PageAddon.NewCollapse.Group>
  );
};

export default WorkspaceIdentityFormOnlineWallets;
