import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { PaginationSchema } from '../../api/schemas/paginationSchema';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import Select, { OnLoad } from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { required } from '../../utils/form';
import notify from '../../utils/notify';
import { CommonAccountSchema, CommonAccountFields as Fields } from './types';

interface Club {
  id: CommonAccountSchema['clubs'][number]['id'];
  name: CommonAccountSchema['clubs'][number]['name'];
  code?: CommonAccountSchema['clubs'][number]['code'];
}

interface Data {
  clubs: Club[];
  stopped_clubs: { id: CommonAccountSchema['stopped_clubs'][number]['id'] }[];
}

interface Props {
  data: Data;
  onSubmit: () => Promise<void> | void;
  onLoad: OnLoad<PaginationSchema<GlobalClubSchema>>;
  disabled?: boolean;
  required?: boolean;
}

const AccountDetailClubs = (props: Props) => {
  const { data, disabled, required: req, onSubmit, onLoad } = props;

  const { clubs, stopped_clubs } = data;

  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<Fields>();

  const clubIds = watch('club_ids');
  const stoppedClubIds = watch('stopped_club_ids');

  if (clubs.length === 0 && disabled) return '—';

  return (
    <PageAddon.QuickField.Group>
      {clubs.map((item) => {
        const stopped = stopped_clubs.map((el) => el.id).includes(item.id);

        return (
          <PageAddon.QuickField
            key={item.id}
            danger={stopped}
            disabled={disabled}
            title={item.name}
            subTitle={item.code}
            onDelete={() => {
              setValue(
                'club_ids',
                clubIds.filter((el) => el.value !== item.id)
              );

              onSubmit();
            }}
          >
            <Tooltip label={stopped ? t('common.start') : t('common.stop')}>
              <Button
                size="extra-small"
                variant="light"
                disabled={disabled}
                icon={stopped ? icon('playCircle', 16) : icon('pauseCircle', 16)}
                onClick={() => {
                  if (stopped) {
                    setValue(
                      'stopped_club_ids',
                      stoppedClubIds.filter((el) => el !== item.id)
                    );
                  } else {
                    setValue('stopped_club_ids', [...stoppedClubIds, item.id]);
                  }

                  onSubmit();

                  notify('success', {
                    title: stopped
                      ? t('sentences.club_has_been_removed_from_stop_list')
                      : t('sentences.club_has_been_added_to_stop_list'),
                  });
                }}
              />
            </Tooltip>
          </PageAddon.QuickField>
        );
      })}
      {!disabled && (
        <Select.Async
          onLoad={async (args) => {
            const response = await onLoad(args);

            const options = response.items.map((item) => ({
              value: item.id,
              label: item.name,
              caption: item.code,
            }));

            return options;
          }}
        >
          {({ options, ...selectAsyncProps }) => (
            <Select.Quick
              name="club_ids"
              disabled={disabled}
              options={options.filter(
                (item) => !clubIds.map((el) => el.value).includes(item.value)
              )}
              onChange={(value) => {
                setValue('club_ids', value ? [...clubIds, value] : [], { shouldValidate: true });

                onSubmit();
              }}
              {...(req && !clubs.length && { rules: { validate: required } })}
              {...selectAsyncProps}
            />
          )}
        </Select.Async>
      )}
    </PageAddon.QuickField.Group>
  );
};

export default AccountDetailClubs;
