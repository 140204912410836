import { useQuery } from '@tanstack/react-query';
import { loadWorkspaceAccountStrategy } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import queryKeys from '../../../config/queryKeys';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

export const useWorkspaceAccountStrategyQuery = (args: Args) => {
  const { workspaceId, accountId } = args;

  const queryKey = [queryKeys.workspaceAccountStrategy({ workspaceId, accountId })];
  const queryFn = () => loadWorkspaceAccountStrategy({ workspaceId, accountId });

  return useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });
};
