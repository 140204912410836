import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: IdentitySchema;
}

const WorkspaceIdentityDropdown = (props: Props) => {
  const { title, options } = useDropdown(props);

  const compactOptions = compact([
    options.market,
    options.copy,
    options.divider,
    options.delete,
    options.restore,
  ]);

  return <Dropdown title={title} buttonSize="small" options={compactOptions} />;
};

export default WorkspaceIdentityDropdown;
