import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  title: ReactNode;
  subTitle: ReactNode;
}

const DoubleRowText = (props: Props) => {
  const { title, subTitle } = props;

  return (
    <div className={styles.container}>
      {title}
      <span className={styles.subTitle}>{subTitle}</span>
    </div>
  );
};

export default DoubleRowText;
