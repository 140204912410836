import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import clsx from 'clsx';
import { useState, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import TextareaAutosize from 'react-textarea-autosize';
import Error from '../Error';
import Label, { getLabelProps } from '../Label';
import styles from './styles.module.scss';
import TextareaQuick from './TextareaQuick';
import { Props } from './types';

const Textarea = (props: Props) => {
  const {
    label,
    error,
    addon,
    ref,
    maxLength = 0,
    onChange,
    errorVariant = 'default',
    className: customClassName,
    ...rest
  } = props;

  const [value, setValue] = useState(rest.value ? String(rest.value).length : 0);

  const className = clsx(styles.textarea, {
    [styles.error]: error,
    ...(errorVariant === 'popover' && { [styles.errorTextareaPopover]: true }),
    ...(customClassName && { [customClassName]: true }),
  });

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: true,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift({ padding: 4 }), arrow({ element: arrowRef })],
  });

  const mergedRefs = mergeRefs([ref, ...(errorVariant === 'popover' ? [refs.setReference] : [])]);

  return (
    <div className={styles.container}>
      {(label || addon) && <Label addon={addon} {...getLabelProps(label)} />}
      <div className={className}>
        <TextareaAutosize
          data-textarea
          ref={mergedRefs}
          onChange={(event) => {
            if (onChange) {
              onChange(event);
            }

            setValue(event.target.value.length);
          }}
          {...(maxLength !== 0 && { maxLength })}
          {...rest}
        />
      </div>
      <div className={styles.bottom}>
        {errorVariant === 'default' && <Error error={error} />}
        {errorVariant === 'popover' && error && (
          <div ref={refs.setFloating} style={floatingStyles} className={styles.errorPopover}>
            <FloatingArrow
              ref={arrowRef}
              context={context}
              tipRadius={1}
              className={styles.arrow}
            />
            {error}
          </div>
        )}
        {maxLength ? <div className={styles.maxLength}>{maxLength - value}</div> : null}
      </div>
    </div>
  );
};

Textarea.Quick = TextareaQuick;

export default Textarea;
