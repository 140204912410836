import { ReactNode } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pathOr, stringToPath } from 'remeda';
import styles from './styles.module.scss';
import { Option, Props as SelectProps } from './types';
import Select from '.';

type Props<T extends FieldValues, Name extends FieldPath<T>> = UseControllerProps<T, Name> &
  SelectProps<FieldValues[Name]> & {
    onSubmit?: () => void;
    onChange?: (option: Option<FieldValues[Name], FieldValues[Name]>) => void;
    renderCaption?: (option: Option<FieldValues[Name], FieldValues[Name]>) => ReactNode;
  };

const SelectQuick = <T extends FieldValues, Name extends FieldPath<T>>(props: Props<T, Name>) => {
  const {
    name,
    control,
    rules,
    onSubmit,
    label,
    options,
    disabled,
    clearable = false,
    onChange,
    renderCaption,
    ...rest
  } = props;

  const { t } = useTranslation();

  const {
    field,
    formState: { errors, isValid },
  } = useController({ name, control, rules });

  if (disabled && !field.value) {
    return (
      <div data-quick className={styles.empty}>
        —
      </div>
    );
  }

  // @ts-ignore
  const error = pathOr(errors, stringToPath(name), undefined)?.message;

  return (
    <Select
      quick
      clearable={clearable}
      placeholder={t('common.select')}
      label={label}
      disabled={disabled}
      options={options}
      value={field.value}
      renderCaption={renderCaption}
      onChange={(value) => {
        field.onChange(value);

        if (onChange) onChange(value);

        if (onSubmit && isValid) onSubmit();
      }}
      errorVariant="popover"
      error={error}
      {...rest}
    />
  );
};

export default SelectQuick;
