import Dropdown, { presets } from '../../components/Dropdown';
import { FieldProps } from './types';

interface Props extends FieldProps {
  onRemove: () => void;
}

const WorkspaceIdentityFormControls = (props: Props) => {
  const { onRemove, disabled, onSubmit } = props;

  if (disabled) return null;

  return (
    <Dropdown
      options={[
        {
          ...presets.delete,
          onClick: () => {
            onRemove();

            if (onSubmit) onSubmit();
          },
        },
      ]}
    />
  );
};

export default WorkspaceIdentityFormControls;
