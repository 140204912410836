import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { globalExpenseFilterValuesSchema } from '../schemas/globalExpenseSchema';

const loadGlobalExpensesFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/expenses/filter-values', { params });

  return globalExpenseFilterValuesSchema.parse(response.data);
};

export default loadGlobalExpensesFilterValues;
