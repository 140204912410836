import { addSeconds } from 'date-fns';
import { createWithEqualityFn as create } from 'zustand/traditional';
import currentUserSessionSchema, {
  CurrentUserSessionSchema,
} from '../api/schemas/currentUserSessionSchema';
import { getCookie, removeCookie, setCookie } from '../utils/cookie';

interface State {
  session: CurrentUserSessionSchema | null;
  setSession: (session: CurrentUserSessionSchema) => void;
  removeSession: () => void;
}

const getSession = () => {
  const session = getCookie('_s');

  try {
    if (session) return currentUserSessionSchema.parse(JSON.parse(session));
  } catch {
    removeCookie('_s');
  }

  return null;
};

const useSessionStore = create<State>()((set) => ({
  session: getSession(),
  setSession: (payload) => {
    setCookie('_s', JSON.stringify(payload), {
      expires: addSeconds(new Date(), payload.expires_in),
    });

    set({ session: payload });
  },
  removeSession: () =>
    set(() => {
      removeCookie('_s');

      return { session: null };
    }),
}));

export { useSessionStore };
