import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BANK_ACCOUNT_TYPE } from '../../api/schemas/identity';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { maxLength, required } from '../../utils/form';
import { BANK_ACCOUNTS_LABELS } from './helpers';
import { Fields, FieldProps as Props } from './types';
import WorkspaceIdentityFormControls from './WorkspaceIdentityFormControls';
import WorkspaceIdentityFormCreate from './WorkspaceIdentityFormCreate';

const options = [
  {
    value: BANK_ACCOUNT_TYPE.CHECKING,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.CHECKING],
  },
  {
    value: BANK_ACCOUNT_TYPE.SAVING,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.SAVING],
  },
  {
    value: BANK_ACCOUNT_TYPE.MONEY_MARKET,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.MONEY_MARKET],
  },
  {
    value: BANK_ACCOUNT_TYPE.CERTIFICATE_OF_DEPOSIT,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.CERTIFICATE_OF_DEPOSIT],
  },
  {
    value: BANK_ACCOUNT_TYPE.CARD,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.CARD],
  },
];

const WorkspaceIdentityFormBankAccounts = (props: Props) => {
  const { disabled, onSubmit } = props;

  const { t } = useTranslation();

  const { watch, control, setValue } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'bank_accounts' });

  return (
    <PageAddon.NewCollapse.Group
      label={t('common.bank_accounts')}
      {...(!disabled && {
        onAdd: () =>
          append(
            { name: '', type: null, banking_digits: '', fullname: '', open: true, append: true },
            { shouldFocus: false }
          ),
      })}
    >
      {fields.map((dynamicField, index) => {
        const value = watch(`bank_accounts.${index}`);

        const commonProps = { control, disabled, ...(!value.append && { onSubmit }) };

        return (
          <PageAddon.NewCollapse
            key={dynamicField.id}
            open={value.open}
            onToggle={(open) => setValue(`bank_accounts.${index}.open`, open)}
            label={value.name || `${t('common.bank')} ${index + 1}`}
            controls={<WorkspaceIdentityFormControls onRemove={() => remove(index)} {...props} />}
          >
            <PageAddon.NewCollapse.Field label={t('common.name')}>
              <Input.Quick
                name={`bank_accounts.${index}.name`}
                rules={{ validate: { required, maxLength: maxLength() } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.type')}>
              <Select.Quick
                name={`bank_accounts.${index}.type`}
                options={options}
                rules={{ validate: { required } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.banking_digits')}>
              <Input.Quick
                name={`bank_accounts.${index}.banking_digits`}
                rules={{ validate: { required, maxLength: maxLength() } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            <PageAddon.NewCollapse.Field label={t('common.full_name')}>
              <Input.Quick
                name={`bank_accounts.${index}.fullname`}
                rules={{ validate: { required, maxLength: maxLength() } }}
                {...commonProps}
              />
            </PageAddon.NewCollapse.Field>
            {value.append && (
              <WorkspaceIdentityFormCreate
                name={`bank_accounts.${index}.append`}
                onSubmit={onSubmit}
              />
            )}
          </PageAddon.NewCollapse>
        );
      })}
    </PageAddon.NewCollapse.Group>
  );
};

export default WorkspaceIdentityFormBankAccounts;
