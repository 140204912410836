import { useFormContext } from 'react-hook-form';
import { loadWorkspaceDeals } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import useURL from '../../hooks/useURL';
import { required } from '../../utils/form';
import { renderCaption } from '../deal/helpers';
import { Fields } from './types';

interface Deal {
  id: WorkspaceAccountSchema['deals'][number]['id'];
  code: WorkspaceAccountSchema['deals'][number]['code'];
  agent?: WorkspaceAccountSchema['deals'][number]['agent'];
}

interface Data {
  room: WorkspaceAccountSchema['room'];
  clubIds: WorkspaceAccountSchema['clubs'][number]['id'][];
  deals: Deal[];
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: Data;
  onSubmit: () => Promise<void> | void;
  disabled?: boolean;
  bulk?: boolean;
  required?: boolean;
  getDealRoute?: (args: { dealId: WorkspaceDealSchema['id'] }) => string;
}

const WorkspaceAccountDetailDeals = (props: Props) => {
  const { workspaceId, data, disabled, bulk, required: req, onSubmit, getDealRoute } = props;

  const { room, clubIds, deals } = data;

  const { navigate } = useURL();

  const { watch, setValue } = useFormContext<Fields>();

  const dealIds = watch('deal_ids');

  if (deals.length === 0 && disabled) return '—';

  return (
    <PageAddon.QuickField.Group>
      {deals.map((item) => (
        <PageAddon.QuickField
          key={item.id}
          disabled={disabled}
          title={item.code}
          {...(item.agent && { subTitle: item.agent.name })}
          onDelete={() => {
            setValue(
              'deal_ids',
              dealIds.filter((el) => el.value !== item.id)
            );

            onSubmit();
          }}
        >
          {getDealRoute && (
            <LinkButton
              size="extra-small"
              variant="light"
              onClick={() => navigate(getDealRoute({ dealId: item.id }))}
            />
          )}
        </PageAddon.QuickField>
      ))}
      {!disabled && (
        <Select.AsyncV2
          onLoad={(args) =>
            loadWorkspaceDeals(
              { workspaceId },
              {
                ...args,
                club_id_in: clubIds.join(','),
                ...(!bulk && { poker_room_in: `${room}` }),
              }
            )
          }
          select={(item) => ({
            value: item.id,
            label: item.code,
            caption: renderCaption(item),
          })}
        >
          {({ options, ...selectAsyncProps }) => (
            <Select.Quick
              name="deal_ids"
              disabled={disabled}
              options={options.filter(
                (item) => !dealIds.map((el) => el.value).includes(item.value)
              )}
              onChange={(value) => {
                setValue('deal_ids', value ? [...dealIds, value] : [], { shouldValidate: true });

                onSubmit();
              }}
              {...(req && !deals.length && { rules: { validate: required } })}
              {...selectAsyncProps}
            />
          )}
        </Select.AsyncV2>
      )}
    </PageAddon.QuickField.Group>
  );
};

export default WorkspaceAccountDetailDeals;
