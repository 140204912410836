import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { loadWorkspaceAccountHistory, loadWorkspaceAccountHistoryFilterValues } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { HistorySchema } from '../../../api/schemas/historySchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import HistoryDialog from '../../../components/HistoryDialog';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import { createInfinitePaginatedQueryData } from '../../../utils/queryClient';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../../utils/trainer';
import { PILOT_STATUS_LABELS, PLAYER_TYPE_LABELS, TABLE_SIZE_LABELS } from '../../account/helpers';
import { STATUS_LABELS, TYPE_LABELS } from '../../session/helpers';
import { STATUS_LABELS as CHANNEL_STATUS_LABELS } from '../../workspace-channel/helpers';

const enums = {
  room: TRAINER_POKER_ROOM_LABELS,
  game_types: TRAINER_GAME_TYPE_LABELS,
  comparative_limits: TRAINER_LIMITS_LABELS,
  table_sizes: TABLE_SIZE_LABELS,
  pilot_status: PILOT_STATUS_LABELS,
  bot_setting_game_type: TRAINER_GAME_TYPE_LABELS,
  channel_status: CHANNEL_STATUS_LABELS,
  player_type: PLAYER_TYPE_LABELS,
  status: STATUS_LABELS,
  session_type: TYPE_LABELS,
  deal_poker_room: TRAINER_POKER_ROOM_LABELS,
};

interface Props {
  workspaceId: WorkspaceSchema['id'];
  account: WorkspaceAccountSchema;
  onClose: () => void;
}

const WorkspaceAccountHistoryDialog = (props: Props) => {
  const { workspaceId, account, onClose } = props;

  const { id: accountId, login } = account;

  const [search, setSearch] = useState('');

  const [filter, setFilter] = useState({});

  const queryKey = [
    queryKeys.workspaceAccountHistory({ workspaceId, accountId }),
    { search },
    filter,
  ];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) =>
      loadWorkspaceAccountHistory(
        { workspaceId, accountId },
        { ...params, ...filter, ...(search && { search }) }
      ),
  });

  useWebSocketMessage<HistorySchema>({
    domain: 'History',
    onMessage: (message) => {
      const { payload } = message;

      if (message.action === 'Created' && payload.instance.id === accountId) {
        createInfinitePaginatedQueryData(queryKey, payload);
      }
    },
  });

  const filterQuery = useQuery({
    queryKey: [queryKeys.workspaceAccountHistoryFilterValues({ workspaceId, accountId })],
    queryFn: () => loadWorkspaceAccountHistoryFilterValues({ workspaceId, accountId }),
    placeholderData: keepPreviousData,
  });

  return (
    <HistoryDialog
      query={query}
      enums={enums}
      title={login}
      search={search}
      onSearch={setSearch}
      onClose={onClose}
      filter={<HistoryDialog.Filter query={filterQuery} onChange={setFilter} />}
    />
  );
};

export default WorkspaceAccountHistoryDialog;
