import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceAccountSchema, {
  WorkspaceAccountSchema,
} from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceAccountSchema;

type Payload = Partial<{
  room: Schema['room'];
  channel_ids: string[];
  club_ids: Schema['clubs'][number]['id'][];
  game_types: Schema['game_types'];
  comparative_limits: Schema['comparative_limits'];
  table_sizes: Schema['table_sizes'];
  max_tables: Schema['max_tables'];
  tag_ids: Schema['tags'][number]['id'][];
  affiliate_reference_number: Schema['affiliate_reference_number'] | null;
  user_id: Exclude<Schema['user'], null>['id'] | null;
  geo: Schema['geo'] | null;
  identity_id: Exclude<Schema['identity'], null>['id'] | null;
  bot_settings_ids: Schema['bot_settings'][number]['id'][];
  deal_ids: Schema['deals'][number]['id'][];
  nickname_pool: string;
}>;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const autoCreateWorkspaceAccounts = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/auto-register`,
    payload
  );

  return z.array(workspaceAccountSchema.pick({ id: true })).parse(response.data);
};

export default autoCreateWorkspaceAccounts;
