import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { globalBonusFilterValuesSchema } from '../schemas/globalBonusSchema';

const loadGlobalBonusesFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/bonus/filter-values', { params });

  return globalBonusFilterValuesSchema.parse(response.data);
};

export default loadGlobalBonusesFilterValues;
