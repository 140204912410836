import { ReactNode } from 'react';
import { DropzoneProps } from 'react-dropzone';
import Files from '../../types/Files';
import { handleDrop } from '../../utils/files';
import Attach from '../Attach';
import Dropzone from '../Dropzone';
import Error from '../Error';
import FilesList from '../FilesList';
import MediaViewer from '../MediaViewer';
import styles from './styles.module.scss';

interface Props {
  value: Files;
  children: ReactNode;
  onChange: (files: Files) => void;
  error?: string;
  disabled?: boolean;
  inline?: boolean;
  accept?: DropzoneProps['accept'];
  maxSize?: DropzoneProps['maxSize'];
}

const MediaFilesField = (props: Props) => {
  const { value, accept, maxSize, children, error, disabled, inline, onChange } = props;

  const files = value.map((item) => ({
    name: item.name,
    src: item.url,
    ...(!disabled && {
      onRemove: () => onChange(value.filter((file) => file.url !== item.url)),
    }),
  }));

  const commonProps = { accept, maxSize, disabled };

  return (
    <>
      {!inline && (
        <div>
          <Dropzone
            {...commonProps}
            onDrop={(acceptedFiles) => onChange([...value, ...handleDrop(acceptedFiles)])}
          >
            {children}
          </Dropzone>
          <Error error={error} />
        </div>
      )}
      <div className={styles.files}>
        <MediaViewer files={files}>
          {inline && !disabled && (
            <Attach
              {...commonProps}
              error={error}
              onAttach={(acceptedFiles) => onChange([...value, ...acceptedFiles])}
            />
          )}
        </MediaViewer>
        <FilesList files={files} />
      </div>
    </>
  );
};

export default MediaFilesField;
