import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const PageAddonHeading = (props: Props) => {
  const { children, className: customClassName } = props;

  const className = clsx(styles.container, {
    ...(customClassName && { [customClassName]: true }),
  });

  return <div className={className}>{children}</div>;
};

export default PageAddonHeading;
