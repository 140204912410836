import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceAccountAutoCreateForm from '../../features/workspace-account/WorkspaceAccountAutoCreateForm';
import WorkspaceAccountBotSettingDetail from '../../features/workspace-account/WorkspaceAccountBotSettingDetail';
import WorkspaceAccountBulkActionsDetail from '../../features/workspace-account/WorkspaceAccountBulkActionsDetail';
import WorkspaceAccountDetail from '../../features/workspace-account/WorkspaceAccountDetail';
import WorkspaceAccountBulkForm from '../../features/workspace-account/WorkspaceAccountForm/WorkspaceAccountBulkForm';
import WorkspaceAccountForm from '../../features/workspace-account/WorkspaceAccountForm/WorkspaceAccountForm';
import WorkspaceAccountImport from '../../features/workspace-account/WorkspaceAccountImport';
import WorkspaceAccountLoader from '../../features/workspace-account/WorkspaceAccountLoader';
import WorkspaceAccountNotes from '../../features/workspace-account/WorkspaceAccountNotes';
import WorkspaceAccountRoomConfigBulkForm from '../../features/workspace-account/WorkspaceAccountRoomConfigBulkForm';
import WorkspaceAccountSchedule from '../../features/workspace-account/WorkspaceAccountSchedule';
import WorkspaceAccountStrategyBulkForm from '../../features/workspace-account/WorkspaceAccountStrategy/WorkspaceAccountStrategyBulkForm';
import WorkspaceAccountTransactions from '../../features/workspace-account/WorkspaceAccountTransactions';
import WorkspaceChannelDetail from '../../features/workspace-channel/WorkspaceChannelDetail';
import WorkspaceChannelLoader from '../../features/workspace-channel/WorkspaceChannelLoader';
import WorkspaceDealDetail from '../../features/workspace-deal/WorkspaceDealDetail';
import WorkspaceDealLoader from '../../features/workspace-deal/WorkspaceDealLoader';
import WorkspaceIdentityDetail from '../../features/workspace-identity/WorkspaceIdentityDetail';
import WorkspaceIdentityLoader from '../../features/workspace-identity/WorkspaceIdentityLoader';
import WorkspaceUserDetail from '../../features/workspace-user/WorkspaceUserDetail';
import WorkspaceUserLoader from '../../features/workspace-user/WorkspaceUserLoader';
import WorkspaceAccountsPage from '../../pages/WorkspaceAccountsPage';
import Notes from '../Notes';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountsPage workspaceId={workspaceId} />}
  </Route>
);

const createForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceAccountForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
        onCreate={({ accountId }) => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
      />
    )}
  </Route>
);

const autoCreateForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceAccountAutoCreateForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
      />
    )}
  </Route>
);

const importForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceAccountImport
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
            onNotesOpen={() => navigate(routes.workspaceAccountNotes({ workspaceId, accountId }))}
            onTransactionsOpen={({ type, id }) =>
              navigate(routes.workspaceAccountTransactions({ workspaceId, accountId, type, id }))
            }
            getBotSettingRoute={({ botSettingId }) =>
              routes.workspaceAccountBotSetting({ workspaceId, accountId, botSettingId })
            }
            getChannelRoute={({ channelId }) =>
              routes.workspaceAccountChannel({ workspaceId, accountId, channelId })
            }
            getDealRoute={({ dealId }) =>
              routes.workspaceAccountDeal({ workspaceId, accountId, dealId })
            }
            getUserRoute={({ userId }) =>
              routes.workspaceAccountUser({ workspaceId, accountId, userId })
            }
            getIdentityRoute={({ identityId }) =>
              routes.workspaceAccountIdentity({ workspaceId, accountId, identityId })
            }
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const detailNotes = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountNotes workspaceId={workspaceId} accountId={accountId}>
        {(loader) => (
          <Notes
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
            {...loader}
          />
        )}
      </WorkspaceAccountNotes>
    )}
  </Route>
);

const detailTransactions = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountTransactions
        workspaceId={workspaceId}
        accountId={accountId}
        onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
      />
    )}
  </Route>
);

const detailBotSetting = (
  <Route params={['workspaceId', 'accountId', 'botSettingId']}>
    {({ workspaceId, accountId, botSettingId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountBotSettingDetail
            data={data}
            botSettingId={botSettingId}
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const detailChannel = (
  <Route params={['workspaceId', 'accountId', 'channelId']}>
    {({ workspaceId, accountId, channelId, navigate }) => (
      <WorkspaceChannelLoader workspaceId={workspaceId} channelId={channelId}>
        {({ data }) => (
          <WorkspaceChannelDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
          />
        )}
      </WorkspaceChannelLoader>
    )}
  </Route>
);

const detailDeal = (
  <Route params={['workspaceId', 'accountId', 'dealId']}>
    {({ workspaceId, accountId, dealId, navigate }) => (
      <WorkspaceDealLoader workspaceId={workspaceId} dealId={dealId}>
        {({ data }) => (
          <WorkspaceDealDetail
            data={data}
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
          />
        )}
      </WorkspaceDealLoader>
    )}
  </Route>
);

const detailIdentity = (
  <Route params={['workspaceId', 'accountId', 'identityId']}>
    {({ workspaceId, accountId, identityId, navigate }) => (
      <WorkspaceIdentityLoader workspaceId={workspaceId} identityId={identityId}>
        {({ data }) => (
          <WorkspaceIdentityDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
          />
        )}
      </WorkspaceIdentityLoader>
    )}
  </Route>
);

const schedule = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId }) => (
      <WorkspaceAccountSchedule workspaceId={workspaceId} accountId={accountId} />
    )}
  </Route>
);

const detailUser = (
  <Route params={['workspaceId', 'accountId', 'userId']}>
    {({ workspaceId, accountId, userId, navigate }) => (
      <WorkspaceUserLoader workspaceId={workspaceId} userId={userId}>
        {({ data }) => (
          <WorkspaceUserDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
          />
        )}
      </WorkspaceUserLoader>
    )}
  </Route>
);

const bulkEditForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountBulkForm workspaceId={workspaceId} />}
  </Route>
);

const bulkStrategyEditForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountStrategyBulkForm workspaceId={workspaceId} />}
  </Route>
);

const bulkRoomConfig = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountRoomConfigBulkForm workspaceId={workspaceId} />}
  </Route>
);

const bulkSuccess = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountBulkActionsDetail workspaceId={workspaceId} />}
  </Route>
);

const bulkError = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceAccountBulkActionsDetail workspaceId={workspaceId} />}
  </Route>
);

const bulkErrorAccount = (
  <Route params={['workspaceId', 'accountId']}>
    {({ workspaceId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceAccountsBulkError({ workspaceId }))}
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const workspaceAccountsPage: RouteObject = {
  path: routes.workspaceAccounts({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceAccountsCreate({ workspaceId: ':workspaceId' }),
      element: createForm,
    },
    {
      path: routes.workspaceAccountsAutoCreate({ workspaceId: ':workspaceId' }),
      element: autoCreateForm,
    },
    {
      path: routes.workspaceAccountsImport({ workspaceId: ':workspaceId' }),
      element: importForm,
    },
    {
      path: routes.workspaceAccount({
        workspaceId: ':workspaceId',
        accountId: ':accountId',
      }),
      element: detail,
      children: [
        {
          path: routes.workspaceAccountNotes({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: detailNotes,
        },
        {
          path: routes.workspaceAccountTransactions({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            type: ':type',
            id: ':id?',
          }),
          element: detailTransactions,
        },
        {
          path: routes.workspaceAccountBotSetting({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            botSettingId: ':botSettingId',
          }),
          element: detailBotSetting,
        },
        {
          path: routes.workspaceAccountChannel({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            channelId: ':channelId',
          }),
          element: detailChannel,
        },
        {
          path: routes.workspaceAccountDeal({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            dealId: ':dealId',
          }),
          element: detailDeal,
        },
        {
          path: routes.workspaceAccountUser({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            userId: ':userId',
          }),
          element: detailUser,
        },
        {
          path: routes.workspaceAccountIdentity({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            identityId: ':identityId',
          }),
          element: detailIdentity,
        },
        {
          path: routes.workspaceAccountSchedule({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: schedule,
        },
      ],
    },
    {
      path: routes.workspaceAccountsBulkEdit({ workspaceId: ':workspaceId' }),
      element: bulkEditForm,
    },
    {
      path: routes.workspaceAccountsBulkStrategyEdit({ workspaceId: ':workspaceId' }),
      element: bulkStrategyEditForm,
    },
    {
      path: routes.workspaceAccountsBulkRoomConfig({ workspaceId: ':workspaceId' }),
      element: bulkRoomConfig,
    },
    {
      path: routes.workspaceAccountsBulkSuccess({ workspaceId: ':workspaceId' }),
      element: bulkSuccess,
    },
    {
      path: routes.workspaceAccountsBulkError({ workspaceId: ':workspaceId' }),
      element: bulkError,
      children: [
        {
          path: routes.workspaceAccountsBulkErrorAccount({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
          }),
          element: bulkErrorAccount,
        },
      ],
    },
  ],
};

export default workspaceAccountsPage;
