import { z } from 'zod';
import { workspaceStrategySchema } from './workspaceStrategySchema';

const workspaceSettingsStrategySchema = workspaceStrategySchema.omit({
  buyin_bb: true,
  rebuy_below_bb: true,
  rebuy_below_bb_straddle_table: true,
  stop_lose_bb: true,
  stop_win_bb: true,
  stop_lose_table_bb: true,
  stop_win_table_bb: true,
  max_swing_bb: true,
  effective_stack_limit_bb: true,
  max_stack_size_bb: true,
  mtt_max_rebuy: true,
});

export type WorkspaceSettingsStrategySchema = z.infer<typeof workspaceSettingsStrategySchema>;

export { workspaceSettingsStrategySchema };
