import { useQuery } from '@tanstack/react-query';
import { loadGlobalAccountStrategy } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import AccountStrategy from '../account/AccountStrategy';

interface Props {
  account: GlobalAccountSchema;
}

const GlobalAccountDetailStrategyTab = (props: Props) => {
  const { account } = props;

  const { id: accountId } = account;

  const queryKey = [queryKeys.globalAccountStrategy({ accountId })];
  const queryFn = () => loadGlobalAccountStrategy({ accountId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return <AccountStrategy disabled data={data} />;
};

export default GlobalAccountDetailStrategyTab;
