import api from '../../services/api';
import globalBonusSchema, { GlobalBonusSchema } from '../schemas/globalBonusSchema';

type Schema = GlobalBonusSchema;

interface Payload {
  deal_id: Schema['deal']['id'];
  type: Schema['type'];
  amount: Schema['amount'];
  currency: Schema['currency'];
  reconcile: Schema['reconcile'];
  description: Schema['description'];
  date: Schema['date'];
}

interface Args {
  payload: Payload;
}

const createGlobalBonus = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/bonus', payload);

  return globalBonusSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalBonus;
