import clsx from 'clsx';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import PageAddonNewCollapseControls from './PageAddonNewCollapseControls';
import PageAddonNewCollapseField from './PageAddonNewCollapseField';
import PageAddonNewCollapseGroup from './PageAddonNewCollapseGroup';
import styles from './styles.module.scss';

interface Props {
  label: string;
  children: ReactNode;
  open: boolean;
  onToggle: (value: boolean) => void;
  onCreate?: () => void;
  controls?: ReactNode;
}

const PageAddonNewCollapse = (props: Props) => {
  const { label, children, controls, onCreate, open, onToggle } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <button type="button" onClick={() => onToggle(!open)}>
          <div className={clsx(styles.label, open && styles.open)}>
            {icon('chevronDown', 16)}
            <span>{label}</span>
          </div>
        </button>
        <div className={clsx(styles.controls, open && styles.open)}>{controls}</div>
      </div>
      {open && (
        <div className={styles.content}>
          {children}
          {onCreate && (
            <Button size="small" variant="primary" onClick={onCreate} className={styles.create}>
              {t('common.create')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

PageAddonNewCollapse.Group = PageAddonNewCollapseGroup;
PageAddonNewCollapse.Field = PageAddonNewCollapseField;
PageAddonNewCollapse.Controls = PageAddonNewCollapseControls;

export default PageAddonNewCollapse;
