import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Form from '../../../components/Form';
import PageAddon from '../../../components/PageAddon';
import SubmitButton from '../../../components/SubmitButton';
import useForm from '../useForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  onCreate: (args: { accountId: WorkspaceAccountSchema['id'] }) => void;
}

const WorkspaceAccountForm = (props: Props) => {
  const { workspaceId, onClose, onCreate } = props;

  const { t } = useTranslation();

  const { form, fields, onSubmit } = useForm({ workspaceId, onCreate });

  return (
    <PageAddon title={t('common.create_account')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.room()}
        {fields.login}
        {fields.password}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceAccountForm;
