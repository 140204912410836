import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalAccountsFilterValues } from '../../api';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../utils/trainer';
import { filterHasNextSession, PILOT_STATUS_LABELS } from '../account/helpers';

interface Fields {
  maintenance_in: string[];
  stage_name_in: string[];
  room_in: string[];
  club_in: string[];
  game_types_in: string[];
  comparative_limits_in: string[];
  max_tables_ge: string;
  max_tables_le: string;
  pilot_status_in: string[];
  user_in: string[];
  bot_settings_in: string[];
  has_next_session: string;
  last_online_ge: string;
  last_online_le: string;
  last_played_ge: string;
  last_played_le: string;
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
}

interface Props {
  total?: number;
}

const GlobalAccountFilter = (props: Props) => {
  const { total } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.globalAccountsFilterValues, urlParams];
  const queryFn = () => loadGlobalAccountsFilterValues(urlParams);

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    maintenance_in: 'multi',
    stage_name_in: 'multi',
    room_in: 'multi',
    club_in: 'multi',
    game_types_in: 'multi',
    comparative_limits_in: 'multi',
    max_tables_ge: 'single',
    max_tables_le: 'single',
    pilot_status_in: 'multi',
    user_in: 'multi',
    bot_settings_in: 'multi',
    has_next_session: 'single',
    last_online_ge: 'single',
    last_online_le: 'single',
    last_played_ge: 'single',
    last_played_le: 'single',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
  });

  const { values, setValue, clearValue } = filter;

  const {
    maintenance_in,
    stage_name_in,
    room_in,
    club_in,
    game_types_in,
    comparative_limits_in,
    max_tables_ge,
    max_tables_le,
    pilot_status_in,
    user_in,
    bot_settings_in,
    has_next_session,
    last_online_ge,
    last_online_le,
    last_played_ge,
    last_played_le,
    updated_ge,
    updated_le,
    created_ge,
    created_le,
  } = values;

  const filterProps = { total, filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const {
    maintenance,
    stage_name,
    room,
    clubs,
    game_types,
    comparative_limits,
    max_tables,
    pilot_status,
    user,
    bot_settings,
  } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {maintenance.length !== 0 && (
          <Filter.Collapse
            label={t('common.workspace')}
            count={maintenance_in.length}
            onClear={clearValue('maintenance_in')}
          >
            <Filter.MultiCheckbox
              config={maintenance.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('maintenance_in'),
                checked: maintenance_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {stage_name.length !== 0 && (
          <Filter.Collapse
            label={t('common.stage')}
            count={stage_name_in.length}
            onClear={clearValue('stage_name_in')}
          >
            <Filter.MultiCheckbox
              config={stage_name.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('stage_name_in'),
                checked: stage_name_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {room.length !== 0 && (
          <Filter.Collapse
            label={t('common.poker_room')}
            count={room_in.length}
            onClear={clearValue('room_in')}
          >
            <Filter.MultiCheckbox
              config={room.map((item) => ({
                value: String(item),
                label: TRAINER_POKER_ROOM_LABELS[item],
                onChange: setValue('room_in'),
                checked: room_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {clubs.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={club_in.length}
            onClear={clearValue('club_in')}
          >
            <Filter.MultiCheckbox
              config={clubs.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('club_in'),
                checked: club_in.includes(item.id),
                addon: item.code,
              }))}
              searchKeys={['addon']}
            />
          </Filter.Collapse>
        )}
        {game_types.length !== 0 && (
          <Filter.Collapse
            label={t('common.game_type')}
            count={game_types_in.length}
            onClear={clearValue('game_types_in')}
          >
            <Filter.MultiCheckbox
              config={game_types.map((item) => ({
                value: String(item),
                label: TRAINER_GAME_TYPE_LABELS[item],
                onChange: setValue('game_types_in'),
                checked: game_types_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {comparative_limits.length !== 0 && (
          <Filter.Collapse
            label={t('common.limit')}
            count={comparative_limits_in.length}
            onClear={clearValue('comparative_limits_in')}
          >
            <Filter.MultiCheckbox
              config={comparative_limits.map((item) => ({
                value: String(item),
                label: TRAINER_LIMITS_LABELS[item],
                onChange: setValue('comparative_limits_in'),
                checked: comparative_limits_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.RangeInput
          label={t('common.max_tables')}
          step={1}
          min={max_tables.min}
          max={max_tables.max}
          valueMin={max_tables_ge}
          valueMax={max_tables_le}
          fieldMin="max_tables_ge"
          fieldMax="max_tables_le"
        />
        {pilot_status.length !== 0 && (
          <Filter.Collapse
            label={t('common.pilot')}
            count={pilot_status_in.length}
            onClear={clearValue('pilot_status_in')}
          >
            <Filter.MultiCheckbox
              config={pilot_status.map((item) => ({
                value: String(item),
                label: PILOT_STATUS_LABELS[item],
                onChange: setValue('pilot_status_in'),
                checked: pilot_status_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {user.length !== 0 && (
          <Filter.Collapse
            label={t('common.assign_to')}
            count={user_in.length}
            onClear={clearValue('user_in')}
          >
            <Filter.MultiCheckbox
              config={user.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('user_in'),
                checked: user_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {bot_settings.length !== 0 && (
          <Filter.Collapse
            label={t('common.bot_settings')}
            count={bot_settings_in.length}
            onClear={clearValue('bot_settings_in')}
          >
            <Filter.MultiCheckbox
              config={bot_settings.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('bot_settings_in'),
                checked: bot_settings_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.has_session')}
          {...(has_next_session && { onClear: clearValue('has_next_session') })}
        >
          <Filter.Radio
            value={has_next_session}
            onChange={setValue('has_next_session')}
            config={filterHasNextSession}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.last_online')}
          {...((last_online_ge || last_online_le) && {
            onClear: clearValue(['last_online_ge', 'last_online_le']),
          })}
        >
          <Filter.Date
            from={{ value: last_online_ge, onChange: setValue('last_online_ge') }}
            to={{ value: last_online_le, onChange: setValue('last_online_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.last_played')}
          {...((last_played_ge || last_played_le) && {
            onClear: clearValue(['last_played_ge', 'last_played_le']),
          })}
        >
          <Filter.Date
            from={{ value: last_played_ge, onChange: setValue('last_played_ge') }}
            to={{ value: last_played_le, onChange: setValue('last_played_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
    </Filter>
  );
};

export default GlobalAccountFilter;
