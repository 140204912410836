import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  finishGlobalAccountVerification,
  submitGlobalAccountForVerification,
} from '../../api/global-account-verification';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { TRAINER_POKER_ROOM } from '../../api/schemas/trainerPokerRoomSchema';
import { TRAINER_TECH } from '../../api/schemas/trainerTechSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';
import { getScheduleTooltip } from '../account/helpers';
import {
  useCloseGlobalAccountTablesPokerRoomMutation,
  useResetGlobalAccountTablesCacheMutation,
  useSwitchOffGlobalAccountPilotMutation,
} from './mutations';

interface Args {
  data: GlobalAccountSchema;
  credentials: () => void;
  verificationCode: () => void;
}

const useDropdown = (args: Args) => {
  const { data, credentials, verificationCode } = args;

  const { id, mga_id, room, maintenance, comparative_limits, is_online, on_verification } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const resetCache = useResetGlobalAccountTablesCacheMutation();
  const closeRoom = useCloseGlobalAccountTablesPokerRoomMutation();
  const switchOffPilot = useSwitchOffGlobalAccountPilotMutation();

  const [view, setView] = useState<'default' | 'verification'>('default');

  const setDefaultView = () => setView('default');

  const getResetCacheTooltip = () => {
    if (!can.lobby.resetCache) return t('common.no_permission');
    if (!is_online) return t('common.account_is_offline');

    return null;
  };

  const getClosePokerRoomTooltip = () => {
    if (!can.lobby.closePokerRoom) return t('common.no_permission');
    if (!is_online) return t('common.account_is_offline');

    return null;
  };

  return {
    title: mga_id,
    view,
    setDefaultView,
    options: {
      schedule: {
        icon: icon('calendar', 20),
        label: t('common.schedule'),
        onClick: () => navigate(routes.globalAccountSchedule({ accountId: id })),
        disabled: !can.session.read || !comparative_limits.length,
        tooltip: getScheduleTooltip({ account: data }),
      },
      copy: presets.copyId(id),
      resetCache: {
        icon: icon('broom', 20),
        label: t('common.reset_cache'),
        disabled: !can.lobby.resetCache || !is_online,
        tooltip: getResetCacheTooltip(),
        onClick: () => resetCache.mutate({ accountId: id }),
      },
      credentials: {
        icon: icon('password', 24),
        label: t('common.credentials'),
        onClick: credentials,
        disabled: !can.account.passwordRead,
        ...(!can.account.passwordRead && { tooltip: t('common.no_permission') }),
      },
      divider: { divider: true },
      closePokerRoom: {
        danger: true,
        confirm: true,
        icon: icon('logOut', 20),
        label: t('common.close_poker_room'),
        confirmText: t('common.close'),
        onClick: () => closeRoom.mutate({ accountId: id }),
        disabled: !can.lobby.closePokerRoom || !is_online,
        tooltip: getClosePokerRoomTooltip(),
      },
      terminate: {
        danger: true,
        confirm: true,
        icon: icon('crossCircle', 20),
        label: t('common.terminate'),
        confirmText: t('common.terminate'),
        onClick: () => switchOffPilot.mutate({ accountId: id }),
        disabled: !can.account.switchPilotOff,
        ...(!can.account.switchPilotOff && { tooltip: t('common.no_permission') }),
      },
      ...(!on_verification && {
        sumbitVerification: {
          icon: icon('playCircle', 20),
          label: t('common.sumbit_to_verification'),
          onClick: async () => {
            await submitGlobalAccountForVerification({ accountId: id });

            notify('success', { title: t('common.action_completed') });
          },
          disabled: !can.account.verify,
          ...(!can.account.verify && { tooltip: t('common.no_permission') }),
        },
      }),
      ...(on_verification && {
        finishVerification: {
          icon: icon('pauseCircle', 20),
          label: t('common.finish_verification'),
          onClick: async () => {
            await finishGlobalAccountVerification({ accountId: id });

            notify('success', { title: t('common.action_completed') });
          },
          disabled: !can.account.verify,
          ...(!can.account.verify && { tooltip: t('common.no_permission') }),
        },
      }),
      ...(on_verification && {
        showVerificationCode: {
          icon: icon('key', 20),
          label: t('common.show_verification_code'),
          onClick: verificationCode,
          disabled: !can.account.verify,
          ...(!can.account.verify && { tooltip: t('common.no_permission') }),
        },
      }),
      ...(maintenance.trainer_tech.includes(TRAINER_TECH.POKERRRR2_DROP_MANUAL) &&
        room === TRAINER_POKER_ROOM.POKERRRR2 && {
          verification: {
            ...presets.more,
            icon: icon('checkCircle', 20),
            label: t('common.verification'),
            onClick: () => setView('verification'),
          },
        }),
      back: {
        ...presets.back(),
        onClick: setDefaultView,
      },
    },
  };
};

export default useDropdown;
