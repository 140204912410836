import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalArea } from '../../api';
import { AreaSchema } from '../../api/schemas/areaSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  areaId: AreaSchema['id'];
  children: (args: { data: AreaSchema }) => ReactElement;
}

const GlobalAreaLoader = (props: Props) => {
  const { areaId, children } = props;

  const queryKey = [queryKeys.globalArea({ areaId })];
  const queryFn = () => loadGlobalArea({ areaId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<AreaSchema>({
    domain: 'Area',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' || message.action === 'Deleted') &&
        message.payload.id === areaId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalAreaLoader;
