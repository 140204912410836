import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { workspaceSettingsStrategySchema } from '../schemas/workspaceSettingsStrategySchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceStrategy = async (args: Args) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/strategy-profile-settings`
  );

  return workspaceSettingsStrategySchema.parse(response.data);
};

export default loadWorkspaceStrategy;
