import { GlobalExpenseSchema } from '../../api/schemas/globalExpenseSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';
import { formatAbsoluteDate } from '../../utils/date';

interface Props {
  data: GlobalExpenseSchema;
}

const GlobalExpenseDetailLink = (props: Props) => {
  const { data } = props;

  const { id, date } = data;

  return (
    <PageDetailLink label={formatAbsoluteDate(date)} to={routes.globalExpense({ expenseId: id })} />
  );
};

export default GlobalExpenseDetailLink;
