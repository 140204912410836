import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import FadingText from '../../../components/FadingText';
import Link from '../../../components/Link';
import PageAddon from '../../../components/PageAddon';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import { useWorkspaceAccountsContext } from '../WorkspaceAccountsContext';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountBulkActionsDetail = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { total, errors, setErrors, setTotal } = useWorkspaceAccountsContext();

  const onClose = () => {
    navigate(routes.workspaceAccounts({ workspaceId }));
    setTotal(0);
    setErrors([]);
  };

  if (!total) return null;

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={t('common.bulk_edit')} onClose={onClose}>
        {errors.length ? (
          <div className={styles.container}>
            <Alert
              type="error"
              title={t('sentences.accounts_have_an_update_error', {
                count: errors.length,
                total,
              })}
              closable={false}
            />
            <div className={styles.wrapper}>
              <PageAddon.Heading>{t('common.failed_accounts')}:</PageAddon.Heading>
              <div className={styles.buttons}>
                {errors.map((item) => (
                  <Link
                    key={item.id}
                    to={routes.workspaceAccountsBulkErrorAccount({
                      workspaceId,
                      accountId: item.id,
                    })}
                  >
                    <FadingText text={item.login} className={styles.text} />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <Alert
            type="success"
            title={t('sentences.accounts_have_been_updated', { count: total })}
            closable={false}
          />
        )}
        <PageAddon.Controls>
          <Button onClick={onClose}>{t('common.close')}</Button>
        </PageAddon.Controls>
      </PageAddon>
    </PageAddon.Group>
  );
};

export default WorkspaceAccountBulkActionsDetail;
