import { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { t } from '../i18n';
import icon from '../static/icons';

type Type = 'success' | 'warning' | 'info' | 'error';

interface CommonConfig {
  title: ReactNode;
  description?: string;
}

interface DefaultConfig {
  large?: never;
  subTitle?: never;
}

interface LargeConfig {
  large: true;
  subTitle?: ReactNode;
}

type Config = CommonConfig & (DefaultConfig | LargeConfig);

export const getMessage = (type: Type, config: Config) => {
  const { large = false, title, subTitle, description } = config;

  const getIcon = () => {
    if (type === 'success') return icon('checkCircle', 20);
    if (type === 'warning') return icon('alertTriangle', 20);
    if (type === 'info') return icon('alertCircle', 20);

    return icon('xCircle', 20);
  };

  return (
    <div data-toastify-body>
      <div data-toastify-icon>{getIcon()}</div>
      <div data-toastify-info>
        {!large && <span data-toastify-subtitle>{String(t(`common.${type}`))}</span>}
        {subTitle && <span data-toastify-large-subtitle>{subTitle}</span>}
        <div>
          <span data-toastify-title>{title}</span>
          {description && <span data-toastify-description>{description}</span>}
        </div>
      </div>
    </div>
  );
};

const notify = (type: Type, config: Config) => {
  const { large = false } = config;

  const message = getMessage(type, config);

  if (type === 'success') {
    toast.success(message, {
      ...(large && { className: 'Toastify__toast--large' }),
    });
  }

  if (type === 'warning') {
    toast.warning(message, {
      ...(large && { className: 'Toastify__toast--large' }),
    });
  }

  if (type === 'info') {
    toast.info(message, {
      ...(large && { className: 'Toastify__toast--large' }),
    });
  }

  if (type === 'error') {
    toast.error(message, {
      ...(large && { className: 'Toastify__toast--large' }),
    });
  }
};

export default notify;
