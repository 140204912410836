import { QueryKeys } from './types';

const queryKeys: QueryKeys = {
  currentUser: 'currentUser',
  permissions: 'permissions',

  notifications: 'notifications',
  notificationsFilterValues: 'notifications-filter-values',
  notificationsCount: 'notifications-count',
  notificationsSettings: 'notifications-settings',
  notificationsSetting: ({ workspaceId }) => `notifications-setting-${workspaceId}`,

  workspaces: 'workspaces',
  workspace: ({ workspaceId }) => `workspace-${workspaceId}`,
  workspaceCounters: ({ workspaceId }) => `workspace-${workspaceId}-counters`,
  workspaceBalance: ({ workspaceId }) => `workspace-${workspaceId}-balance`,
  workspaceAddress: ({ workspaceId }) => `workspace-${workspaceId}-address`,
  workspaceStrategy: ({ workspaceId }) => `workspace-${workspaceId}-strategy`,

  workspaceDashboardAccounts: ({ workspaceId }) => `workspace-${workspaceId}-dashboard-accounts`,
  workspaceDashboardAccountsTotal: ({ workspaceId }) =>
    `workspace-${workspaceId}-dashboard-accounts/total`,
  workspaceDashboardAccountsCount: ({ workspaceId }) =>
    `workspace-${workspaceId}-dashboard-accounts/count`,
  workspaceDashboardChannelsCount: ({ workspaceId }) =>
    `workspace-${workspaceId}-dashboard-channels/count`,
  workspaceDashboardIdentitiesCount: ({ workspaceId }) =>
    `workspace-${workspaceId}-dashboard-identities/count`,

  workspaceUsers: ({ workspaceId }) => `workspace-${workspaceId}-users`,
  workspaceUser: ({ workspaceId, userId }) => `workspace-${workspaceId}-user-${userId}`,

  workspaceAccounts: ({ workspaceId }) => `workspace-${workspaceId}-accounts`,
  workspaceAccountsFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-accounts-filter-values`,
  workspaceAccountsBuyCountryList: ({ workspaceId }) =>
    `workspace-${workspaceId}-accounts-buy-country-list`,
  workspaceAccount: ({ workspaceId, accountId }) => `workspace-${workspaceId}-account-${accountId}`,
  workspaceAccountHistory: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-history`,
  workspaceAccountHistoryFilterValues: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-history-filter-values`,
  workspaceAccountNotes: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-notes`,
  workspaceAccountStrategy: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-strategy`,
  workspaceAccountRoomConfig: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-room-config`,
  workspaceAccountBalance: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-balance`,
  workspaceAccountTransactions: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-transactions`,
  workspaceAccountVerificationCode: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-verification-code`,

  workspaceAccountSchedule: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-${accountId}-schedule`,
  workspaceAccountScheduleSession: ({ workspaceId, accountId, sessionId }) =>
    `workspace-${workspaceId}-account-${accountId}-schedule-session-${sessionId}`,

  workspaceAccountTables: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-tables-${accountId}`,
  workspaceAccountTablesSearch: ({ workspaceId, accountId }) =>
    `workspace-${workspaceId}-account-tables-search-${accountId}`,

  workspaceAccountsStages: ({ workspaceId }) => `workspace-${workspaceId}-accounts-stages`,

  workspaceSessions: ({ workspaceId }) => `workspace-${workspaceId}-sessions`,
  workspaceSessionsFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-sessions-filter-values`,
  workspaceSession: ({ workspaceId, sessionId }) =>
    `/workspace/${workspaceId}/session/${sessionId}`,

  workspaceChannels: ({ workspaceId }) => `workspace-${workspaceId}-channels`,
  workspaceChannelsFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-channels-filter-values`,
  workspaceChannel: ({ workspaceId, channelId }) => `workspace-${workspaceId}-channel-${channelId}`,

  workspaceClubManagers: ({ workspaceId }) => `workspace-${workspaceId}-club-managers`,
  workspaceClubManagersFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-club-managers-filter-values`,
  workspaceClubManager: ({ workspaceId, managerId }) =>
    `workspace-${workspaceId}-club-manager-${managerId}`,

  workspaceClubMembers: ({ workspaceId }) => `workspace-${workspaceId}-club-members`,
  workspaceClubMembersFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-club-members-filter-values`,
  workspaceClubMember: ({ workspaceId, memberId }) =>
    `workspace-${workspaceId}-club-member-${memberId}`,

  workspaceClubWhitelist: ({ workspaceId }) => `workspace-${workspaceId}-club-whitelist`,
  workspaceClubWhitelistMember: ({ workspaceId, whitelistMemberId }) =>
    `workspace-${workspaceId}-club-whitelist-${whitelistMemberId}`,

  workspaceClubTransactions: ({ workspaceId }) => `workspace-${workspaceId}-club-transactions`,
  workspaceClubTransactionsFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-club-transactions-filter-values`,
  workspaceClubTransaction: ({ workspaceId, transactionId }) =>
    `workspace-${workspaceId}-club-transaction-${transactionId}`,

  workspaceIdentities: ({ workspaceId }) => `workspace-${workspaceId}-identities`,
  workspaceIdentitiesFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-identities-filter-values`,
  workspaceIdentity: ({ workspaceId, identityId }) =>
    `workspace-${workspaceId}-identity-${identityId}`,

  workspaceMarketIdentities: ({ workspaceId }) => `workspace-${workspaceId}-market-identities`,
  workspaceMarketIdentitiesFilterValues: ({ workspaceId }) =>
    `workspace-${workspaceId}-market-identities-filter-values`,
  workspaceMarketIdentity: ({ workspaceId, identityId }) =>
    `workspace-${workspaceId}-market-identity-${identityId}`,

  workspaceOrders: ({ workspaceId }) => `workspace-${workspaceId}-orders`,
  workspaceOrdersFilterValues: ({ workspaceId }) => `workspace-${workspaceId}-orders-filter-values`,
  workspaceOrder: ({ workspaceId, orderId }) => `workspace-${workspaceId}-order-${orderId}`,
  workspaceOrderTransactions: ({ workspaceId, orderId }) =>
    `workspace-${workspaceId}-order-${orderId}-transactions`,

  workspaceDeals: ({ workspaceId }) => `workspace-${workspaceId}-deals`,
  workspaceDeal: ({ workspaceId, dealId }) => `workspace-${workspaceId}-deal-${dealId}`,

  workspaceSeatControlRules: ({ workspaceId }) => `workspace-${workspaceId}-seat-control-rules`,

  workspaceWallets: ({ workspaceId }) => `workspace-${workspaceId}-wallets`,
  workspaceWallet: ({ workspaceId, walletId }) => `workspace-${workspaceId}-wallet-${walletId}`,

  workspaceTokens: ({ workspaceId }) => `workspace-${workspaceId}-tokens`,
  workspaceToken: ({ workspaceId, tokenId }) => `workspace-${workspaceId}-token-${tokenId}`,

  workspaceTags: ({ workspaceId }) => `workspace-${workspaceId}-tags`,
  workspaceTag: ({ workspaceId, tagId }) => `workspace-${workspaceId}-tag-${tagId}`,

  globalWorkspaces: 'global-workspaces',
  globalWorkspacesFilterValues: 'global-workspaces-filter-values',
  globalWorkspace: ({ workspaceId }) => `global-workspace-${workspaceId}`,
  globalWorkspaceBalance: ({ workspaceId }) => `global-workspace-${workspaceId}-balance`,
  globalWorkspaceAddress: ({ workspaceId }) => `global-workspace-${workspaceId}-address`,
  globalWorkspaceUsers: ({ workspaceId }) => `global-workspace-${workspaceId}-users`,
  globalWorkspaceClubs: ({ workspaceId }) => `global-workspace-${workspaceId}-clubs`,

  globalOrders: 'global-orders',
  globalOrdersFilterValues: 'global-orders-filter-values',
  globalOrder: ({ orderId }) => `global-order-${orderId}`,
  globalOrderTransactions: ({ orderId }) => `global-order-${orderId}-transactions`,

  globalDeals: 'global-deals',
  globalDealsFilterValues: 'global-deals-filter-values',
  globalDeal: ({ dealId }) => `global-deal-${dealId}`,
  globalDealNotes: ({ dealId }) => `global-deal-${dealId}-notes`,

  globalAgents: 'global-agents',
  globalAgent: ({ agentId }) => `global-agent-${agentId}`,
  globalAgentNotes: ({ agentId }) => `global-agent-${agentId}-notes`,

  globalBonuses: 'global-bonuses',
  globalBonusesFilterValues: 'global-bonuses-filter-values',
  globalBonus: ({ bonusId }) => `global-bonus-${bonusId}`,

  globalExpenses: 'global-expenses',
  globalExpensesFilterValues: 'global-expenses-filter-values',
  globalExpense: ({ expenseId }) => `global-expense-${expenseId}`,

  globalSeatControlRules: 'global-seat-control-rules',
  globalSeatControlRulesFilterValues: 'global-seat-control-rules-filter-values',
  globalSeatControlRule: ({ seatControlRuleId }) => `global-seat-control-rule-${seatControlRuleId}`,

  globalUsers: 'global-users',
  globalUsersFilterValues: 'global-users-filter-values',
  globalUser: ({ userId }) => `global-user-${userId}`,

  globalRoles: 'global-roles',
  globalRole: ({ roleId }) => `global-role-${roleId}`,

  globalAccounts: 'global-accounts',
  globalAccountsFilterValues: 'global-accounts-filter-values',
  globalAccount: ({ accountId }) => `global-account-${accountId}`,
  globalAccountStrategy: ({ accountId }) => `global-account-${accountId}-strategy`,
  globalAccountRoomConfig: ({ accountId }) => `global-account-${accountId}-room-config`,
  globalAccountBalance: ({ accountId }) => `global-account-${accountId}-balance`,
  globalAccountTransactions: ({ accountId }) => `global-account-${accountId}-transactions`,
  globalAccountPassword: ({ accountId }) => `global-account-${accountId}-password`,
  globalAccountVerificationCode: ({ accountId }) => `global-account-${accountId}-verification-code`,

  globalAccountSchedule: ({ accountId }) => `global-account-${accountId}-schedule`,
  globalAccountScheduleSession: ({ accountId, sessionId }) =>
    `account-${accountId}-schedule-session-${sessionId}`,

  globalSessions: 'global-sessions',
  globalSessionsFilterValues: 'global-sessions-filter-values',
  globalSession: ({ sessionId }) => `global-session-${sessionId}`,

  globalAccountTables: ({ accountId }) => `global-account-tables-${accountId}`,
  globalAccountTablesSearch: ({ accountId }) => `global-account-tables-search-${accountId}`,

  globalLobby: 'global-lobby',
  globalLobbyTable: ({ tableId }) => `global-lobby-${tableId}`,
  globalLobbyTablePlayers: ({ tableId }) => `global-lobby-${tableId}-players`,

  globalBotSettings: `bot-settings`,
  globalBotSettingsFilterValues: `bot-settings-filter-values`,
  globalBotSetting: ({ botSettingId }) => `global-bot-setting-${botSettingId}`,

  globalBotSettingsRules: `bot-settings-rules`,
  globalBotSettingsRulesFilterValues: `bot-settings-rules-filter-values`,
  globalBotSettingRule: ({ botSettingRuleId }) => `global-bot-setting-rule-${botSettingRuleId}`,

  globalClubs: 'global-clubs',
  globalClubsFilterValues: 'global-clubs-filter-values',
  globalClub: ({ clubId }) => `global-club-${clubId}`,
  globalClubNotes: ({ clubId }) => `global-club-${clubId}-notes`,
  globalClubWorkspaces: ({ clubId }) => `global-club-${clubId}-workspaces`,
  globalClubAnnouncements: ({ clubId }) => `global-club-${clubId}-announcements`,

  globalLeagues: 'global-leagues',
  globalLeague: ({ leagueId }) => `global-league-${leagueId}`,

  globalSuperLeagues: 'global-super-leagues',
  globalSuperLeague: ({ superLeagueId }) => `global-super-league-${superLeagueId}`,

  globalAreas: 'global-areas',
  globalArea: ({ areaId }) => `global-area-${areaId}`,
};

export default queryKeys;
