import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import clsx from 'clsx';
import { ChangeEvent, useCallback, useState, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { NumericFormat, PatternFormat, PatternFormatProps } from 'react-number-format';
import { getFontShorthand, measureText } from 'update-input-width';
import { time } from '../../utils/validations';
import Error from '../Error';
import Label, { getLabelProps } from '../Label';
import InputButton from './InputButton';
import InputClearButton from './InputClearButton';
import InputQuick from './InputQuick';
import styles from './styles.module.scss';
import { Props } from './types';

const Input = (props: Props) => {
  const {
    ref,
    type,
    icon,
    label,
    error,
    placeholder,
    addon,
    extra,
    size = 'large',
    className: customClassName,
    children,
    setInputWidth,
    format,
    patternFormat,
    autoComplete = 'off',
    errorVariant = 'default',
    allow,
    onChange,
    ...rest
  } = props;

  const className = clsx(styles.input, {
    [styles[size]]: true,
    [styles.error]: error,
    [styles.withIcon]: icon,
    ...(errorVariant === 'popover' && { [styles.errorInputPopover]: true }),
    ...(customClassName && { [customClassName]: true }),
  });

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: true,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift({ padding: 4 }), arrow({ element: arrowRef })],
  });

  const [width, setWidth] = useState<number | null>(null);

  const callbackRef = useCallback(
    (node: HTMLInputElement) => {
      if (setInputWidth && node) {
        const contentWidth = measureText(node.value || node.placeholder, getFontShorthand(node));

        if (contentWidth) {
          setWidth(setInputWidth(contentWidth));
        }
      }
    },
    [setInputWidth]
  );

  const mergedRefs = mergeRefs([
    ref,
    callbackRef,
    ...(errorVariant === 'popover' ? [refs.setReference] : []),
  ]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (allow) {
        const eventValue = event.target.value;

        if (allow(eventValue) || eventValue === '') {
          return onChange(event);
        }

        return null;
      }

      return onChange(event);
    }

    return event;
  };

  const getContent = () => {
    if (format) {
      const {
        thousandSeparator = ',',
        decimalScale = 0,
        allowNegative = false,
        prefix,
        onValueChange,
      } = format;

      return (
        <NumericFormat
          data-input
          autoComplete={autoComplete}
          allowNegative={allowNegative}
          getInputRef={mergedRefs}
          valueIsNumericString
          placeholder={placeholder}
          decimalScale={decimalScale}
          thousandSeparator={thousandSeparator}
          allowLeadingZeros={false}
          prefix={prefix}
          {...(onValueChange && { onValueChange: (values) => onValueChange(values.value) })}
          {...rest}
        />
      );
    }

    if (patternFormat === 'time') {
      const patternTimeProps: PatternFormatProps = {
        format: '##:##',
        mask: ['h', 'h', 'm', 'm'],
        allowEmptyFormatting: false,
        isAllowed: ({ value }) => time(value),
      };

      return (
        <PatternFormat
          data-input
          getInputRef={mergedRefs}
          autoComplete={autoComplete}
          placeholder={placeholder}
          {...patternTimeProps}
          {...(width && { style: { width: `${width}px` } })}
          {...rest}
        />
      );
    }

    return (
      <input
        data-input
        type={type}
        ref={mergedRefs}
        autoComplete={autoComplete}
        placeholder={placeholder}
        onChange={handleChange}
        {...(width && { style: { width: `${width}px` } })}
        {...rest}
      />
    );
  };

  return (
    <div className={styles.container}>
      {(label || addon) && <Label addon={addon} {...getLabelProps(label)} />}
      <div className={className}>
        <div className={styles.icon}>{icon}</div>
        {getContent()}
        {extra && (
          <div data-input-extra className={styles.extra}>
            {extra}
          </div>
        )}
        {children}
      </div>
      {errorVariant === 'default' && <Error error={error} />}
      {errorVariant === 'popover' && error && (
        <div ref={refs.setFloating} style={floatingStyles} className={styles.errorPopover}>
          <FloatingArrow ref={arrowRef} context={context} tipRadius={1} className={styles.arrow} />
          {error}
        </div>
      )}
    </div>
  );
};

Input.Button = InputButton;
Input.ClearButton = InputClearButton;
Input.Quick = InputQuick;

export default Input;
