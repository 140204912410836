import Badge from '../../components/Badge';
import { STATUS_COLORS, STATUS_LABELS } from './helpers';
import { CommonDealSchema } from './types';

interface Props {
  data: CommonDealSchema;
}

const DealStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  return <Badge color={STATUS_COLORS[status]}>{STATUS_LABELS[status]}</Badge>;
};

export default DealStatus;
