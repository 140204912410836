import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { loadWorkspaceDeals } from '../../api/workspace-deal';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { cardFields, columns } from '../../features/deal/config';
import DealDetailLink from '../../features/deal/DealDetailLink';
import { sortings } from '../../features/deal/helpers';
import { GlobalClubProvider } from '../../features/global-club/GlobalClubContext';
import useTableColumnResize from '../../features/workspace-deal/useTableColumnResize';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';

type Params = RouteParams<typeof routes.workspaceDeal>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceDealsPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();
  const { search } = urlParams;
  const { dealId } = useParams<Params>();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();

  const queryKey = [queryKeys.workspaceDeals({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceDeals({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const columnResize = useTableColumnResize();

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <GlobalClubProvider>
      <Page heading={t('common.deals')} addon={<Outlet />}>
        {isPhone && <PageTop sorting={sortings} />}
        {(isDesktop || isTablet) && (
          <Table
            data={data}
            search={search}
            {...getTableProps(query)}
            {...columnResize}
            columns={[
              columns.code((values) => routes.workspaceDeal({ workspaceId, dealId: values.id })),
              columns.agent_name(() => ''),
              columns.poker_room,
              columns.club,
              columns.area_id,
              columns.holder_username,
              columns.role,
              columns.credit,
              columns.protection,
              columns.chip_rate,
              columns.rake_back,
              columns.action,
              columns.rev_share,
              columns.win_share,
              columns.rebate,
              columns.agent_fee,
              columns.withdraw_fee,
            ]}
            renderRow={(row, index) => (
              <Table.Row
                active={row.data.id === dealId}
                danger={row.data.is_deleted}
                {...checkIsInView(index)}
                {...row}
              />
            )}
          />
        )}
        {isPhone && (
          <Card.Group>
            {data.map((item, index) => (
              <Card key={item.id} danger={item.is_deleted} {...checkIsInView(index)}>
                <Card.Top>
                  <DealDetailLink
                    data={item}
                    to={routes.workspaceDeal({ workspaceId, dealId: item.id })}
                  />
                </Card.Top>
                <Card.Field.Group>
                  {cardFields.code(item)}
                  {cardFields.agent_name(item)}
                  {cardFields.poker_room(item)}
                  {cardFields.club(item)}
                  {cardFields.area_id(item)}
                  {cardFields.holder_username(item)}
                  {cardFields.role(item)}
                  {cardFields.credit(item)}
                  {cardFields.protection(item)}
                  {cardFields.chip_rate(item)}
                  {cardFields.rake_back(item)}
                  {cardFields.action(item)}
                  {cardFields.rev_share(item)}
                  {cardFields.win_share(item)}
                  {cardFields.rebate(item)}
                  {cardFields.agent_fee(item)}
                  {cardFields.withdraw_fee(item)}
                  {cardFields.modified(item)}
                  {cardFields.created_on(item)}
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        )}
      </Page>
    </GlobalClubProvider>
  );
};

export default WorkspaceDealsPage;
