import { useQuery } from '@tanstack/react-query';
import { loadGlobalAccount, loadGlobalAccountTables } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { GlobalAccountTableSchema } from '../../api/schemas/globalAccountTableSchema';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { mutateDetailQueryData, updateDetailQueryData } from '../../utils/queryClient';
import { AccountMetrics, TableMetrics } from '../account/types';

export const useGlobalAccountQuery = (args: { accountId: GlobalAccountSchema['id'] }) => {
  const { accountId } = args;

  const queryKey = [queryKeys.globalAccount({ accountId })];
  const queryFn = () => loadGlobalAccount({ accountId });

  useWebSocketMessage<GlobalAccountSchema>({
    domain: 'GlobalAccount',
    onMessage: (message) => {
      if (
        message.payload.id === accountId &&
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'AccountIsOnline' ||
          message.action === 'AccountIsOffline' ||
          message.action === 'BalanceChanged' ||
          message.action === 'PilotSwitchOn' ||
          message.action === 'PilotSwitchOff' ||
          message.action === 'ConfirmPilotStarted' ||
          message.action === 'ConfirmPilotFinished' ||
          message.action === 'VerificationSubmitted' ||
          message.action === 'VerificationFinished')
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  return useQuery({ queryKey, queryFn, meta: { ignoredStatuses: [403] } });
};

export const useGlobalAccountTablesQuery = (id: GlobalAccountSchema['id'], enabled: boolean) => {
  const queryKey = [queryKeys.globalAccountTables({ accountId: id })];
  const queryFn = () => loadGlobalAccountTables({ accountId: id });

  useWebSocketMessage<GlobalAccountTableSchema>({
    domain: 'AccountLobby',
    onMessage: (message) => {
      if (
        (message.action === 'TableAdded' ||
          message.action === 'TableRemoved' ||
          message.action === 'StatsUpdated') &&
        message.payload.account.id === id
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  useWebSocketMessage<AccountMetrics>({
    domain: 'AccountStats',
    onMessage: (message) => {
      if (message.payload.account_id === id) {
        if (message.action === 'Updated') {
          mutateDetailQueryData<GlobalAccountTableSchema>(queryKey, (prevData) => ({
            ...prevData,
            account: { ...prevData.account, metrics: message.payload.metrics },
          }));
        }
      }
    },
  });

  useWebSocketMessage<TableMetrics>({
    domain: 'AccountTableStats',
    onMessage: (message) => {
      if (message.payload.account_id === id) {
        if (message.action === 'Updated') {
          mutateDetailQueryData<GlobalAccountTableSchema>(queryKey, (prevData) => ({
            ...prevData,
            tables: prevData.tables.map((item) => {
              if (item.id === message.payload.table_id) {
                return { ...item, metrics: message.payload.metrics };
              }

              return item;
            }),
          }));
        }
      }
    },
  });

  return useQuery({
    queryKey,
    queryFn,
    enabled,
    meta: { ignoredStatuses: [403] },
  });
};
