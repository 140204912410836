import clsx from 'clsx';
import { Dispatch, ReactNode, Ref, SetStateAction, useState } from 'react';
import icon from '../../static/icons';
import Button from '../Button';
import Link from '../Link';
import CollapseBadge from './CollapseBadge';
import CollapseCaption from './CollapseCaption';
import CollapseGroup from './CollapseGroup';
import CollapseId from './CollapseId';
import CollapseInfoField from './CollapseInfoField';
import CollapseLabelCopy from './CollapseLabelCopy';
import CollapseSecondaryLabel from './CollapseSecondaryLabel';
import styles from './styles.module.scss';

interface Props {
  label: ReactNode;
  to?: string;
  labelAfter?: ReactNode;
  labelBefore?: ReactNode;
  caption?: ReactNode;
  className?: string;
  defaultOpen?: boolean;
  alwaysRenderContent?: boolean;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  extra?: ReactNode;
  children?: ReactNode;
  collapse?: boolean;
  ref?: Ref<HTMLDivElement>;
}

const Collapse = (props: Props) => {
  const {
    label,
    to,
    labelBefore,
    labelAfter,
    caption,
    className,
    defaultOpen = false,
    alwaysRenderContent = false,
    open: passedOpen,
    setOpen: passedSetOpen,
    extra,
    ref,
    children,
    collapse = true,
  } = props;

  let [open, setOpen] = useState(defaultOpen);

  open = passedOpen || open;
  setOpen = passedSetOpen || setOpen;

  const handleClick = () => setOpen((prev) => !prev);

  const renderContent = () => {
    if (alwaysRenderContent) {
      return <div className={clsx(styles.content, open && styles.open)}>{children}</div>;
    }

    if (open) return <div className={clsx(styles.content, styles.open)}>{children}</div>;

    return null;
  };

  return (
    <div ref={ref} className={clsx(styles.container, className)}>
      <div className={styles.top}>
        <div className={styles.wrapperInfo}>
          {labelBefore}
          <div className={styles.info}>
            <div className={styles.label}>
              {to ? (
                <Link to={to} className={styles.labelLink}>
                  {label}
                </Link>
              ) : (
                <span className={styles.labelText}>{label}</span>
              )}
              {labelAfter}
            </div>
            {caption}
          </div>
        </div>
        <div className={styles.controls}>
          {extra}
          {collapse && (
            <div className={styles.button}>
              <Button
                size="small"
                variant="ghost"
                icon={open ? icon('chevronUp', 16) : icon('chevronDown', 16)}
                onClick={handleClick}
                disabled={!children}
              />
            </div>
          )}
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

Collapse.Id = CollapseId;
Collapse.SecondaryLabel = CollapseSecondaryLabel;
Collapse.Badge = CollapseBadge;
Collapse.InfoField = CollapseInfoField;
Collapse.Group = CollapseGroup;
Collapse.Caption = CollapseCaption;
Collapse.LabelCopy = CollapseLabelCopy;

export default Collapse;
