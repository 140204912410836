import { updateWorkspaceAccountStrategy } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageAddon from '../../../components/PageAddon';
import AccountStrategy from '../../account/AccountStrategy';
import { useWorkspaceAccountStrategyQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  account: WorkspaceAccountSchema;
}

const WorkspaceAccountStrategy = (props: Props) => {
  const { workspaceId, account } = props;

  const { id: accountId } = account;

  const { data, isPending } = useWorkspaceAccountStrategyQuery({
    workspaceId,
    accountId,
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return (
    <AccountStrategy
      data={data}
      onUpdate={(payload) =>
        updateWorkspaceAccountStrategy({
          workspaceId,
          accountId,
          payload,
        })
      }
    />
  );
};

export default WorkspaceAccountStrategy;
