import DoubleRowText from '../../components/DoubleRowText';
import FadingText from '../../components/FadingText';
import { CommonDealSchema } from './types';

interface Props {
  data: CommonDealSchema;
}

const DealArea = (props: Props) => {
  const { data } = props;

  const { area_id, area2_id } = data;

  if (!area_id && !area2_id) return '—';

  return (
    <DoubleRowText
      title={<FadingText text={area_id || '—'} />}
      subTitle={<FadingText text={area2_id || '—'} />}
    />
  );
};

export default DealArea;
