import api from '../../services/api';
import { GlobalDealSchema } from '../schemas/deal/globalDealSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = GlobalDealSchema;

interface Payload {
  agent_id?: Schema['agent']['id'];
  poker_room?: Schema['poker_room'];
  club_id?: Schema['club']['id'];
  area_id?: Schema['area_id'];
  area2_id?: Schema['area2_id'];
  holder_user_id?: Exclude<Schema['holder'], null>['id'] | null;
  owner?: Schema['owner'];
  status?: Schema['status'] | null;
  role?: Schema['role'] | null;
  credit?: Schema['credit'] | null;
  protection?: Schema['protection'] | null;
  currency?: Schema['currency'];
  chip_rate?: Schema['chip_rate'];
  rake_back?: Schema['rake_back'];
  action?: Schema['action'];
  rev_share?: Schema['rev_share'];
  win_share?: Schema['win_share'];
  rebate?: Schema['rebate'];
  agent_fee?: Schema['agent_fee'];
  withdraw_fee?: Schema['withdraw_fee'];
  hand_cost?: Schema['hand_cost'];
  fixed_payment?: Schema['fixed_payment'];
  income_cap?: Schema['income_cap'];
  workspace_ids?: Schema['workspaces'][number]['id'][];
  financial_conditions?: Schema['financial_conditions'];
  operating_conditions?: Schema['operating_conditions'];
  policy?: Schema['policy'];
  description?: Schema['description'];
  split_accounting?: Schema['split_accounting'];
}

interface Args {
  dealId: Schema['id'];
  payload: Payload;
}

const updateGlobalDeal = async (args: Args) => {
  const { dealId, payload } = args;

  const response = await api.patch(`/api/deal/${dealId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalDeal;
