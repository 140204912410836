import clsx from 'clsx';
import { FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useQuickField from '../../../hooks/useQuickField';
import Textarea from '../Textarea';
import { Props as TextareaProps } from '../types';
import styles from './styles.module.scss';

type Props<T extends FieldValues> = UseControllerProps<T> &
  TextareaProps & {
    onSubmit?: () => void;
  };

const TextareaQuick = <T extends FieldValues>(props: Props<T>) => {
  const { name, control, rules, disabled, onSubmit, className, maxLength, ...rest } = props;

  const { t } = useTranslation();

  const { controller, error, focused, listeners } = useQuickField({
    name,
    control,
    rules,
    onSubmit,
  });

  const { field } = controller;

  if (disabled && !field.value) {
    return (
      <div data-quick className={styles.empty}>
        —
      </div>
    );
  }

  return (
    <Textarea
      data-quick
      error={error}
      disabled={disabled}
      errorVariant="popover"
      placeholder={t('common.enter')}
      maxLength={focused ? maxLength : 0}
      className={clsx(styles.textarea, error && styles.error, className)}
      {...field}
      {...listeners}
      {...rest}
    />
  );
};

export default TextareaQuick;
