import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteWorkspaceIdentity, restoreWorkspaceIdentity } from '../../api';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Checkbox from '../../components/Checkbox';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';
import { toggleMarket } from './helpers';

type Params = RouteParams<typeof routes.workspaceIdentity>;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  data: IdentitySchema;
}

const useDropdown = (args: Args) => {
  const { workspaceId, data } = args;

  const { id, last_name, first_name, monthly_cost, minimal_rental_period, on_market, is_deleted } =
    data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { identityId } = useParams<Params>();

  const marketDisabled = is_deleted || !monthly_cost || !minimal_rental_period;

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  return {
    title: `${last_name} ${first_name}`,
    options: {
      ...(!isPokerFarm && {
        market: {
          icon: icon('shoppingCart', 20),
          label: t('common.sell_on_market'),
          extra: <Checkbox variant="switch" checked={on_market} disabled={marketDisabled} />,
          onClick: () => toggleMarket(workspaceId, data),
          disabled: marketDisabled,
        },
      }),
      copy: presets.copyId(id),
      divider: { divider: true },
      ...(!is_deleted && {
        delete: {
          ...presets.delete,
          ...(on_market && { tooltip: t('common.identity_is_on_market') }),
          disabled: on_market,
          onClick: async () => {
            await deleteWorkspaceIdentity({ workspaceId, identityId: id });

            if (id === identityId) {
              navigate(routes.workspaceIdentities({ workspaceId }));
            }

            notify('success', { title: t('sentences.record_has_been_deleted') });
          },
        },
      }),
      ...(is_deleted && {
        restore: {
          ...presets.restore,
          onClick: async () => {
            await restoreWorkspaceIdentity({ workspaceId, identityId: id });

            notify('success', { title: t('sentences.record_has_been_restored') });
          },
        },
      }),
    },
  };
};

export default useDropdown;
