import api from '../../services/api';
import accountVerificationCodeSchema from '../schemas/account/accountVerificationCodeSchema';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountVerificationCode = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}/verification-code`);

  return accountVerificationCodeSchema.parse(response.data);
};

export default loadGlobalAccountVerificationCode;
