import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  label?: string;
}

const PageAddonNewCollapseField = (props: Props) => {
  const { label, children } = props;

  return (
    <div className={styles.container}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PageAddonNewCollapseField;
